import React, { useContext, useCallback, useState, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { sortAsc, sortDesc, FiltersLegend,setTableVisibility,getThresholdReferences,ca8debug } from './Utils';
import { useReactToPrint } from 'react-to-print';

import { ResponsiveContainer, BarChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, Checkbox, Menu, message, Switch, Space  } from 'antd';
import { AppContext } from "../contexts/AppContext";
import html2canvas from "html2canvas";

import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  TeamOutlined,
  UserOutlined,
  FileOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import MaximizeMinimizeButton from './buttons/MaximizeMinimizeButton';


const { Option } = Select;
const { SubMenu } = Menu;

const SitesChartFixed = (props) => {
    const TAG = 'SitesChartFixed';

    const componentRef = useRef();
    const exportRef = useRef();
    const tableRef = useRef();

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  
  
    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('group1');
    const [group1Filter, setGroup1Filter] = React.useState([]);
    const [group2Filter, setGroup2Filter] = React.useState([]);
    const [siteFilter, setSiteFilter] = React.useState([]);
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});
  
    const [loading, setLoading] = React.useState(true);
    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');

    const [showPdfLink, setShowPdfLink] = React.useState(false);

    const [useCrimeGroups, setUseCrimeGroups] = React.useState(false);
    
    const [exportAggregatedData, setExportAggregatedData] = React.useState(true);

    const [cellCount, setCellCount] = React.useState(0);

    // crime total or crime rate
    const [showCrimeTotal, setShowCrimeTotal] = React.useState(true);

    React.useEffect(() => {
      const filter = props.filter;
      setLoading(true);
      setLoadingMessage('Loading ...');


        ca8debug(`${TAG} useEffect started`);

      const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (filter) {
        apiClient.post(`api/clients/${clientId}/chart/sites`, filter)
        .then(response => {
          ca8debug(`${TAG} data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                  'id': idx + 1, 
                  'site': x.site, 
                  'address': x.address, 
                  'crime': x.crime, 
                  'value': +x.value, 
                  'category': x.category, 
                  'group1': x.group1, 
                  'group2': x.group2,  
                  'population': +x.population,  
                  //'address': x.site_address
                }
              });            
              ca8debug('dt', dt);

              //updateFilters(dt, 'group1');

              setData(dt);
              // set initial chart data
              updateTableData(dt, allFilters, allSorters, 'group1');
              //setChartData(dt);
  
              setLoading(false);
  
        });
      }
    }, [props.refreshed]);

  const updateFilters = (dt, mode) => {
    // read group1 filter
    const uniqueGroup1 = [...new Set(dt.map(x => x.group1))];
    ca8debug('uniqueGroup1', uniqueGroup1);
    setGroup1Filter(uniqueGroup1.map(x => { return {'text': x, 'value': x}}));
    // read group2 filter
    const uniqueGroup2 = [...new Set(dt.map(x => x.group2))];
    ca8debug('uniqueGroup2', uniqueGroup2);
    setGroup2Filter(uniqueGroup2.map(x => { return {'text': x, 'value': x}}));
    // read site filter
    const uniqueSite = [...new Set(dt.map(x => x.site))];
    ca8debug('uniqueSite', uniqueSite);
    setSiteFilter(uniqueSite.map(x => { return {'text': x, 'value': x}}));

    // calculate cell count
    let cnt = uniqueGroup1 ? uniqueGroup1.length : 0;
    if (mode == 'group2') {
        cnt = uniqueGroup2 ? uniqueGroup2.length : 0;
    } else if (mode == 'site') {
        cnt = uniqueSite ? uniqueSite.length : 0;
    }
    ca8debug(`${TAG} updateFilters cnt`,mode, cnt)
    ca8debug(`${TAG} updateFilters unique`,uniqueGroup1, uniqueGroup2,uniqueSite)
    setCellCount(cnt);

    // read category filter
    if (useCrimeGroups) {
      const uniqueCategory = [...new Set(dt.map(x => x.category))];
      ca8debug('uniqueCategory', uniqueCategory);
      setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));            

      // read crime filter
      const uniqueCrime = [...new Set(dt.map(x => x.crime))];
      ca8debug('uniqueCrime', uniqueCrime);
      setCrimeFilter(uniqueCrime.map(x => { return {'text': x, 'value': x}}));            
    }
  }

  const onTableChange = (pagination, filters, sorter, mode) => {
    ca8debug(`${TAG} onTableChange filters`, filters);
    
    setAllFilters(filters);
    setAllSorters(sorter);

    updateTableData(data, filters, sorter, mode ? mode : chartMode);

  }

  const filterUsingAllFilters = () => {
    ca8debug(`${TAG} filterUsingAllFilters data`, data, allFilters);
    let result = data.slice();
    if (allFilters.group1) {
      result = result.filter(x => allFilters.group1.includes(x.group1));
    }
    if (allFilters.group2) {
      result = result.filter(x => allFilters.group2.includes(x.group2));
    }
    if (allFilters.site) {
      result = result.filter(x => allFilters.site.includes(x.site));
    }
    ca8debug(`${TAG} filterUsingAllFilters result`, result);
    return result;

  }

  const exportToPdf = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPdf data`, data, allFilters);

    setLoadingMessage('Export to PDF ...');
    setLoading(true);

    const filteredData = filterUsingAllFilters();

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPdf image`, image);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", exportAggregatedData);
    formData.append("data", JSON.stringify(filteredData));

    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const filteredData = filterUsingAllFilters();

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", exportAggregatedData);
    formData.append("data", JSON.stringify(filteredData));

    apiClient.post(`api/clients/${clientId}/export`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const updateTableData = (dd, filters, sorter, mode) => {
    let newData = dd.slice();

    if (filters.group1) {
      newData = newData.filter(x => filters.group1.includes(x.group1));
    }
    if (filters.group2) {
      newData = newData.filter(x => filters.group2.includes(x.group2));
    }
    if (filters.site) {
      newData = newData.filter(x => filters.site.includes(x.site));
    }
    if (useCrimeGroups) {
      if (filters.category) {
        newData = newData.filter(x => filters.category.includes(x.category));
      }
      if (filters.crime) {
        newData = newData.filter(x => filters.crime.includes(x.crime));
      }     
    }

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }
    updateFilters(newData, mode);
    setChartData(newData);
  }
  
  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData, 'group1');
    //setChartData(newData);      
    setAllFilters([]);
    setChartMode('group1');
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
    if (e.activePayload && e.activePayload.length == 1) {
      const payloadData = e.activePayload[0];
      if (payloadData && payloadData.payload) {
        const newChartMode = chartMode == 'group1' ? 'group2' : 'sites';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (chartMode == 'group1') {
          newAllFilters['group1'] = [payloadData.payload.name];
        } else if (chartMode == 'group2') {
          newAllFilters['group2'] = [payloadData.payload.name];
        }

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null, newChartMode);
      }
    }
  }

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnDrillUp`, allFilters);
    // change chart mode
    if (chartMode != 'group1') {
        const newChartMode = chartMode == 'sites' ? 'group2' : 'group1';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (newChartMode == 'group1') {
          newAllFilters['group1'] = undefined;
          newAllFilters['group2'] = undefined;
        } else if (newChartMode == 'group2') {
          newAllFilters['group2'] = undefined;
          newAllFilters['sites'] = undefined;
        } 

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null, newChartMode);
        
    }
  }    

  const calculateWithPopulation = (x) => {
    return x.population > 0 
    ? ((x.value * 1000) / x.population).toFixed(6)
    : 0;

  }

  const getChart = (forPrinting) => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, showCrimeTotal, chartData);
    // group data by site and crime
    const uniqueGroup1 = [...new Set(chartData.map(x => x.group1))];
    const uniqueGroup2 = [...new Set(chartData.map(x => x.group2))];
    const uniqueSite = [...new Set(chartData.map(x => x.site))];
    const uniqueCategory = [...new Set(chartData.map(x => x.category))];
    const uniqueCrime = [...new Set(chartData.map(x => x.crime))];

    if (chartMode == 'group1') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueGroup1.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = showCrimeTotal 
         ? chartData.filter(y => ((y.group1 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0)
         : chartData.filter(y => ((y.group1 === x))).map(z => calculateWithPopulation(z)).reduce((a, b) => +a + +b, 0);

        res['value'] = val; // = showCrimeTotal ? val : val.toFixed(4);
        result.push(res);
        idx++;
      });
      ca8debug(`${TAG} getChart bbb result chartMode ${chartMode}`, result);
      return getChartItem(forPrinting, result);
 
    } else if (chartMode == 'group2') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueGroup2.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = showCrimeTotal 
         ? chartData.filter(y => ((y.group2 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0)
         : chartData.filter(y => ((y.group2 === x))).map(z => calculateWithPopulation(z)).reduce((a, b) => +a + +b, 0);
         res['value'] = val; // showCrimeTotal ? val.toFixed(0) : val.toFixed(4);
         result.push(res);
        idx++;
      });
      return getChartItem(forPrinting, result);

    } else {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueSite.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = showCrimeTotal 
         ? chartData.filter(y => ((y.site === x))).map(z => z.value).reduce((a, b) => +a + +b, 0)
         : chartData.filter(y => ((y.site === x))).map(z => calculateWithPopulation(z)).reduce((a, b) => +a + +b, 0);
        // calculate with population
        res['value'] = val; // showCrimeTotal ? val.toFixed(0) : val.toFixed(4);
        result.push(res);
        idx++;
      });
      return getChartItem(forPrinting, result);      
    }
        
  }    

  const CustomTooltip = ({ active, payload, label }) => {
    //ca8debug(`${TAG} CustomTooltip payload`, payload);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="intro">{`value: ${payload[0].value}`}</p>
          {chartMode == 'sites'
           ? <p className="desc">{`address: ${getSiteAddress(label)}`}</p>
           : ''}
        </div>
      );
    }
  
    return null;
  };

  const getSiteAddress = (site) => {
    if (chartData) {
      const res = chartData.filter(x => x.site == site);
      if (res && res.length > 0) {
        return res[0].address;
      }
    }
    return '';
  }

  const getChartItem = (forPrinting, data) => {

    ca8debug(`${TAG} getChartItem chartMode ${chartMode}`,allFilters, data);

    const angle = chartMode == 'group2' ? 270 : 0;
    const tickMargin = chartMode == 'group2' ? 60 : 0;
    const height = chartMode == 'group2' ? 100 : 30;

    const chartWidth = forPrinting ? 800 : 500;
    const chartHeight = forPrinting ? 600 : 300;

    //setCellCount(data && data.length > 0 ? data.length : 0);

    return <BarChart
      onClick={OnChartClick}
      width={550}
      data={data}
      layout="vertical"
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="3 3" />

      <XAxis type="number"/> 
      <YAxis type="category" tick={{ fontSize: 10, width: 250 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>


      <Bar barSize={20} isAnimationActive={false} dataKey='value' fill="#7B778C" >
        <LabelList dataKey="value" position="right" fill="#000000" formatter={(x) => showCrimeTotal ? x : Number(x).toFixed(6)}/>
      </Bar>     
    
      {getThresholdReferences(data, true, false, props.minimized)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} content={<CustomTooltip />}/>

    </BarChart>    
  }

  const CustomLegend = () => {
    if (chartMode == 'group1') {
      return FiltersLegend(allFilters, resetFilters, null);
    }
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const showDetails = (record) => {

    const filteredData = useCrimeGroups ? props.data.filter(x => 
        x.state_name == record.group1 && 
        x.site_city == record.group2 &&
        x.site_name == record.site &&
        x.crime_category == record.category) 
        : props.data.filter(x => 
          x.state_name == record.group1 && 
          x.site_city == record.group2 &&
          x.site_name == record.site
        );

        ca8debug(`${TAG} showDetails`, record, filteredData)
    
    const cols = [
      { title: 'id',dataIndex: 'incident_id',key: 'incident_id' },
      { title: 'Site',dataIndex: 'site_name',key: 'site_name' },
      { title: 'Crime Type',dataIndex: 'crime_type',key: 'crime_type' },
      { title: 'Date',dataIndex: 'incident_date',key: 'incident_date' },
      { title: 'Time',dataIndex: 'incident_time',key: 'incident_time' },
      { title: 'Location',dataIndex: 'incident_location',key: 'incident_location' },
      { title: 'Victim',dataIndex: 'incident_victim',key: 'incident_victim' },
      { title: 'Comments',dataIndex: 'incident_comments',key: 'incident_comments' },
      { title: 'Offense Report',dataIndex: 'incident_offence_report_no',key: 'incident_offence_report_no' },
    ];

    return <Table 
        size="small"
        key={`table_${record.group1}_${record.group2}_${record.site}_${record.category ? record.category : 0}`}
        columns={cols} 
        dataSource={filteredData}
        />
  }

  const showHideTable = () => {
    
    const isShowTable = !showTable;

    setShowTable(isShowTable);
  }    

  const onCrimeGroupsChanged = (e) => {
    setUseCrimeGroups(!useCrimeGroups);
  }

  const getColumns = () => {
    const result = 
    [
      {
        title: 'Group 1',
        dataIndex: 'group1',
        key: 'group1',
        //sorter: true,
        //filters: group1Filter,
        filteredValue: allFilters.group1 || null,
        onFilter: (value, record) => {
          return record.group1.includes(value);
        },      
      },
      {
        title: 'Group 2',
        dataIndex: 'group2',
        key: 'group2',
        //sorter: true,
        //filters: group2Filter,
        filteredValue: allFilters.group2 || null,
        onFilter: (value, record) => {
          return record.group2.includes(value);
        },      
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
        //sorter: true,
        //filters: siteFilter,
        filteredValue: allFilters.site || null,
        onFilter: (value, record) => {
          return record.site.includes(value);
        },      
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'total_crime',
      }
    ];

    if (useCrimeGroups) {
      result.push(      
        {
          title: 'Crime Group',
          dataIndex: 'category',
          key: 'category',
          //sorter: true,
          //filters: categoryFilter,
          filteredValue: allFilters.category || null,
          onFilter: (value, record) => {
            return record.category.includes(value);
          },
      });
    }
    if (!showCrimeTotal) {
/*      
      result.push(      
        {
          title: 'Population',
          dataIndex: 'population',
          key: 'population',
      });
*/      
      result.push(      
        {
          title: 'Rate',
          key: 'rate',
          sorter: true,
          render: (text, record) => {
            return calculateWithPopulation(record)
          }
        });

    }
    return result;
  }

  const onExportAggregatedDataChanged = (e) => {
    setExportAggregatedData(e.target.checked);
  }  

  const onShowCrimeTotalChange = (checked) => {

    setShowCrimeTotal(checked);
    //setLoading(false);
    //setChartData(chartData);
    //onTableChange = (null, allFilters, null, chartMode);
  }

  return (
    <div id="divSiteChart" className="chart-container" style={{ width: props.minimized ? 500 : props.twoColumns ? 650 : 1000}} >
      {props.minimized || loading || props.loading
        ? ''
        :  <Menu style={{ position: 'absolute', top: 10, right: 10, width: 120 }} mode="vertical">
            <SubMenu key="sub1" icon={<PrinterOutlined />} title="Export">
              <Checkbox checked={exportAggregatedData} onChange={onExportAggregatedDataChanged} style={{margin: 15}}>
                Export aggregated data
              </Checkbox>
              <Menu.Item icon={<FilePdfOutlined />} onClick={() => {
                exportToPdf(exportRef.current, "test");
              }} key="1">PDF</Menu.Item>
              <Menu.Item icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
            </SubMenu>
          </Menu>
      }
      {props.twoColumns
        ? ''
        : <MaximizeMinimizeButton minimized={props.minimized} onClick={props.onMinimizedChange}/>
      }

      <h2>Sites / Site Groups 
        <Space direction='horizontal' style={{ marginLeft: 20 }}>
          <Switch 
            disabled={props.loading}
            checkedChildren="Crime total" 
            unCheckedChildren="Crime rate" 
            defaultChecked 
            onChange={(checked) => onShowCrimeTotalChange(checked)} 
          />
          <Button 
            icon={<TableOutlined />} 
            loading={props.loading} 
            size='small' 
            type="ghost" 
            onClick={() => props.showChartData('sites', 'Sites / Site groups', chartData, getColumns())}>
            Show data</Button>
        </Space>
      </h2>
      <div>
        </div>

      <Spin tip={loadingMessage} spinning={loading}>

        {loading
        ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
        : data && data.length > 0
          ? <div ref={exportRef}>
              <ResponsiveContainer width='100%' height={cellCount > 15 ? cellCount * 25 : 400}>
              {getChart(false)}
              </ResponsiveContainer>
              <CustomLegend/>
            </div>
          : <div style={{width: '100%', height: 400, padding: 100}}><Empty /></div>
        }
        <div style={{ position: 'relative' }}>
          {/*
          <Button icon={<TableOutlined />} loading={props.loading} size='small' type="ghost" onClick={() => showHideTable()}>{ showTable ? 'Hide data' : 'Show data' }</Button>
      */}
          <Checkbox style={{ position: 'absolute', left: 10, bottom: 10 }} loading={loading || props.loading} onChange={onCrimeGroupsChanged}>Use crime groups</Checkbox>
        </div>
{/*          
         <Table 
          size='middle'
          rowKey='id' 
          loading={loading || props.loading}
          ref={tableRef}  
          columns={getColumns()} 
          dataSource={chartData} 
          onChange={onTableChange}
          expandable={{
            expandedRowRender: (record) => showDetails(record),
          }}         
          />
        */}          
      </Spin>
    </div>
  );
}

export default SitesChartFixed;