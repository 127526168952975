import React, { useContext } from "react";
import { Layout, Menu, Breadcrumb, Button } from 'antd';
  
const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;


const AppFooter = () => {
  const TAG = 'AppFooter';

  return (
    <Footer style={{ textAlign: 'center' }}>v. 0.2.229</Footer>
  );
};

export default AppFooter;
