import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState, useRef, useEffect } from 'react'
import ReactDOM from  'react-dom'
import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label } from 'recharts';
import { Layout, Menu, PageHeader, Table, Button, Space, Select, Divider,Collapse } from 'antd';
import SitesChartFixed from './SitesChartFixed';
import TimeChart from './TimeChart';
import DayChart from './DayChart';
import HourChart from './HourChart';
import CrimeChartFixed from './CrimeChartFixed';
import HeatmapChart from './HeatmapChart';
import FilterOptions from './FilterOptions';
import FilterOptions2 from './FilterOptions2';
import { AppContext } from "../contexts/AppContext";
import AppHeader from './AppHeader';
import DraggableComponent from './DraggableComponent'
import PageTitle from './PageTitle'


import { ca8debug, getProperDate } from './Utils';
import AppFooter from './AppFooter';


import { DesktopOutlined,
  BarChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import ChartDataDrawer from './drawers/ChartDataDrawer';
import SitesChart3 from './SitesChart3';
import TimeChart3 from './TimeChart3';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;

//import { readString } from 'react-papaparse'

const DashboardFixed3 = (props) => {
  const TAG = 'DashboardFixed3';

  const appContext = useContext(AppContext);
  const {
    apiClient,
    appRole,
    appClientId,
    appCurrentClient
  } = appContext;  


  const defaultFilter = {
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    dayOfWeek: '',
    crimeCategory: '',
    crimeType: '',
    group1: '',
    group2: '',
    site: '',
    relationship: ''
  }

  const [data, setData] = React.useState([]);
  const [mainFilter, setMainFilter] = React.useState(defaultFilter);
  const [loading, setLoading] = React.useState(true);
  
  //const [crimeTypes, setCrimeTypes] = React.useState([]);
  //const [crimeCategories, setCrimeCategories] = React.useState([]);

  const [mainFilterLegend, setMainFilterLegend] = React.useState('');

  const [showDOW, setShowDOW] = useState(false);
  const [showTOD, setShowTOD] = useState(false);
  
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const [refreshDateTime, setRefreshDateTime] = useState(new Date().getMilliseconds());


  const [isSitesChartMinimized, setIsSitesChartMinimized] = useState(false);
  const [isTimeChartMinimized, setIsTimeChartMinimized] = useState(true);
  const [isDayChartMinimized, setIsDayChartMinimized] = useState(true);
  const [isHourChartMinimized, setIsHourChartMinimized] = useState(true);
  const [isCrimeChartMinimized, setIsCrimeChartMinimized] = useState(true);
  const [isHeatmapChartMinimized, setIsHeatmapChartMinimized] = useState(true);

  const [chartData, setChartData] = React.useState([]);
  const [chartColumns, setChartColumns] = React.useState([]);
  const [chartTitle, setChartTitle] = React.useState('');
  const [chartFilter, setChartFilter] = React.useState(undefined);
  const [chartMode, setChartMode] = React.useState('');
  const [chartExtra, setChartExtra] = React.useState({});
  const [drawerVisible, setDrawerVisible] = React.useState(false);
	const [selectedChart, setSelectedChart] = React.useState('');
  const [expertYear, setExpertYear] = React.useState(undefined);
/*
  const [allCrimeCategories, setAllCrimeCategories] = useState([]);
  const [allCrimeTypes, setAllCrimeTypes] = useState([]);
  const [allGroup1, setAllGroup1] = useState([]);
  const [allGroup2, setAllGroup2] = useState([]);
  const [allSite, setAllSite] = useState([]);
*/  
const [allData, setAllData] = useState(undefined);
const [allDataLoading, setAllDataLoading] = useState(false);

  function useFirstRender() {
    const ref = useRef(true);
    const firstRender = ref.current;
    ref.current = false;
    return firstRender;
  }

  const {firstRender} = useFirstRender();
      
  const clientId = appCurrentClient?.id;

  const onSetFilterLegend = (legend) => {
    ca8debug(`${TAG} onSetFilterLegend`, legend);
    setMainFilterLegend(legend);

    onShowMainFilterLegendEx(legend);

  }

  const onSiderCollapsed = (value) => {
    setSiderCollapsed(value);
  }

  const onShowMainFilterLegend = () => {
    const args = {
      message: 'Filter options',
      description: mainFilterLegend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);       
  }

  const onShowMainFilterLegendEx = (legend) => {
    const args = {
      message: 'Filter options',
      description: legend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);       
  } 

  const onUseFilters = (filters) => {
    ca8debug(`${TAG} onUseFilters`,filters);

    const newData = data.slice();

    let startDate = '';
    let endDate = '';
    let startTime = '';
    let endTime = '';
    let dayOfWeek = '';  
    let crimeCategory = '';  
    let crimeType = '';  
    let group1 = '';  
    let group2 = '';  
    let site = '';  
    
    let dateCheck = true;

    if (filters !== undefined) {
      if (filters.useDatePeriod) {
        startDate = filters.datePeriod[0];
        endDate = filters.datePeriod[1];
        // startDate = getProperDate(filters.datePeriod[0], filters.dateMode);
        // endDate = getProperDate(filters.datePeriod[1], filters.dateMode);
      }

      if (filters.useTimePeriod) {
        startTime = filters.timePeriod[0] ?? '';
        endTime = filters.timePeriod[1] ?? '';
      }

      if (filters.useWeekdays) {
        dayOfWeek = filters.weekdays.join(',');
      } 

      if (filters.useCrimeCategories) {
        crimeCategory = filters.crimeCategories.join(',');
        if (filters.useCrimeTypes) {
          crimeType = filters.crimeTypes.join(',');
        } 
      } 

      if (filters.useGroup1) {
        group1 = filters.group1.join(',');
        if (filters.useGroup2) {
          group2 = filters.group2.join(',');
          if (filters.useSite) {
            site = filters.site.join(',');
          } 
        } 
      } 
    }

    
    const newFilter = {
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      dayOfWeek: dayOfWeek,
      crimeCategory: crimeCategory,
      crimeType: crimeType,
      group1: group1,
      group2: group2,
      site: site,
      relationship: filters?.relationship || ''
    }

    ca8debug(`${TAG} onUseFilters result`,newFilter);
    setMainFilter(newFilter);
    loadData(newFilter);
  }

  const loadData = (filter) => {
    ca8debug(`${TAG} loadData started`,filter);

    if (clientId) {
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    setLoading(true);
    //setRefreshDateTime(new Date().getMilliseconds());

    apiClient.post(`api/clients/${clientId}/chart/incidents`, filter)
      .then(response => {
        ca8debug(`${TAG} loadData response`, response);
        
            const dt = response.data.map((x, idx) => {
              return {
                id : idx + 1,
                incident_id : x.incident_id,
                site_name : x.site_name,
                site_city : x.site_city,
                state_name : x.state_name,
                crime_type : x.crime_type_name,
                crime_category : x.crime_category_name,
                incident_date : x.incident_date,
                incident_time : x.incident_time,
                incident_location : x.incident_location,
                incident_victim : x.incident_victim,
                incident_comments : x.incident_comments,
                incident_offence_report_no : x.incident_offence_report_no,
                incident_relationship : x.incident_relationship,
                cyear : x.cyear,
                cmonth : x.cmonth,
                smonth : x.smonth,
                weekday : x.weekday,
                cweekday : x.cweekday,
                timeperiod : x.chour,
                quarter : x.quarter,
                period1 : x.period1,
                period2 : x.period2,
                period4 : x.period4,
                period8 : x.period8,
              }
            });            
            ca8debug(`${TAG} loadData dt`, dt);

            setRefreshDateTime(new Date().getMilliseconds());
            setData(dt);
            setLoading(false);
          });
    }
  }

  React.useEffect(() => {

    ca8debug('firstRender', firstRender);

    if (clientId) {

    setLoading(true);
    setAllDataLoading(true);

    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const expertMode = appCurrentClient ? appCurrentClient.is_expert > 0 : false;

    ca8debug(`${TAG} useEffect started`, clientId);

    const newAllData = {};

    apiClient.get(`/api/clients/${clientId}/chart/unique`).then(response => {
      ca8debug(`${TAG} unique data response`, response);
      
      if (response.data) {
        if (response.data.crimeTypes) {
          const dt = response.data.crimeTypes.map((x, idx) => {
            return {
              value: x.id, 
              parent_id: x.crime_category_id, 
              label: x.name, 
            }
          }).sort((a, b) => (+a.id) - (+b.id));             
          ca8debug(`${TAG} unique crimeTypes`, dt);
          newAllData.crimeTypes = dt;
        }
        if (response.data.crimeCategories) {
          const dt = response.data.crimeCategories.map((x, idx) => {
            return {
              value: x.id, 
              label: x.name, 
            }
          }).sort((a, b) => (+a.id) - (+b.id));              
          ca8debug(`${TAG} unique crimeCategories`, dt);
          newAllData.crimeCategories = dt;
        }
        if (response.data.group1) {
          const dt = response.data.group1.map((x, idx) => {
            return {
              value: x.state_id, 
              label: x.state_name, 
            }
          }).sort((a, b) => a.label.localeCompare(b.label));            
          ca8debug(`${TAG} unique group1`, dt);
          newAllData.group1 = dt;
        }
        if (response.data.group2) {
          const dt = response.data.group2.map((x, idx) => {
            return {
              value: x.id, 
              parent_id: x.state_id, 
              label: x.name, 
            }
          }).sort((a, b) => a.label.localeCompare(b.label));          
          ca8debug(`${TAG} unique group2`, dt);
          newAllData.group2 = dt;
        }
        if (response.data.site) {
          const dt = response.data.site.map((x, idx) => {
            return {
              value: x.id, 
              parent_id: x.city_id, 
              label: x.name, 
            }
          }).sort((a, b) => a.label.localeCompare(b.label));        
          ca8debug(`${TAG} unique site`, dt);
          newAllData.site = dt;
        }
        if (response.data.year) {
          const dt = response.data.year.map((x, idx) => {
            return {
              value: x.yr, 
              label: x.yr, 
            }
          }).sort((a, b) => a - b);        
          ca8debug(`${TAG} unique year`, dt);
          newAllData.year = dt;
        }
        if (response.data.relationship) {
          const dt = response.data.relationship.map((x, idx) => {
            return {
              value: x.incident_relationship, 
              label: x.incident_relationship, 
            }
          }).sort((a, b) => a - b);        
          ca8debug(`${TAG} unique relationship`, dt);
          newAllData.relationship = dt;
        }        
        // expert mode
        ca8debug(`${TAG} unique expertMode`, expertMode, response.data.maxdate);
        let expertModeResult = undefined;
        if (response.data.maxdate) {
          if (expertMode) {
            const dt = response.data.maxdate;
            if (dt.length > 0) {
              const md = dt[0].yr;
              const arr = md.split("-");
              ca8debug(`${TAG} unique expertMode 1`, md, arr);
              if (arr.length == 3) {
                if (12 > +arr[1]) {
                  expertModeResult = { year: arr[0], value: `${arr[0]} to ${arr[1]}/${arr[2]}`}
                }
              }
            }
          }
        }
        newAllData.expertMode = expertModeResult;
        ca8debug(`${TAG} unique expertMode Result`, expertModeResult);
      }
      ca8debug(`${TAG} useEffect newAllData`, newAllData);
              
      setAllData(newAllData);
      setAllDataLoading(false);
      //loadData(mainFilter);

    });
  }
    
  }, [clientId]);

  const onSetChartData = (dt) => {
    setChartData(dt);
  }

  const onShowChartData = (mde, ttl, flt, dt, cols, extra = {}) => {
    setChartMode(mde);
    setChartTitle(ttl);
    setChartFilter(flt);
    setChartData(dt);
    setChartColumns(cols);
    setChartExtra(extra);
    setDrawerVisible(true);
  }  

  const onDrawerClose = () => {
    setChartData([]);
    setDrawerVisible(false);
		setSelectedChart('');
  }

  return (clientId 
    ? <Layout hasSider style={{ minHeight: '100vh' }}>
      <FilterOptions2 
        onUseFilters={onUseFilters} 
        onSetFilterLegend={onSetFilterLegend} 
        onSetShowTOD={setShowTOD}
        onSetShowDOW={setShowDOW}
        onCollapsed={onSiderCollapsed}
        loading={loading}
        allDataLoading={allDataLoading}
        allData={allData}
      />
      
      <Layout className="site-layout" style={{ position: 'relative' }}>

        <PageTitle
          icon={<BarChartOutlined />}
          title="Dashboard"
        />      

        <DraggableComponent legend={mainFilterLegend} />

        <Content style={{ padding: '2px', position: 'relative', overflow: 'hidden' }}>
          <div className='chart3-main-container'>

            <SitesChart3 
              selectChart={setSelectedChart} 
              selected={false} 
              drawerVisible={drawerVisible} 
              showChartData={onShowChartData} 
              refreshed={refreshDateTime} 
              data={data} 
              allData={allData} 
              filter={mainFilter} 
              loading={loading} 
              allDataLoading={allDataLoading}
              mainLegend={mainFilterLegend} 
              onShowLegend={onShowMainFilterLegend}/>
              
            <TimeChart3 
              selectChart={setSelectedChart} 
              selected={false} 
              drawerVisible={drawerVisible} 
              showChartData={onShowChartData} 
              refreshed={refreshDateTime} 
              data={data} 
              allData={allData} 
              allDataLoading={allDataLoading}
              filter={mainFilter} 
              loading={loading} 
              mainLegend={mainFilterLegend}/>

            <CrimeChartFixed 
              selectChart={setSelectedChart} 
              selected={false} 
              drawerVisible={drawerVisible} 
              showChartData={onShowChartData} 
              refreshed={refreshDateTime} 
              data={data} 
              allData={allData} 
              allDataLoading={allDataLoading}
              filter={mainFilter} 
              loading={loading} 
              mainLegend={mainFilterLegend}/>

            <HeatmapChart 
              onSetShowTOD={setShowTOD}
              onSetShowDOW={setShowDOW}

              selectChart={setSelectedChart} 
              selected={false} 
              drawerVisible={drawerVisible} 
              showChartData={onShowChartData} 
              refreshed={refreshDateTime} 
              data={data} 
              allData={allData} 
              allDataLoading={allDataLoading}
              filter={mainFilter} 
              loading={loading} 
              mainLegend={mainFilterLegend}/>

            {showDOW
            ? <DayChart 
                selectChart={setSelectedChart} 
                selected={false} 
                drawerVisible={drawerVisible} 
                showChartData={onShowChartData} 
                refreshed={refreshDateTime} 
                data={data} 
                allData={allData} 
                allDataLoading={allDataLoading}
                filter={mainFilter} 
                loading={loading} 
                mainLegend={mainFilterLegend}/>
            : ''}

            {showTOD
            ? <HourChart 
                selectChart={setSelectedChart} 
                selected={false} 
                drawerVisible={drawerVisible} 
                showChartData={onShowChartData} 
                refreshed={refreshDateTime} 
                data={data} 
                allData={allData} 
                allDataLoading={allDataLoading}
                filter={mainFilter} 
                loading={loading} 
                mainLegend={mainFilterLegend}/>
            : ''}

          </div>
{/*
          <ChartDataDrawer 
            visible={drawerVisible} 
            onClose={onDrawerClose} 
            data={chartData} 
            chartData={chartData} 
            mode={chartMode} 
            title={chartTitle} 
            filter={chartFilter} 
            columns={chartColumns}
            extra={chartExtra}/>
*/}            
  {/*
              <SitesChartFixed refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
              <TimeChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
              <DayChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
              <HourChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
              <CrimeChartFixed refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
              <HeatmapChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
  */}            
        </Content>
      </Layout>
    </Layout>
    : <>loading</>
  );
}


export default DashboardFixed3;
