import 'antd/dist/antd.css';

import React, { useContext, useRef, useState } from 'react'
import ReactDOM from  'react-dom'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Layout, Menu, Switch, Table, Button, message, Empty, Spin,Collapse } from 'antd';
import FilterOptions2 from './FilterOptions2';
import { AppContext } from "../contexts/AppContext";
import AppHeader from './AppHeader';
import DraggableComponent from './DraggableComponent'
import PageTitle from './PageTitle'
import CalendarPage from './CalendarPage'
import FrequencyPage3 from './FrequencyPage3'

import { getProperDate,ca8debug } from './Utils';
import AppFooter from './AppFooter';


import { DesktopOutlined,
  BarChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
//import CalendarMonthComponent from './CalendarMonthComponent';
//import LegendComponent from './LegendComponent';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;

//import { readString } from 'react-papaparse'

const FrequencyAll = (props) => {
  const TAG = 'FrequencyAll';

  const appContext = useContext(AppContext);
  const {
    apiClient,
    appRole,
    appClientId,
    appCurrentClient
  } = appContext;  

  const defaultFilter = {
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    dayOfWeek: '',
    crimeCategory: '',
    crimeType: '',
    group1: '',
    group2: '',
    site: '',
    relationship: ''
  }

  const [data, setData] = React.useState([]);
  const [mainFilter, setMainFilter] = React.useState(defaultFilter);
  const [loading, setLoading] = React.useState(true);

  const [crimeTypes, setCrimeTypes] = React.useState([]);
  const [crimeCategories, setCrimeCategories] = React.useState([]);

  const [mainFilterLegend, setMainFilterLegend] = React.useState('');

  const [showDOW, setShowDOW] = useState(false);
  const [showTOD, setShowTOD] = useState(false);

  //const [chartData, setChartData] = useState([]);
  const [uniqueCategory, setUniqueCategory] = useState([]);

  const [allData, setAllData] = useState(undefined);
  const [allDataLoading, setAllDataLoading] = useState(false);

  const [isCalendarPage, setIsCalendarPage] = React.useState(true);

  const pageHeader = () => {
    return <div>

    </div>
  }

  const onSetFilterLegend = (legend) => {

    ca8debug(`${TAG} onSetFilterLegend`, legend);
    setMainFilterLegend(legend);

    onShowMainFilterLegendEx(legend);

  }

  const onShowMainFilterLegendEx = (legend) => {
    const args = {
      message: 'Filter options',
      description: legend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);       
  }  

  const onUseFilters = (filters) => {
    ca8debug(`${TAG} onUseFilters`,filters);

    let startDate = '';
    let endDate = '';
    let startTime = '';
    let endTime = '';
    let dayOfWeek = '';  
    let crimeCategory = '';  
    let crimeType = '';  
    let group1 = '';  
    let group2 = '';  
    let site = '';  
    
    let dateCheck = true;

    if (filters !== undefined) {
      if (filters.useDatePeriod) {
        startDate = getProperDate(filters.datePeriod[0], filters.dateMode);
        endDate = getProperDate(filters.datePeriod[1], filters.dateMode);
      }

      if (filters.useTimePeriod) {
        startTime = filters.timePeriod[0] ?? '';
        endTime = filters.timePeriod[1] ?? '';
      }

      if (filters.useWeekdays) {
        dayOfWeek = filters.weekdays.join(',');
      } 

      if (filters.useCrimeCategories) {
        crimeCategory = filters.crimeCategories.join(',');
        if (filters.useCrimeTypes) {
          crimeType = filters.crimeTypes.join(',');
        } 
      } 

      if (filters.useGroup1) {
        group1 = filters.group1.join(',');
        if (filters.useGroup2) {
          group2 = filters.group2.join(',');
          if (filters.useSite) {
            site = filters.site.join(',');
          } 
        } 
      } 
    }

    
    const newFilter = {
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      dayOfWeek: dayOfWeek,
      crimeCategory: crimeCategory,
      crimeType: crimeType,
      group1: group1,
      group2: group2,
      site: site,
      relationship: filters?.relationship || ''
    }

    ca8debug(`${TAG} onUseFilters result`,newFilter);
    setMainFilter(newFilter);
    loadData(newFilter);
  }

  const loadData = (filter) => {
    ca8debug(`${TAG} loadData started`,filter);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    setLoading(true);
    apiClient.post(`api/clients/${clientId}/chart/calendar`,filter)
      .then(response => {
        ca8debug(`${TAG} loadData response`, response);
        
        if (response.data && response.data.length > 0) {
            setData(response.data);
        } else {
            setData([]);
        }
        ca8debug(`${TAG} setLoading`);
        setLoading(false);
    }).catch((error) => {

        setLoading(false);
        setData([]);
        message.error(error);
    
    });
  }

  React.useEffect(() => {

    setLoading(true);
    setAllDataLoading(true);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const expertMode = appCurrentClient ? appCurrentClient.is_expert > 0 : false;
    ca8debug(`${TAG} useEffect started`, clientId);

    const newAllData = {};

    apiClient.get(`/api/clients/${clientId}/chart/unique`).then(response => {
      ca8debug(`${TAG} unique data response`, response);
      
      if (response.data) {
        if (response.data.crimeTypes) {
          const dt = response.data.crimeTypes.map((x, idx) => {
            return {
              value: x.id, 
              parent_id: x.crime_category_id, 
              label: x.name, 
            }
          }).sort((a, b) => (+a.id) - (+b.id)); //.sort((a, b) => a.label.localeCompare(b.label));             
          ca8debug(`${TAG} unique crimeTypes`, dt);
          newAllData.crimeTypes = dt;
        }
        if (response.data.crimeCategories) {
          const dt = response.data.crimeCategories.map((x, idx) => {
            return {
              value: x.id, 
              label: x.name, 
            }
          }).sort((a, b) => (+a.id) - (+b.id)); //.sort((a, b) => a.label.localeCompare(b.label));              
          ca8debug(`${TAG} unique crimeCategories`, dt);
          newAllData.crimeCategories = dt;
        }
        if (response.data.group1) {
          const dt = response.data.group1.map((x, idx) => {
            return {
              value: x.state_id, 
              label: x.state_name, 
            }
          }).sort((a, b) => a.label.localeCompare(b.label));            
          ca8debug(`${TAG} unique group1`, dt);
          newAllData.group1 = dt;
        }
        if (response.data.group2) {
          const dt = response.data.group2.map((x, idx) => {
            return {
              value: x.id, 
              parent_id: x.state_id, 
              label: x.name, 
            }
          }).sort((a, b) => a.label.localeCompare(b.label));          
          ca8debug(`${TAG} unique group2`, dt);
          newAllData.group2 = dt;
        }
        if (response.data.site) {
          const dt = response.data.site.map((x, idx) => {
            return {
              value: x.id, 
              parent_id: x.city_id, 
              label: x.name, 
            }
          }).sort((a, b) => a.label.localeCompare(b.label));        
          ca8debug(`${TAG} unique site`, dt);
          newAllData.site = dt;
        }
        if (response.data.year) {
          const dt = response.data.year.map((x, idx) => {
            return {
              value: x.yr, 
              label: x.yr, 
            }
          }).sort((a, b) => a - b);        
          ca8debug(`${TAG} unique year`, dt);
          newAllData.year = dt;
        }
        if (response.data.relationship) {
          const dt = response.data.relationship.map((x, idx) => {
            return {
              value: x.incident_relationship, 
              label: x.incident_relationship, 
            }
          }).sort((a, b) => a - b);        
          ca8debug(`${TAG} unique relationship`, dt);
          newAllData.relationship = dt;
        }        
        // expert mode
        ca8debug(`${TAG} unique expertMode`, expertMode, response.data.maxdate);
        let expertModeResult = undefined;
        if (response.data.maxdate) {
          if (expertMode) {
            const dt = response.data.maxdate;
            if (dt.length > 0) {
              const md = dt[0].yr;
              const arr = md.split("-");
              ca8debug(`${TAG} unique expertMode 1`, md, arr);
              if (arr.length == 3) {
                if (12 > +arr[1]) {
                  expertModeResult = { year: arr[0], value: `${arr[0]} to ${arr[1]}/${arr[2]}`}
                }
              }
            }
          }
        }
        newAllData.expertMode = expertModeResult;
        ca8debug(`${TAG} unique expertMode Result`, expertModeResult);        
      }
      ca8debug(`${TAG} useEffect newAllData`, newAllData);
              
      setAllData(newAllData);
      setAllDataLoading(false);
      //loadData(mainFilter);

    });
    
  }, []);



  const getComponents = () => {
    return (
        <div style={{ marginLeft: 20 }}>
            <Switch 
                disabled={props.loading}
                style={{width: 150, marginBottom: 20}}
                checkedChildren="Calendar" 
                unCheckedChildren="Chart" 
                checked={isCalendarPage} 
                onChange={(checked) => switchPages(checked)} 
            />  

          <div>
            {isCalendarPage
                ? <CalendarPage data={data} allData={allData} filter={mainFilter} mainLegend={mainFilterLegend}/>
                : <FrequencyPage3 data={data} allData={allData} filter={mainFilter} mainLegend={mainFilterLegend}/>
            }
          </div>
      </div>   
    );
  }

  const switchPages = (checked) => {
    setIsCalendarPage(checked);
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <FilterOptions2 
        onUseFilters={onUseFilters} 
        onSetFilterLegend={onSetFilterLegend} 
        onSetShowTOD={setShowTOD}
        onSetShowDOW={setShowDOW}
        loading={loading}
        allDataLoading={allDataLoading}
        allData={allData}
       
        />
        
    <Layout className="site-layout">
      <PageTitle
        icon={<BarChartOutlined />}
        title={`Frequency - ${isCalendarPage ? 'Calendar' : 'Chart'}`}
      />      

      <DraggableComponent legend={mainFilterLegend} colors={uniqueCategory}/>

      <Content style={{ margin: '0 5px' }}>

      <Spin tip="Loading ..." spinning={loading}>
      {getComponents()}
      </Spin>
            
      </Content>
        </Layout>
      </Layout>
  );
}


export default FrequencyAll;
