import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Layout, Tag, Spin, Table, Button, Space, Drawer, Divider,Upload } from 'antd';
import { AppContext } from "../../contexts/AppContext";
//import {useDropzone} from 'react-dropzone'

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  FileExcelOutlined,
  CloudUploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;  

const SettingsRegions = (props) => {
  const TAG = 'SettingsRegions';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [sites, setSites] = React.useState([]);
  const [years, setYears] = React.useState([]);


  React.useEffect(() => {
    loadData();
    loadSites();
  }, [props.client]);

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/region`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setData([]);
        setLoading(false);
      });
  }

  const loadSites = () => {
    //console.log(`${TAG} loadSites started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/site`)
      .then(response => {
        //console.log(`${TAG} loadSites response`, response);
        if (response.data) {
          setSites(response.data.sites);
          setYears(response.data.years);
        } else {
          setSites([]);
          setYears([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadSites response`,error);
        setSites([]);
        setLoading(false);
      });
  }

  const showSites = (record) => {
    //console.log(`${TAG} showSites started`, record, sites);

    const cols = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
      { title: 'Address', dataIndex: 'address', key: 'address' },
      { title: 'Group 1', dataIndex: 'state_name', key: 'state_name' },
      { title: 'Group 2', dataIndex: 'site_city', key: 'site_city' },
      {
        title: 'Absent population data',
        key: 'population_years',
        dataIndex: 'population_years',
        width: 100,
        render: population_years => {
          let zero_population = years.map(x => x.cyear).slice();
          if (population_years && population_years.length > 0) {
            const arr = population_years.split(',').map(x => +x);
            zero_population = zero_population.filter(x => !arr.includes(x));
          }
          return <span>
            {zero_population && zero_population.length > 0
              ? <Tag color={'volcano'} key={zero_population}>{zero_population.join(',')}</Tag>
              : ''
            }
          </span>
        },
      },       
    ];

    const dt = sites.filter(x => x.region_id == record.id);

    return <Table 
        size="small"
        key={`table_region_${record.id}_site`}
        columns={cols} 
        dataSource={dt}
      />
  }
  
  const columns = [
    { title: 'Name', dataIndex: 'region_name', key: 'region_name' },
    { title: 'Description', dataIndex: 'region_description', key: 'region_description' },
  ]

  return (
    
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading} delay={0}>
        <Table 
          size='small' 
          rowKey='id'
          columns={columns} 
          dataSource={data} 
          expandable={{
            expandedRowRender: (record) => showSites(record),
          }}           
          title={() => 'Regions list'} />
      </Spin>
      </div>
  </div>    
  );
}


export default SettingsRegions;
