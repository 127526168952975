import 'antd/dist/antd.css';

import React, { useContext, useRef, useState } from 'react'
import ReactDOM from  'react-dom'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Layout, Menu, PageHeader, Select, Space, message, Empty, Spin,Collapse } from 'antd';
import FilterOptions2 from './FilterOptions2';
import { AppContext } from "../contexts/AppContext";
import AppHeader from './AppHeader';
import DraggableComponent from './DraggableComponent'
//import LogoutButton from './LogoutButton'
import PageTitle from './PageTitle'

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'

import { formatExpertYear, getFillForCategory, ca8debug } from './Utils';
import AppFooter from './AppFooter';

import * as htmlToImage from 'html-to-image';
 
import { DesktopOutlined,
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined,
  BarChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import CalendarMonthComponent from './CalendarMonthComponent';
import LegendComponent from './LegendComponent';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;
const { Option } = Select;

//import { readString } from 'react-papaparse'

const FrequencyPage3 = (props) => {
  const TAG = 'FrequencyPage3';

  const exportRef = useRef([]);

  const appContext = useContext(AppContext);
  const {
    apiClient,
    appRole,
    appClientId,
    appCurrentClient
  } = appContext;  

  const defaultFilter = {
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    dayOfWeek: '',
    crimeCategory: '',
    crimeType: '',
    group1: '',
    group2: '',
    site: '',
    relationship: ''
  }

  const [data, setData] = React.useState([]);
  const [mainFilter, setMainFilter] = React.useState(defaultFilter);
  const [loading, setLoading] = React.useState(true);
  const [exporting, setExporting] = React.useState(false);
  const [printing, setPrinting] = React.useState(false);
  
  const [crimeTypes, setCrimeTypes] = React.useState([]);
  const [crimeCategories, setCrimeCategories] = React.useState([]);

  const [mainFilterLegend, setMainFilterLegend] = React.useState('');

  const [showDOW, setShowDOW] = useState(false);
  const [showTOD, setShowTOD] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [uniqueYear, setUniqueYear] = useState([]);
  //const [selectedYear, setSelectedYear] = useState(0);
  const [uniqueCategory, setUniqueCategory] = useState([]);

  //const [totalCrimesMin, setTotalCrimesMin] = useState(0);
  const [totalCrimesMax, setTotalCrimesMax] = useState(0);

  const getDayName = (yr, mn, dy) => {
    const date = new Date(yr, mn - 1, 1);  
    const month = date.toLocaleString('default', { month: 'short' });
//    return `${dy} ${month}, ${yr}`;
    return `${month} ${dy}, ${yr}`;
  }

  const loadData = (data) => {
    ca8debug(`${TAG} loadData started`,data);

    if (data && data.length > 0) {
        setLoading(true);

        let newTotalCrimesMin = 1000000;
        let newTotalCrimesMax = 0;
        
        const uniqueYr = [...new Set(data.map(x => x.cyear))].sort((a, b) => a - b);
        const uC = [...new Set(data.map(x => x.category))].sort((a, b) => a.localeCompare(b));

        const dt = [];
        let idx = 0;
 
        uniqueYr.forEach(yr => {
            const uniqueMonthofyear = [...new Set(data.filter(x => x.cyear == yr).map(x => x.cmonth))];
            uniqueMonthofyear.forEach(mn => {
                const uniqueDayofmonth = [...new Set(data.filter(x => x.cyear == yr && x.cmonth == mn).map(x => x.cday))];
                uniqueDayofmonth.forEach(dm => {
                    idx++;
                    let res = {};      
                    res['year'] = yr;
                    res['month'] = mn;
                    res['day'] = dm;
                    res['label'] = getDayName(yr, mn, dm);
                    let total = 0;
                    uC.forEach(ct => {
                        const val = data.filter(x => 
                            ((x.cyear === yr && x.cmonth == mn && x.cday == dm && x.category == ct)))
                            .map(y => y.value).reduce((a, b) => +a + +b, 0);
                        res[ct] = val;   
                        total += val;                             
                    });
                    // set new totals
                    if (total > newTotalCrimesMax) newTotalCrimesMax = total;
                    //if (total < newTotalCrimesMin) newTotalCrimesMin = total;
                    res['total'] = total;
                    dt.push(res); 
                });
                ca8debug(`${TAG} loadData uniqueDayofmonth`, uniqueDayofmonth);    
            });
        }); 

        ca8debug(`${TAG} loadData dt`, dt, uniqueYr,uC);
        if (uniqueYr && uniqueYr.length > 0) {
            loadChartData(props.filter.startDate, props.filter.endDate, uniqueYr, dt, uC);
            //setSelectedYear(uniqueYr[0]);
        } else {
            //setSelectedYear(0);
        }
        //setTotalCrimesMin(newTotalCrimesMin);
        setTotalCrimesMax(newTotalCrimesMax);
        setUniqueYear(uniqueYr);
        setUniqueCategory(uC);
        setData(dt);
        setLoading(false);

    } else {
        setData([]);
        setChartData([]);
        //setSelectedYear(0);
        setUniqueYear([]);
        setUniqueCategory([]);
    }
  }

  React.useEffect(() => {
    ca8debug(`${TAG} useEffect props`,props);
    loadData(props.data);
  }, [props.data]);

  React.useEffect(() => {
    ca8debug(`${TAG} useEffect printing`,printing);
    if (exporting || printing) {
      doExportToPdf();
    }
  }, [exporting, printing]);

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  }

  const getComponents = () => {

    const uniqueMonthofyear = [...new Set(data.map(x => `${x.year}#${x.month}`))];

    ca8debug(`${TAG} getComponents`,uniqueMonthofyear);

    return uniqueMonthofyear.map(x => {
        const arr = x.split('#');
        const dt = data.filter(x => x.year == +arr[0] && x.month == +arr[1]);
        return <CalendarMonthComponent data={dt} year={+arr[0]} month={+arr[1]} categories={uniqueCategory} />
    });
  }

  const loadChartData = (startDt, endDt, uniqueYear, lst, uc) => {

    //const dt = lst.filter(x => x.year == value);

    //console.log(`${TAG} loadChartData started 0`, uniqueYear);

    //console.log(`${TAG} loadChartData started`, uniqueYear, lst, uc, mainFilter);

    exportRef.current = [];

    const dt = [];
 
    // add absent days
    // with filter's start date and end date check
    const startDateString = startDt && startDt.length > 0 ? startDt : '1970-01-01';
    const endDateString = endDt && endDt.length > 0 ? endDt : '3000-01-01';

    const startDate = new Date(startDateString).setHours(0, 0, 0, 0);
    const endDate = new Date(endDateString).setHours(0, 0, 0, 0);
    //console.log(`${TAG} loadChartData FFF`, mainFilter, startDate, endDate);

    uniqueYear.forEach(yr => {
      

      for (let mn = 1; mn <= 12; mn ++) {
        const days = daysInMonth(mn, yr);
        for (let dm = 1; dm <= days; dm ++) {
          // respect filter date range
          const itemDate = new Date(yr, mn - 1, dm).setHours(0, 0, 0, 0);

          ca8debug(`${TAG} loadChartData itemDate`, yr, mn, dm, itemDate, startDate, endDate);
          if (itemDate >= startDate && itemDate <= endDate) {
          
            let res = {};      
            res['year'] = yr;
            res['month'] = mn;
            res['day'] = dm;
            res['label'] = getDayName(yr, mn, dm);
            const existingDay = lst.find(x => (x.year == yr && x.month == mn && x.day == dm));
            uc.forEach(ct => {
              if (existingDay) {
                res[ct] = existingDay[ct];
              } else {
                res[ct] = 0;                                
              }
            });

            dt.push(res);
          }
        }
      }
    });

    ca8debug(`${TAG} loadChartData dt 1`, lst);
    ca8debug(`${TAG} loadChartData dt 2`, dt);

    setChartData(dt);
    //setSelectedYear(value);
  }
  
  const addRef = (el) => {
    ca8debug(`${TAG} addRef started`, el);
     if (el && !exportRef.current.includes(el)) {
      ca8debug(`${TAG} addRef`, el);
      exportRef.current.push(el);
     }
  }

  const renderLegend = (props) => {
    const { payload } = props;

    ca8debug(`${TAG} renderLegend props`, props);
  
    return (
      <table style={{ width: '100%', margin: 0, padding: 0 }}>
        {
          payload.map((entry, index) => (
            <tr key={`item-${index}`}>
              <td key={`item-${index}-1`} style={{ textAlign: 'center' }}>
                <table style={{ width: 150, fontSize: 20 }}>
                  <tr key={`item-2-${index}`}>
                    <td key={`item-2-${index}-1`} style={{ width: 20, height: 20, backgroundColor: entry.color }}></td>
                    <td key={`item-2-${index}-2`}>{entry.value}</td>
                  </tr>
                </table>
              </td>
            </tr>          ))
        }
      </table>
    );
  }
  const getPdfChart = () => {

    ca8debug(`${TAG} getChart`,loading, chartData);

    const uniqueYear = [...new Set(chartData.map(x => x.year))];
    const uniqueMonth = [...new Set(chartData.map(x => x.month))];
    const result = [];
    uniqueYear.forEach(yr => {
    for (let i = 0; i < uniqueMonth.length; i++) {
      const dt = chartData.filter(x => x.year == yr && x.month == uniqueMonth[i]);
      ca8debug(`${TAG} getChart dt[${i}]`,dt,uniqueMonth);

      result.push(<div ref={addRef} style={{ opacity: 0.01 }}><ResponsiveContainer width="100%" height={dt && dt.length > 15 ? 35 * dt.length : 400}>
        <BarChart
              key={`chart_${i}`}
              layout="vertical"
              data={dt}
              margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
              }}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis minTickGap={10} dataKey="label" type="category" tick={{ fontSize: 20, width: 250 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={20} />
              <XAxis type="number" tickMargin={30} fontSize={20} allowDecimals={false} allowDataOverFlow={true} domain={[0,totalCrimesMax]}/>
              <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />
              <Legend content={renderLegend} />
              {uniqueCategory.map(x => <Bar isAnimationActive={false} barSize={25} maxBarSize={25} dataKey={x} stackId="a" fill={getFillForCategory(x)}/>)}
        </BarChart></ResponsiveContainer></div>);
      }
    });

    ca8debug(`${TAG} getChart result`,result);
    return result; 
  }

  const getScreenChart = () => {

    ca8debug(`${TAG} getScreenChart`,loading, chartData);

    const uniqueYear = [...new Set(chartData.map(x => x.year))];
    const uniqueMonth = [...new Set(chartData.map(x => x.month))];
    const result = [];
    uniqueYear.forEach(yr => {
    for (let i = 0; i < uniqueMonth.length; i++) {
      const dt = chartData.filter(x => x.year == yr && x.month == uniqueMonth[i]);
      ca8debug(`${TAG} getChart dt[${i}]`,dt,uniqueMonth);

      result.push(<div ref={addRef}><ResponsiveContainer width="100%" height={dt && dt.length > 15 ? 25 * dt.length : 400}>
        <BarChart
              key={`chart_${i}`}
              layout="vertical"
              data={dt}
              margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
              }}
          >
              <CartesianGrid strokeDasharray="3 3" />
              <YAxis minTickGap={10} dataKey="label" type="category" tick={{ fontSize: 10, width: 250 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} />
              <XAxis type="number" allowDecimals={false} allowDataOverFlow={true} domain={[0,totalCrimesMax]}/>
              <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />
              <Legend />
              {uniqueCategory.map(x => <Bar isAnimationActive={false} barSize={25} maxBarSize={25} dataKey={x} stackId="a" fill={getFillForCategory(x)}/>)}
        </BarChart></ResponsiveContainer></div>);
      }
    });

    ca8debug(`${TAG} getScreenChart result`,result);
    return result; 
  }

  const exportToPdf = async (el, imageFileName) => {
    ca8debug(`${TAG} exportToPdf started`,exportRef.current);
    setExporting(true);
  }

  const doExportToPdf = async () => {
    ca8debug(`${TAG} exportToPdf started`,exportRef.current);
    //setLoading(true);

    const images = []; 
    for (const x of exportRef.current) {
      if (x) {
        //const data = await htmlToImage.toJpeg(x, { quality: 0.9, backgroundColor: 'white' });
        const data = x.innerHTML;
        ca8debug(`${TAG} exportToPdf image x blob`, x, data);
        images.push({image: data});
      }
    }

    ca8debug(`${TAG} exportToPdf images`, images,JSON.stringify(images));

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("charts", JSON.stringify(images));
    formData.append("title", 'Frequency');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", false);

    ca8debug(`${TAG} exportToPdf formData`, formData);

    apiClient.post(`api/clients/${clientId}/freq`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      if (exporting) {
        setExporting(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        setPrinting(false);
        const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
        printPdf(data);
      }
      }).catch((error) => {
        message.error(error);
        if (exporting) {
          setExporting(false);
        } else {
          setPrinting(false);
        }
      });    

  }

  const exportToPrint = async () => {
    
    //ca8debug(`${TAG} exportToPrint data`, data, allFilters);
  
    //setLoadingMessage('Prepare to print ...');
    setPrinting(true);
/*    
    setLoading(true);

    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const images = []; 
    for (const x of exportRef.current) {
      if (x) {
        const data = await htmlToImage.toJpeg(x, { quality: 0.9, backgroundColor: 'white' });
        ca8debug(`${TAG} exportToPdf image x blob`, x, data);
        images.push({image: data});
      }
    }

    ca8debug(`${TAG} exportToPdf images`, images,JSON.stringify(images));

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("charts", JSON.stringify(images));
    formData.append("title", 'Calendar');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", false);

    ca8debug(`${TAG} exportToPdf formData`, formData);

    apiClient.post(`api/clients/${clientId}/freq`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);

      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);
    }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    

*/
  }

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  


  return (
        
    <Layout className="site-layout">

      <LegendComponent legend={uniqueCategory} />

      <div>
        <Menu mode="vertical" style={{width: 200, border: "1px solid lightgray", marginBottom: 20 }}>
          <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
            <Menu.Item icon={<FilePdfOutlined />} onClick={() => {
              exportToPdf(exportRef.current, "test");
            }} key="export">PDF</Menu.Item>
            <Menu.Item icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
          </SubMenu>
        </Menu>
      </div>         

      <Content>

      <Spin tip="Loading ..." spinning={loading}>
        <div ref={exportRef}>
        {chartData && chartData.length > 0
        ? (exporting || printing) ? getPdfChart() : getScreenChart()
        : <div style={{width: '100%', height: 400, padding: 100}}><Empty /></div>}
        </div>
      </Spin>
             
      </Content>
    </Layout>
  );
}


export default FrequencyPage3;
