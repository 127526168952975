import React, { useContext } from "react";
import { Tooltip, Button, Popconfirm } from 'antd';
import { AppContext } from "../contexts/AppContext";
import {
    LogoutOutlined,
  } from '@ant-design/icons';

const LogoutButton = (props) => {
  const appContext = useContext(AppContext);
  const { logout } = appContext;
  return (
    <div style={{position: 'absolute',top: 5,right: 5,zIndex: 10000,}}>
        <Tooltip placement="bottomLeft" title={'Logout'}>
            <Popconfirm
                title="Are you sure to log out?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => logout()}
            >
                <Button shape="circle" icon={<LogoutOutlined />} size="large" />
            </Popconfirm>
        </Tooltip>
    </div>
  );
};

export default LogoutButton;
