import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend, formatExpertYear,getThresholdReferences, ca8debug } from './Utils';

import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, message, Checkbox, Menu, Space } from 'antd';
import { AppContext } from "../contexts/AppContext";

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
} from '@ant-design/icons';
import MaximizeMinimizeButton from './buttons/MaximizeMinimizeButton';

const { Option } = Select;
const { SubMenu } = Menu;

const TimeChart = (props) => {
    const TAG = 'TimeChart';

    const exportRef = useRef();

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  
    
    ca8debug(`${TAG} props`, props);

    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('year');
    const [yearFilter, setYearFilter] = React.useState([]);
    const [monthFilter, setMonthFilter] = React.useState([]);
    const [quarterFilter, setQuarterFilter] = React.useState([]);
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(true);

    const [incidentData, setIncidentData] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');
    const [exportAggregatedData, setExportAggregatedData] = React.useState(true);

    const columns = [
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        //sorter: true,
        //filters: yearFilter,
        filteredValue: allFilters.year || null,
        onFilter: (value, record) => {
          return record.year == value;
        },      
      },
      {
        title: 'Quarter',
        dataIndex: 'quarter',
        key: 'quarter',
        //sorter: true,
        //filters: quarterFilter,
        filteredValue: allFilters.quarter || null,
        onFilter: (value, record) => {
          return record.quarter.includes(value);
        },      
      },
      {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
        //sorter: true,
        //filters: monthFilter,
        filteredValue: allFilters.month || null,
        onFilter: (value, record) => {
          return record.month.includes(value);
        },      
      },
      {
        title: 'Crime Group',
        dataIndex: 'category',
        key: 'category',
        //sorter: true,
        //filters: categoryFilter,
        filteredValue: allFilters.category || null,
        onFilter: (value, record) => {
          return record.category.includes(value);
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
      },
    ];
  
    ca8debug(`${TAG} started`);
  
    const tableRef = React.useRef();
  
    React.useEffect(() => {
  
      const filter = props.filter;
      setLoading(true);
      setLoadingMessage('Loading ...');

      const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      apiClient.post(`api/clients/${clientId}/chart/time`, filter)
      .then(response => {
          ca8debug(`${TAG} data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'year': x.cyear, 
                    'month': x.cmonth, 
                    'weekday': x.weekday, 
                    'timeperiod': x.timeperiod, 
                    'quarter': x.cquarter, 
                    'category': x.category, 
                    'crime': x.crime, 
                    'value': x.value, 
                    'smonth': x.smonth, 
                    'sday': x.sday, 
                    'shour': x.shour  
                }
              });            
              ca8debug(`${TAG} dt`, dt);

              updateFilters(dt);

              setData(dt);
              // set initial chart data
              setChartData(dt);
              //updateTableData(dt, allFilters, allSorters);

              setLoading(false);
        });
    }, [props.refreshed]);

  const updateFilters = (dt) => {
    // read year filter
    const uniqueYear = [...new Set(dt.map(x => x.year))];
    ca8debug('uniqueYear', uniqueYear);
    setYearFilter(uniqueYear.map(x => { return {'text': x, 'value': x}}));
    // read month filter
    const uniqueMonth = [...new Set(dt.map(x => x.month))];
    ca8debug('uniqueMonth', uniqueMonth);
    setMonthFilter(uniqueMonth.map(x => { return {'text': x, 'value': x}}));
    // read category filter
    const uniqueCategory = [...new Set(dt.map(x => x.category))];
    ca8debug('uniqueCategory', uniqueCategory);
    setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));            
    // read crime filter
    const uniqueCrime = [...new Set(dt.map(x => x.crime))];
    ca8debug('uniqueCrime', uniqueCrime);
    setCrimeFilter(uniqueCrime.map(x => { return {'text': x, 'value': x}}));   
    // create quarter filter
    const uniqueQuarter = [1, 2, 3, 4];         
    setQuarterFilter(uniqueQuarter.map(x => { return {'text': x, 'value': x}})); 
  }

  const onExportAggregatedDataChanged = (e) => {
    setExportAggregatedData(e.target.checked);
  }  

  const filterUsingAllFilters = () => {
    ca8debug(`${TAG} filterUsingAllFilters data`, data, allFilters);
    let result = data.slice();
    if (allFilters.year) {
      result = result.filter(x => allFilters.year.includes(x.year));
    }
    if (allFilters.quarter) {
      result = result.filter(x => allFilters.quarter.includes(x.quarter));
    }
    if (allFilters.month) {
      result = result.filter(x => allFilters.month.includes(x.month));
    }
    if (allFilters.category) {
      result = result.filter(x => allFilters.category.includes(x.category));
    }
    ca8debug(`${TAG} filterUsingAllFilters result`, result);
    return result;
  }

  const exportToPdf = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPdf data`, data, allFilters);

    setLoadingMessage('Export to PDF ...');
    setLoading(true);

    const filteredData = filterUsingAllFilters();

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPdf image`, image);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", exportAggregatedData);
    formData.append("data", JSON.stringify(filteredData));

    apiClient.post(`api/clients/${clientId}/pdftime`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const filteredData = filterUsingAllFilters();

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", exportAggregatedData);
    formData.append("data", JSON.stringify(filteredData));

    //formData.append("data", JSON.stringify(props.data));

    apiClient.post(`api/clients/${clientId}/xlstime`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const onTableChange = (pagination, filters, sorter) => {
    
    setAllFilters(filters);
    setAllSorters(sorter);

    updateTableData(data, filters, sorter);

  }

  const updateTableData = (dd, filters, sorter) => {
    let newData = dd.slice();
    ca8debug(`${TAG} onTableChange filters`, filters, sorter, newData);
    if (filters.year) {
      newData = newData.filter(x => filters.year.includes(x.year));
    }
    if (filters.quarter) {
        newData = newData.filter(x => filters.quarter.includes(+x.quarter));
    }
    if (filters.month) {
      newData = newData.filter(x => filters.month.includes(x.month));
    }
    if (filters.category) {
      newData = newData.filter(x => filters.category.includes(x.category));
    }
    if (filters.crime) {
      newData = newData.filter(x => filters.crime.includes(x.crime));
    }      

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }
    updateFilters(newData);
    setChartData(newData);
  }
  
  const onChartModeChange = (value) => {
    ca8debug(`${TAG} onChartModeChange selected ${value}`);
    setChartMode(value);
  }

  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData);
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('year');
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
    if (e.activePayload && e.activePayload.length == 1) {
      const payloadData = e.activePayload[0];
      if (payloadData && payloadData.payload) {
        const newChartMode = chartMode == 'year' ? 'month' : 'month';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (chartMode == 'year') {
          newAllFilters['year'] = [payloadData.payload.name];
        } else if (chartMode == 'quarter') {
          newAllFilters['quarter'] = [payloadData.payload.name];
        } 
        //else if (chartMode == 'month') {
        //    newAllFilters['month'] = [payloadData.payload.name];
        //}

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
      }
    }
  }

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnDrillUp`, allFilters);
    // change chart mode
    if (chartMode != 'year') {
        const newChartMode = 'year';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (newChartMode == 'year') {
          newAllFilters['year'] = undefined;
          newAllFilters['month'] = undefined;
        }
        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
        
    }
  }   
 
  const getChart = () => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, chartData);
    ca8debug(`${TAG} sssssss`, props);
    // get unique values
    const uniqueYear = [...new Set(chartData.map(x => x.year))].sort((a, b) => (+a) - (+b));
    const uniqueQuarter = [1, 2, 3, 4];
    // sort months
    const uniqueMonth = [...new Set(chartData.map(x => `${x.smonth}#${x.month}`))]
        .sort((a, b) => (+a.split('#')[0]) - (+b.split('#')[0])).map(x => x.split('#')[1]);

    if (chartMode == 'year') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueYear.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = formatExpertYear(x, data.expertYear);
        let val = chartData.filter(y => ((y.year === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);

    } else if (chartMode == 'quarter') {

        let result = [];
        let idx = 0;
        let maxVal = 0;
        uniqueQuarter.forEach(x => {
          let res = {};          
          res['id'] = idx;
          res['name'] = x;
          let val = chartData.filter(y => ((+y.quarter === +x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
          res['value'] = val;
          result.push(res);
          idx++;
        });
        return getChartItem(result);
  
      } else {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueMonth.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.month === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);

    } 
  }    

  const getChartItem = (data) => {
    return <BarChart
      isAnimationActive={false}
      layout="vertical"
      onClick={OnChartClick}
      width={550}
      height={300}
      data={data}
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="3 3" />

      <XAxis type="number" />
      <YAxis type="category" tick={{ fontSize: 10, width: 150 }} width={100} padding={{ left: 20, right: 20 }} interval={0} fontSize={10} dataKey="name"/>

      <Bar barSize={20} isAnimationActive={false}  dataKey='value' fill="#7B778C" >
        <LabelList dataKey="value" position="right" fill="#000000"  />
      </Bar>     
    
      {getThresholdReferences(data, true, false, props.minimized)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />

    </BarChart>    
  }

  const expandable = { expandedRowRender: record => <p>{record.description}</p> };

  const showDetails = (record) => {

    ca8debug(`${TAG} showDetails 1`, record, props.data)

    const filteredData = props.data.filter(x => 
        x.cyear == record.year && 
        x.quarter == record.quarter &&
        x.cmonth == record.month &&
        x.crime_category == record.category 
        );

    ca8debug(`${TAG} showDetails 2`, record, filteredData)
    
    const cols = [
        { title: 'id',dataIndex: 'incident_id',key: 'incident_id' },
        { title: 'Site',dataIndex: 'site_name',key: 'site_name' },
        { title: 'Crime Type',dataIndex: 'crime_type',key: 'crime_type' },
        { title: 'Date',dataIndex: 'incident_date',key: 'incident_date' },
        { title: 'Time',dataIndex: 'incident_time',key: 'incident_time' },
        { title: 'Location',dataIndex: 'incident_location',key: 'incident_location' },
        { title: 'Victim',dataIndex: 'incident_victim',key: 'incident_victim' },
        { title: 'Comments',dataIndex: 'incident_comments',key: 'incident_comments' },
        { title: 'Offense Report',dataIndex: 'incident_offence_report_no',key: 'incident_offence_report_no' },
    ];

    return <Table 
        size="small"
        key={`table_${record.year}_${record.quarter}_${record.month}_${record.category}`}
        columns={cols} 
        dataSource={filteredData}
        />
  }

  const CustomLegend = () => {
    if (chartMode == 'year') {
      return FiltersLegend(allFilters, resetFilters, null);

    }
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const showHideTable = () => {
    
    const isShowTable = !showTable;

    setShowTable(isShowTable);
  }    

  return (
    <div id="divTimeChart" className="chart-container" style={{ width: props.minimized ? 500 : props.twoColumns ? 650 : 1000}}>
      {props.minimized || loading || props.loading
        ? ''
        :  <Menu style={{ position: 'absolute', top: 10, right: 10, width: 150 }} mode="vertical">
        <SubMenu key="sub1" icon={<PrinterOutlined />} title="Export">
            <Checkbox checked={exportAggregatedData} onChange={onExportAggregatedDataChanged} style={{margin: 15}}>
              Export aggregated data
            </Checkbox>
            <Menu.Item icon={<FilePdfOutlined />} onClick={() => {
              exportToPdf(exportRef.current, "test");
            }} key="1">PDF</Menu.Item>
            <Menu.Item icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
          </SubMenu>
          </Menu>
        
      }     

      {props.twoColumns
        ? ''
        : <MaximizeMinimizeButton minimized={props.minimized} onClick={props.onMinimizedChange}/> 
      }

      <h2>Year / Month
        <Space direction='horizontal' style={{ marginLeft: 20 }}>
          {props.minimized
            ? ''
            : <Button 
              icon={<TableOutlined />} 
              loading={props.loading} 
              size='small' 
              type="ghost" 
              onClick={() => props.showChartData('time', 'Year / Month', chartData, columns)}>
              Show data</Button>}
        </Space>
      </h2>

      <Spin tip={loadingMessage} spinning={loading}>

      {loading
        ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>

        : data && data.length > 0
          ? <div ref={exportRef}>
              <ResponsiveContainer width='100%' height={400}>
              {getChart()}
              </ResponsiveContainer>
              <CustomLegend/>
            </div>
          : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
      }
{/*}
      <div style={{ marginTop: 50, marginBottom: 20, position: 'relative' }}>
        <Button icon={<TableOutlined />} loading={props.loading} size='small' type="ghost" onClick={() => showHideTable()}>{ showTable ? 'Hide data' : 'Show data' }</Button>
      </div>

      {showTable
      ? <Table 
        ref={tableRef} 
        rowKey='id'
        columns={columns} 
        dataSource={data} 
        onChange={onTableChange}
        expandable={{
            expandedRowRender: (record) => showDetails(record),
          }}         
        />
      : ''}
*/}
    </Spin>
    </div>
  );
}



export default TimeChart;