import React, { useRef, useState, useEffect } from 'react'
import { Collapse, Button, Slider } from 'antd';
import { useOnScreen, ca8debug, getFillForCategory } from './Utils';
import { SettingOutlined,
    DownOutlined,
    UpOutlined,
    FilterOutlined,
    GlobalOutlined,
    AlertOutlined, 
    PropertySafetyFilled} from '@ant-design/icons';

const { Panel } = Collapse;
const quickAndDirtyStyle = {
  width: 400,
  //height: "200px",

  /* display: "flex", */
  //justifyContent: "center",
  //alignItems: "center",
  //position: 'sticky',
  padding: 10,
  //borderBottom: '1px solid white',
  zIndex: 10000
}

const DraggableComponent = (props) => {

  const TAG = 'DraggableComponent';

  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({x: 0, y: 0})
  const [collapsed, setCollapsed] = useState(true)
  const [opacity, setOpacity] = useState(100)
  const ref = useRef()

  const parentWidth = props.parentWidth;

  // Monitor changes to position state and update DOM
  useEffect(() => {
    //console.log(`${TAG} props.parentWidth`, props.parentWidt);
    if (ref.current) {
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`
    }
  }, [position])

  // Update the current position if mouse is down
  const onMouseMove = (event) => {
    if (pressed) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY
      })
    }
  }

  const onMouseLeave = (event) => {
    setPressed(false);
  }  

  const onOpacityChange = (e) => {
    setOpacity(e);
  }

  const getColorsLegend = () => {
    return props.colors && props.colors.length > 0 
    ? <div style={{ backgroundColor: 'white', color: 'black', padding: 10}} >
      <div><b>Legend</b></div>
      <table>
        {props.colors.map(x => {
            return <tr>
                <td style={{ width: 170 }}>{x}</td>
                <td style={{ width: 30, backgroundColor: getFillForCategory(x)}}></td>
            </tr>
        })}
    </table></div>
    : ''
  }  

  return (
    <div
      ref={ ref }
      className="noselect"
      style={{
        position: 'absolute',
        width: 400,
        //height: 200px;
        /* margin: '0 auto',  /* Will not center vertically and won't work in IE6/7. */
        //left: 0,
        //right: 0,


        //width: 400,
        //position: 'fixed',
        top: 0,
        right: 80,
        padding: 1,
        zIndex: 10000,
        opacity: opacity / 100,
        background: "#FF9900", 
        color: "#FFFFFF", 
   }}
      >
        <div 
            style={{ 
            padding: 10, 
            height: 50,  
            background: "#FF9900", 
            color: "#FFFFFF", 
            cursor: pressed ? 'grabbing' : 'grab'
            }}
            onMouseLeave={ onMouseLeave }
            onMouseMove={ onMouseMove }
            onMouseDown={ () => setPressed(true) }
            onMouseUp={ () => setPressed(false) }  
        >
            <FilterOutlined style={{ padding: 5 }}  />Filter options {props.legend ? "" : <b>No filters applied</b>} 
  
            <Button style={{float: 'right'}} type="ghost" shape="circle" icon={collapsed ? <DownOutlined /> : <UpOutlined />} onClick={() => setCollapsed(!collapsed)} />
        </div>
        {collapsed
            ? ''
            : <div style={{ backgroundColor: 'white', color: 'black', padding: 10}} >
                {props.legend ? props.legend : ""}
{/*                
                <div>
                    <span style={{width: 70, display: 'inline-block', verticalAlign: 'top'}}>Opacity:</span>
                    <span style={{width: 100, display: 'inline-block'}}>
                        <Slider width={100} min={50} max={100} onChange={onOpacityChange} value={opacity} />
                    </span>
                    <span style={{width: 50, display: 'inline-block', verticalAlign: 'top', marginLeft: 10}}>{opacity}</span>    
                </div>
*/}                
            </div> 
        }
        {/*props.colors 
          ? getColorsLegend()
          : ''
      */}
    </div>
  )
}

export default DraggableComponent;