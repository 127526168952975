import React, { useContext, useState } from "react";
import 'antd/dist/antd.css';
import { AppContext } from "../contexts/AppContext";

import { Input,Tooltip,Button, Space } from 'antd';
import { UserOutlined,LockOutlined,EyeOutlined,EyeInvisibleOutlined } from '@ant-design/icons';

import { useCookies, CookiesProvider } from 'react-cookie';

import AuthMenu from "./AuthMenu";

const AuthLogin2 = () => {
  const TAG = 'AuthLogin2';

  const appContext = useContext(AppContext);
  const {
    userEmail,
    userPassword,
    handleUserEmail,
    handleUserPassword,
    login,
    loginInProgress,
    errorMessage,
  } = appContext;
  const [hidePassword, setHidePassword] = useState(true);

  const [cookie, setCookie, removeCookie] = useCookies(['ca8']);

  const showHiddenPassword = hidePassword ? "" : "hidden";
  const showRevealedPassword = hidePassword ? "hidden" : "";
  function togglePassword() {
    setHidePassword(!hidePassword);
  }

  //console.log('AuthLogin2', errorMessage);

  return (
      <div className="login-container">
        <div className="login-header-image">
        </div>
        <Space direction="vertical" style={{ width: '100%'}}>
            <Input prefix={<UserOutlined style={{marginRight: 10}} />}  placeholder="Email" value={userEmail} onChange={handleUserEmail}/>
            <Input.Group large>
                <Input style={{ width: 'calc(100% - 40px)' }} prefix={<LockOutlined style={{marginRight: 10}} />} 
                    placeholder="Password" type={hidePassword ? 'password' : 'text'}
                    value={userPassword} onChange={handleUserPassword}
                />
                <Tooltip title={hidePassword ? 'Show password' : 'Hide password'}>
                    <Button style={{ width: 40 }} icon={hidePassword ? <EyeOutlined /> : <EyeInvisibleOutlined />} onClick={() => togglePassword()}/>
                </Tooltip>
            </Input.Group> 

          <Button style={{position: 'absolute', bottom: 50, right: 50}}
            type="primary"
            onClick={() => login()}
            loading={loginInProgress}
          >
            Login
          </Button>
        </Space>
        <div>
          {errorMessage}
        </div>
      </div>
  );
};

export default AuthLogin2;
