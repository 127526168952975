import 'antd/dist/antd.css';

import React, { useContext, useRef, useState, createRef } from 'react'
import ReactDOM from  'react-dom'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Layout, Menu, PageHeader, Table, Button, message, Empty, Spin,Collapse } from 'antd';
import FilterOptions2 from './FilterOptions2';
import { AppContext } from "../contexts/AppContext";
import AppHeader from './AppHeader';
import DraggableComponent from './DraggableComponent'
import PageTitle from './PageTitle'

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'

import { getProperDate,ca8debug } from './Utils';
import AppFooter from './AppFooter';

import * as htmlToImage from 'html-to-image';

import { DesktopOutlined,
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined,
  BarChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import CalendarMonthComponent from './CalendarMonthComponent';
import LegendComponent from './LegendComponent';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;

//import { readString } from 'react-papaparse'

const CalendarPage = (props) => {
  const TAG = 'CalendarPage';

  const exportRef = useRef([]);  

  const appContext = useContext(AppContext);
  const {
    apiClient,
    appRole,
    appClientId,
    appCurrentClient
  } = appContext;  

  const defaultFilter = {
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    dayOfWeek: '',
    crimeCategory: '',
    crimeType: '',
    group1: '',
    group2: '',
    site: '',
    relationship: ''
  }

  const [data, setData] = React.useState([]);
  const [mainFilter, setMainFilter] = React.useState(defaultFilter);
  const [loading, setLoading] = React.useState(true);
  
  const [uniqueCategory, setUniqueCategory] = useState([]);

  const loadData = (filter, data) => {
    ca8debug(`${TAG} loadData started`,filter, data);

    exportRef.current = [];

    if (data && data.length > 0) {
      const uniqueYear = [...new Set(data.map(x => x.cyear))].sort((a, b) => a - b);
      const uC = [...new Set(data.map(x => x.category))].sort((a, b) => a.localeCompare(b));;

      const dt = [];
      let idx = 0;

      uniqueYear.forEach(yr => {

        const uniqueMonthofyear = getUniqueMonth(yr); // [...new Set(data.filter(x => x.cyear == yr).map(x => x.cmonth))];
        uniqueMonthofyear.forEach(mn => {
          const uniqueDayofmonth = [...new Set(data.filter(x => x.cyear == yr && x.cmonth == mn).map(x => x.cday))];
          uniqueDayofmonth.forEach(dm => {
              idx++;
              let res = {};      
              res['year'] = yr;
              res['month'] = mn;
              res['day'] = dm;
              let total = 0;
              uC.forEach(ct => {
                const val = data.filter(x => 
                  ((x.cyear === yr && x.cmonth == mn && x.cday == dm && x.category == ct)))
                  .map(y => y.value).reduce((a, b) => +a + +b, 0);
                res[ct] = val;   
                total += val;                             
              });
              res['total'] = total;
              dt.push(res);
            });
                    
          });
      });

      ca8debug(`${TAG} dt`, dt);
      setUniqueCategory(uC);
      setData(dt);
      ca8debug(`${TAG} setLoading`);
      //setLoading(false);
    } else {
      setData([]);
    }
}

const daysInMonth = (year, month) => {
  return new Date(year, month, 0).getDate();
}

const getUniqueMonth = (yr) => {
  let result = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const startDate = new Date(props.filter.startDate);
  const endDate = new Date(props.filter.endDate);
  if (props.filter.startDate && props.filter.startDate.length > 0) {
    result = result.filter(x => {
      const days = daysInMonth(yr, x);
      const dt = new Date(yr, x - 1, daysInMonth(yr, x), 23, 59);
      ca8debug(`${TAG} getUniqueMonth startDate`, yr, x, days, startDate, dt,  new Date(yr, x, 0).getDate(), dt >= startDate);
      return dt >= startDate;
    });
  } 
  if (props.filter.endDate && props.filter.endDate.length > 0) {
    result = result.filter(x => {
      const dt = new Date(yr, x - 1, 1);
      ca8debug(`${TAG} getUniqueMonth endDate`, x, endDate, dt);
      return dt <= endDate;
    });
  }  

  ca8debug(`${TAG} getUniqueMonth result`, result, yr, startDate, endDate);
  return result;
}

  React.useEffect(() => {
    setLoading(true);
    loadData(props.filter,props.data);
    setLoading(false);
  }, [props.data]);

  const addRef = (el) => {
    ca8debug(`${TAG} addRef started`, el);
     if (el && !exportRef.current.includes(el)) {
      ca8debug(`${TAG} addRef`, el);
      exportRef.current.push(el);
     }
  }

  const getComponents = () => {

    const uniqueYear = [...new Set(data.map(x => x.year))];
    const uniqueMonthofyear = [];

    uniqueYear.forEach(yr => {
      const mn = getUniqueMonth(yr); 
      mn.forEach(m => {
        uniqueMonthofyear.push(`${yr}#${m}`);
      }) 
      ca8debug(`${TAG} getComponents mn`,mn);
      //uniqueMonthofyear.concat(mn.map(x => `${yr}#${x}`));
    });

    ca8debug(`${TAG} getComponents uniqueYear`,uniqueYear, uniqueMonthofyear, data);

    return uniqueMonthofyear.map((x, idx) => {
        const arr = x.split('#');
        const dt = data.filter(x => x.year == +arr[0] && x.month == +arr[1]);
        return <div style={{ display: 'block' }}>
          <div ref={addRef} style={{ display: 'content' }}>
            <CalendarMonthComponent key={`CalendarMonthComponent${idx}`} data={dt} year={+arr[0]} month={+arr[1]} categories={uniqueCategory} />
          </div>
        </div>
    });

  }

  const exportToPdf = async (el, imageFileName) => {
    ca8debug(`${TAG} exportToPdf started`,exportRef.current);
    setLoading(true);

    const images = []; 
    console.time(`${TAG} exportToPdf`);
    for (const x of exportRef.current) {
      if (x) {
        //const data = await htmlToImage.toJpeg(x, { quality: 0.5, backgroundColor: 'white' });
        //ca8debug(`${TAG} exportToPdf image x blob`, x, data);

        const data = x.innerHTML;
        ca8debug(`${TAG} exportToPdf image x blob`, x, data);

        images.push({image: data});
      }
    }
    console.timeEnd(`${TAG} exportToPdf`);

    ca8debug(`${TAG} exportToPdf images`, images,JSON.stringify(images));

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("charts", JSON.stringify(images));
    formData.append("title", 'Calendar');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", false);

    ca8debug(`${TAG} exportToPdf formData`, formData);

    apiClient.post(`api/clients/${clientId}/freq2`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    

  }

  const exportToPrint = async () => {
    
    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const images = []; 
    for (const x of exportRef.current) {
      ca8debug(`${TAG} exportToPrint image x`, x);
      if (x) {
//        const data = await htmlToImage.toJpeg(x, { quality: 0.5, backgroundColor: 'white' });
        const data = x.innerHTML;

        ca8debug(`${TAG} exportToPrint image x blob`, x, data);
        images.push({image: data});
      }
    }

    ca8debug(`${TAG} exportToPrint images`, images,JSON.stringify(images));

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("charts", JSON.stringify(images));
    formData.append("title", 'Calendar');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", false);

    ca8debug(`${TAG} exportToPdf formData`, formData);

    apiClient.post(`api/clients/${clientId}/freq2`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);

      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);
    }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    


  }

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  
  return (
        
    <Layout className="site-layout">

      <LegendComponent legend={uniqueCategory} />

      <div>
        <Menu mode="vertical" style={{width: 200, border: "1px solid lightgray", marginBottom: 20 }}>
          <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
            <Menu.Item icon={<FilePdfOutlined />} onClick={ async () => {
              setLoading(true);
              await exportToPdf(exportRef.current, "test");
            }} key="export">PDF</Menu.Item>
            <Menu.Item icon={<PrinterOutlined />} onClick={ async () => {
              setLoading(true);
              await exportToPrint(exportRef.current, "test");
            }} key="3">Print</Menu.Item>
          </SubMenu>
        </Menu>
      </div>        

      <Content>

        <Spin tip="Loading ..." spinning={loading}>
        {props.data && props.data.length > 0
        ? getComponents()
        : <div style={{width: '100%', height: 400, padding: 100}}><Empty /></div>}        
        </Spin>
            
      </Content>
    </Layout>
  );
}


export default CalendarPage;
