import React, { useState } from "react";
import axios from "axios";
import { useCookies, Cookies } from 'react-cookie';
import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN,
} from "../constants/AuthStatus";

const AppContext = React.createContext();

const AppProvider = (props) => {
  const TAG = 'AppProvider';
//  let hostName = "https://v8.crimetag.com/data/public/";
/*  
  if (process.env.NODE_ENV === "development") {
//    hostName = "https://v8.crimetag.com/data/public/";
    hostName = "http://localhost/";
  } else if (process.env.NODE_ENV === "production") {
    hostName = "";
//    hostName = "http://localhost/";
  }
*/
  const [authStatus, setAuthStatus] = useState(NOT_LOGGED_IN);
  const [errorMessage, setErrorMessage] = useState("");
  const [userId, setUserId] = useState(0);
  const [userName, setUserName] = useState("");
  const [userNameInput, setUserNameInput] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState("");

  const [currentPage, setCurrentPage] = useState("dashboard_fixed3");
  const [cookie, setCookie] = useCookies(['ca8']);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const [appUserName, setAppUserNameState] = useState();
  const [appRole, setAppRoleState] = useState();
  const [appClientId, setAppClientIdState] = useState();
  const [appCurrentClient, setAppCurrentClientState] = useState();

  const [appUserId, setAppUserIdState] = useState(0);

  
  const apiClient = axios.create({
    timeout: 5000000,
    withCredentials: true,
//    withCredentials: false,
    baseURL: 'https://v8.crimetag.com/data/public/'
//      baseURL: `http://localhost/`
});

apiClient.interceptors.response.use(response => response, error => {
    if (error.response.status === 401 && authStatus === LOGGED_IN) {
      //console.log('apiClient.interceptors.response Unauthorized', error)
      logout()

        return Promise.reject()
    }

    return Promise.reject(error)
})  

  const loadClientInfo = async (uId, clientId) => {
    apiClient.get(`api/clients/${clientId}/info`)
      .then(response => {
        setAppCurrentClient(uId, response.data);
      })
      .catch(function (error) {
        console.error(`${TAG} loadClientInfo response`,error);

        localStorage.removeItem("appCurrentClient");
      });
  }
  

  function changeAuthStatusLogin() {
    setAuthStatus(LOG_IN_FORM);
  }

  function changeAuthStatusSignup() {
    setAuthStatus(SIGN_UP_FORM);
  }

  function handleUserNameInput(changeEvent) {
    let updatedUserName = changeEvent.target.value;
    setUserNameInput(updatedUserName);
  }

  function handleUserEmail(changeEvent) {
    let updatedUserEmail = changeEvent.target.value;
    setUserEmail(updatedUserEmail);
  }

  function handleUserPassword(changeEvent) {
    let updatedUserPassword = changeEvent.target.value;
    setUserPassword(updatedUserPassword);
  }

  const signup = () => {
    //axios.defaults.withCredentials = true;
    // CSRF COOKIE
    apiClient.get("sanctum/csrf-cookie").then(
      (response) => {
        ////console.log(response);
        // SIGNUP / REGISTER
        apiClient
          .post("api/register", {
            name: userNameInput,
            email: userEmail,
            password: userPassword,
          })
          .then(
            (response) => {
              ////console.log(response);
              // GET USER
              apiClient.get("api/user").then(
                (response) => {
                  ////console.log('###', response);
                  setUserId(response.data.id);
                  setUserName(response.data.name);
                  setErrorMessage("");
                  setAuthStatus(LOGGED_IN); 
                  
                  setAppUserName(response.data.name);
                  setAppRole(response.data.role);
                  setAppClientId(response.data.client_id);
                },
                // GET USER ERROR
                (error) => {
                  setErrorMessage("Could not complete the sign up");
                  logout();
                }
              );
            },
            // SIGNUP ERROR
            (error) => {
              if (error.response.data.errors.name) {
                setErrorMessage(error.response.data.errors.name[0]);
              } else if (error.response.data.errors.email) {
                setErrorMessage(error.response.data.errors.email[0]);
              } else if (error.response.data.errors.password) {
                setErrorMessage(error.response.data.errors.password[0]);
              } else if (error.response.data.message) {
                setErrorMessage(error.response.data.message);
              } else {
                setErrorMessage("Could not complete the sign up");
              }
            }
          );
      },
      // COOKIE ERROR
      (error) => {
        setErrorMessage("Could not complete the sign up");
      }
    );
  };


  const login = () => {
    
    // CSRF COOKIE
    setLoginInProgress(true);
    apiClient.get("sanctum/csrf-cookie").then(
      (response) => {
        // LOGIN
        apiClient
          .post("api/login", {
            name: userEmail,
            password: userPassword,
          })
          .then(
            (response) => {
              // GET USER
              apiClient.get("api/user").then(
                async (response) => {
                  setUserId(response.data.id);
                  setUserName(response.data.name);
                  setErrorMessage("");
                  setAuthStatus(LOGGED_IN);

                  if (response.data.role !== 2) {
                    setCurrentPage('dashboard_fixed3');
                    await loadClientInfo(response.data.id, response.data.client_id);
                  } else {
                    setCurrentPage('settings');
                  }

                  setAppRole(response.data.id, response.data.role);
                  setAppClientId(response.data.id, response.data.client_id);
                  setAppUserName(response.data.id, response.data.name);

                  setLoginInProgress(false);            

                },
                // GET USER ERROR
                (error) => {
                  logout();

                  setErrorMessage('Unable to retrieve current user info');
                  setLoginInProgress(false);            
                }
              );
            },
            // LOGIN ERROR
            (error) => {
              console.log(`${TAG} login - login error`, error,response);
              if (error && error.response) {
                setErrorMessage(error.response.data.message);
              } else {
                setErrorMessage("Could not complete the login");
              }
              setLoginInProgress(false);            
            }
          );
      },
      // COOKIE ERROR
      (error) => {
        setErrorMessage("Could not complete the login");
        setLoginInProgress(false);            
      }
    );
  };

  function logout() {
    apiClient.get("api/logout");

    setUserId(0);
    setUserName("");
    setUserNameInput("");

    localStorage.removeItem("appUserName");
    localStorage.removeItem("appRole");
    localStorage.removeItem("appClientId");
    localStorage.removeItem("appCurrentClient");

    setAuthStatus(NOT_LOGGED_IN);

  }

  const setAppRole = (id, value) => {
    setAppRoleState(value);
    return localStorage.setItem(`${id}_appRole`, value);
  }

  const setAppClientId = (id, value) => {
    setAppClientIdState(value);
    //localStorage.removeItem(`${id}_appClientId`)
    return localStorage.setItem(`${id}_appClientId`, value);
}

  const setAppCurrentClient = (id, value) => {
    setAppCurrentClientState(value);
    //localStorage.removeItem(`${id}_appCurrentClient`)
    return localStorage.setItem(`${id}_appCurrentClient`, JSON.stringify(value));
  }

  
  const setAppUserName = (id, value) => {
    setAppUserNameState(value);
    //localStorage.removeItem(`${id}_appUserName`)
    return localStorage.setItem(`${id}_appUserName`, JSON.stringify(value));
  }

  const storeFilterOptions = (value) => {
    return value 
      ? localStorage.setItem(`${userId}_filterOptions`, JSON.stringify(value)) 
      : localStorage.removeItem(`${userId}_filterOptions`);
  }

  const restoreFilterOptions = () => {
    const value = localStorage.getItem(`${userId}_filterOptions`);
    return value ? JSON.parse(value) : false;
  }

  const minimizedChartWidth = 200;
  const maximizedChartWidth = 1300;


  return (
    <AppContext.Provider
      value={{
        authStatus,
        changeAuthStatusLogin,
        changeAuthStatusSignup,
        userId,
        userName,
        userNameInput,
        userEmail,
        userPassword,
        handleUserNameInput,
        handleUserEmail,
        handleUserPassword,
        signup,
        login,
        logout,
        apiClient,
        errorMessage,
        currentPage,
        setCurrentPage,
        loginInProgress,
        appRole,
        setAppRole,
        appClientId,
        setAppClientId,
        appCurrentClient, 
        setAppCurrentClient,
        minimizedChartWidth,
        maximizedChartWidth,
        storeFilterOptions,
        restoreFilterOptions,
        appUserName,
        userId,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export { AppContext, AppProvider };
