import React, { useContext, useRef } from "react";
import { useOnScreen, ca8debug, getFillForCategory } from './Utils';
import { PieChart, Pie, LabelList, Cell, ResponsiveContainer } from 'recharts';

import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN,
} from "../constants/AuthStatus";
import AuthNotLoggedIn from "./AuthNotLoggedIn";
//import AuthSignup from "./AuthSignup";
//import AuthLogin from "./AuthLogin";
import AuthLogout from "./AuthLogout";
import { AppContext } from "../contexts/AppContext";
import AuthLogin2 from "./AuthLogin2";
import ChartsPage from "./ChartsPage";
import { Layout, Menu, Breadcrumb, Empty, Tooltip } from 'antd';
import {
    AreaChartOutlined,
    SettingOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    LogoutOutlined,
  } from '@ant-design/icons';

  
const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;

const CalendarMonthComponent = (props) => {

  const TAG = 'CalendarMonthComponent';

  const appContext = useContext(AppContext);
  const { authStatus, errorMessage } = appContext;
  const showNotLoggedIn = authStatus === NOT_LOGGED_IN ? "" : "hidden";
  const showLoginForm = authStatus === LOG_IN_FORM ? "" : "hidden";
  const showSignupForm = authStatus === SIGN_UP_FORM ? "" : "hidden";
  const showLoggedIn = authStatus === LOGGED_IN ? "" : "hidden";
  const { userName, logout, setCurrentPage, currentPage } = appContext;

  const componentRef = useRef()
//  const isVisible = useOnScreen(componentRef)

  React.useEffect(() => {
  
    ca8debug(`${TAG} useEffect started`,props.index);

  }, []);
// }, [isVisible]);

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  }

  function weekInMonth(month, year) {

    // month_number is in the range 1..12

    var firstOfMonth = new Date(year, month - 1, 1);
    var lastOfMonth = new Date(year, month, 0);

    var used = firstOfMonth.getDay() + lastOfMonth.getDate();

    return Math.ceil( used / 7);
}  
   
  const getTable = () => {
    const daysTotal = daysInMonth(props.month, props.year);
    const date = new Date(props.year, props.month - 1, 1);
    const firstDay = date.getDay()
    //let rowsTotal1 = (daysTotal + firstDay + 1) / 7;
    let rowsTotal = weekInMonth(props.month, props.year); //Math.ceil((daysTotal + firstDay + 1) / 7);
    
    ca8debug(`${TAG} getTable year: ${props.year}, month: ${props.month}, daysTotal: ${daysTotal}, rowsTotal: ${rowsTotal}`)
    //ca8debug(`${TAG} getTable year: ${props.year}, month: ${props.month}, daysTotal: ${daysTotal}, rowsTotal1: ${rowsTotal1}, rowsTotal: ${rowsTotal}, date: ${date}, firstDay: ${firstDay}`)
    return <table>
        <thead>
          <tr>
            <th colSpan="7">
              <div style={{ border: '1px solid lightgrey', width: '100%' }}>
                <div className="ant-table-title" style={{ textTransform: 'capitalize', textAlign: 'left'}}>{getPageTitle()}</div>
              </div> 
            </th>
          </tr>
        </thead>
        {Array.apply(null, Array(rowsTotal)).map((x,idx) => 
            <tr>
                {Array.apply(null, Array(7)).map((y, idy) => {
                  const currentCell = idx*7 + idy;
                  return getCell(currentCell - firstDay, daysTotal, firstDay);
                })} 
            </tr>
        )
        }
    </table>
}

const getPageTitle = () => {
  const date = new Date(props.year, props.month - 1, 1);  
  const month = date.toLocaleString('default', { month: 'long' });
  return `${month}, ${props.year}`;
}

function getDayName(day) {
    const date = new Date(props.year, props.month - 1, day);
    return date.toLocaleString('default', { weekday: 'short' }).toUpperCase();        
}

const getCell = (value, daysTotal, firstDay) => {
  //ca8debug(`${TAG} getCell for month ${props.month}`, daysTotal, firstDay, props.year, value, props.data)
  if (value < 0) {
    return getEmptyCell(value);
  } else if (value >= daysTotal) {
    return getEmptyCell(value);
  } else {
    const day = props.data.find(x => x.day == value + 1);
//    ca8debug(`${TAG} getCell day`, day)
        
    if (day) {
      const data = props.categories.map(x => {
        return {
          name: x,
          value: day[x]
        }
      }) 
  
      return <td style={{ position: 'relative', width: 160, height: 180, border: '1px solid lightgrey' }}>
         
        <div style={{ position: 'relative', width: 160, height: 180 }}>
          {getDayTitle(day.day)}
          <div className='date-title-total' style={{ position: 'absolute', display: 'inline-block', top: 3, right: 5, color: 'grey' }}>{`Total : ${day.total}`}</div>
          <div style={{ position: 'relative', width: '100%', height: '100%', paddingTop: 40 }}>
          <Tooltip title={getTooltipForDate(day.day, data)} color='white'>
          <ResponsiveContainer width="100%" height="100%">
          <PieChart 
          isAnimationActive={false}
          width={160} height={170}>
            <Pie
              data={data}
              labelLine={true}
              label={true}
              outerRadius={40}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((x, index) => (
                <Cell key={`cell-${index}`} fill={getFillForCategory(x.name)}></Cell>
              ))}
            </Pie>
          </PieChart>   
          </ResponsiveContainer> </Tooltip>
          </div>      
        </div>
      </td>
    } else {
      if (value < daysTotal) {
      return getNoDataCell(value);
      } else {
        getEmptyCell(value);
      }
    }
  }

}

const getTooltipForDate = (day, data) => {
  return <div>
    {getDayTitle(day)}
    {getMonthTitle()}
    <div style={{ paddingTop: 30, color: 'grey' }}>
      <table>
        {data.map(x => <tr><td style={{ width: 120, color: getFillForCategory(x.name), fontWeight: 600 }}>{x.name}:</td><td>{x.value}</td></tr>)}
      </table>
    </div>
  </div>

}

const getEmptyCell = (value) => {
  return <td style={{ backgroundColor: '#F0F2F5' }}></td>
}

const getNoDataCell22 = (value) => {
  return <td style={{ backgroundColor: '#F0F2F5' }}>
    <div style={{ position: 'relative', width: 160, height: 180, border: '1px solid lightgrey', paddingTop: 40 }}>
      {getDayTitle(value + 1)}
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    </div>
  </td>
}

const getNoDataCell = (value) => {
  return <td style={{ backgroundColor: '#F0F2F5', width: 160, height: 180, padding: 0, verticalAlign: 'top' }}>
    <table style={{ width: 160, height: 180, verticalAlign: 'top', borderSpacing: 0 }}>
      <tr>
        <td style={{ height: 40, verticalAlign: 'top' }}>{getDayTitle2(value + 1)}</td>
      </tr>
      <tr>
        <td style={{ height: 120, textAlign: 'center' }}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></td>
      </tr>
    </table>
  </td>
}


const getDayTitle = (day) => {
  return <div className="date-title-day-number">
    <table>
      <tr>
        <td className="date-title-day-value">{day}</td>
        <td className="date-title-day-interval"></td>
        <td className="date-title-day-name">{getDayName(day)}</td>
      </tr>
    </table>
  </div>
}

const getDayTitle2 = (day) => {
  return <div className="date-title-day-number-2">
    {day}<span className="date-title-day-name">{getDayName(day)}</span>
  </div>
}

const getMonthTitle = (day) => {
  return <div className="date-title-month-year">{getPageTitle()}</div>
}

  return (

    <div ref={componentRef} style={{ paddingBottom: 50 }}>
      {getTable()}
    </div>
  );
};

export default CalendarMonthComponent;
