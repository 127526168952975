import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend, formatExpertYear, getThresholdReferences, ca8debug } from './Utils';

import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, message, Checkbox, Menu, Space } from 'antd';
import { AppContext } from "../contexts/AppContext";

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined
} from '@ant-design/icons';
import MaximizeMinimizeButton from './buttons/MaximizeMinimizeButton';
import ChartDataDrawer from './drawers/ChartDataDrawer';

const { Option } = Select;
const { SubMenu } = Menu;

const TimeChart3 = (props) => {
    const TAG = 'TimeChart3';

    const exportRef = useRef();

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  
    
    ca8debug(`${TAG} props`, props);

    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('year');
    const [yearFilter, setYearFilter] = React.useState([]);
    const [monthFilter, setMonthFilter] = React.useState([]);
    const [quarterFilter, setQuarterFilter] = React.useState([]);
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(true);

    const [incidentData, setIncidentData] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');

    const [chartTableData, setChartTableData] = React.useState([]);
    const [chartTableColumns, setChartTableColumns] = React.useState([]);
    const [chartTableTitle, setChartTableTitle] = React.useState('');
    const [chartTableFilter, setChartTableFilter] = React.useState(undefined);
    const [chartTableMode, setChartTableMode] = React.useState('');    
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    const columns = [
      {
        title: 'Year',
        dataIndex: 'year',
        key: 'year',
        //sorter: true,
        //filters: yearFilter,
        filteredValue: allFilters.year || null,
        onFilter: (value, record) => {
          return record.year == value;
        },      
      },
      {
        title: 'Quarter',
        dataIndex: 'quarter',
        key: 'quarter',
        //sorter: true,
        //filters: quarterFilter,
        filteredValue: allFilters.quarter || null,
        onFilter: (value, record) => {
          return record.quarter.includes(value);
        },      
      },
      {
        title: 'Month',
        dataIndex: 'month',
        key: 'month',
        //sorter: true,
        //filters: monthFilter,
        filteredValue: allFilters.month || null,
        onFilter: (value, record) => {
          return record.month.includes(value);
        },      
      },
      {
        title: 'Crime Group',
        dataIndex: 'category',
        key: 'category',
        //sorter: true,
        //filters: categoryFilter,
        filteredValue: allFilters.category || null,
        onFilter: (value, record) => {
          return record.category.includes(value);
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
      },
    ];
  
    ca8debug(`${TAG} started`);
  
    const tableRef = React.useRef();
  
    React.useEffect(() => {
  
      const filter = props.filter;

      ca8debug(`${TAG} useEffect started`, props.allData, filter);

      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData && filter) {
        setLoading(true);
        setLoadingMessage('Loading ...');
        setDrawerVisible(false);
  
      apiClient.post(`api/clients/${clientId}/chart/time`, filter)
      .then(response => {
          ca8debug(`${TAG} data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'year': x.cyear, 
                    'month': x.cmonth, 
                    'weekday': x.weekday, 
                    'timeperiod': x.timeperiod, 
                    'quarter': x.cquarter, 
                    'category': x.category, 
                    'crime': x.crime, 
                    'value': x.value, 
                    'smonth': x.smonth, 
                    'sday': x.sday, 
                    'shour': x.shour  
                }
              });            
              ca8debug(`${TAG} dt`, dt);

              //updateFilters(dt);

              setData(dt);
              // set initial chart data
              //setChartData(dt);
              updateTableData(dt, allFilters, allSorters);

              setLoading(false);
        });
      }
    }, [props.filter]);

  const updateFilters = (dt) => {
    // read year filter
    const uniqueYear = [...new Set(dt.map(x => x.year))];
    ca8debug('uniqueYear', uniqueYear);
    setYearFilter(uniqueYear.map(x => { return {'text': x, 'value': x}}));
    // read month filter
    const uniqueMonth = [...new Set(dt.map(x => x.month))];
    ca8debug('uniqueMonth', uniqueMonth);
    setMonthFilter(uniqueMonth.map(x => { return {'text': x, 'value': x}}));
    // read category filter
    const uniqueCategory = [...new Set(dt.map(x => x.category))];
    ca8debug('uniqueCategory', uniqueCategory);
    setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));            
    // read crime filter
    const uniqueCrime = [...new Set(dt.map(x => x.crime))];
    ca8debug('uniqueCrime', uniqueCrime);
    setCrimeFilter(uniqueCrime.map(x => { return {'text': x, 'value': x}}));   
    // create quarter filter
    const uniqueQuarter = [1, 2, 3, 4];         
    setQuarterFilter(uniqueQuarter.map(x => { return {'text': x, 'value': x}})); 
  }

  const exportToPdf = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPdf data`, data, allFilters);

    setLoadingMessage('Export to PDF ...');
    setLoading(true);

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPdf image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Year / Month');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();

    const formData = new FormData();

    formData.append("chart", image);
    formData.append("title", 'Year / Month');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));

    apiClient.post(`api/clients/${clientId}/export`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);
        setLoading(false);

      });    
  };

  const exportToPrint = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPrint data`, data, allFilters);
  
    setLoadingMessage('Prepare to print ...');
    setLoading(true);

    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPrint image`, image);
  
    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();
  
    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Year / Month');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
  
      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);

    }).catch((error) => {
        setLoading(false);
        message.error(error);
    });    
  };

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  


  const onTableChange = (pagination, filters, sorter) => {
    
    setAllFilters(filters);
    setAllSorters(sorter);

    updateTableData(data, filters, sorter);

  }

  const updateTableData = (dd, filters, sorter) => {
    let newData = dd.slice();
    ca8debug(`${TAG} onTableChange filters`, filters, sorter, newData);
    if (filters.year) {
      newData = newData.filter(x => filters.year.includes(x.year));
    }
    if (filters.quarter) {
        newData = newData.filter(x => filters.quarter.includes(+x.quarter));
    }
    if (filters.month) {
      newData = newData.filter(x => filters.month.includes(x.month));
    }
    if (filters.category) {
      newData = newData.filter(x => filters.category.includes(x.category));
    }
    if (filters.crime) {
      newData = newData.filter(x => filters.crime.includes(x.crime));
    }      

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }
    updateFilters(newData);
    setChartData(newData);
  }
  
  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData);
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('year');
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
    if (e.activePayload && e.activePayload.length == 1) {
      const payloadData = e.activePayload[0];
      if (payloadData && payloadData.payload) {
        const newChartMode = chartMode == 'year' ? 'month' : 'month';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (chartMode == 'year') {
          newAllFilters['year'] = [payloadData.payload.name];
        } else if (chartMode == 'quarter') {
          newAllFilters['quarter'] = [payloadData.payload.name];
        } 
        //else if (chartMode == 'month') {
        //    newAllFilters['month'] = [payloadData.payload.name];
        //}

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
      }
    }
  }

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnDrillUp`, allFilters);
    // change chart mode
    if (chartMode != 'year') {
        const newChartMode = 'year';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (newChartMode == 'year') {
          newAllFilters['year'] = undefined;
          newAllFilters['month'] = undefined;
        }
        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
        
    }
  }   

  const getUniqueYear = () => {
    let result = props.allData.year.slice();
    const startDate = new Date(props.filter.startDate);
    const endDate = new Date(props.filter.endDate);
    if (props.filter.startDate && props.filter.startDate.length > 0) {
      result = result.filter(x => {
        const dt = new Date(x.value, 11, 31, 23, 59);
        
        return dt >= startDate;
      });
    } 
    if (props.filter.endDate && props.filter.endDate.length > 0) {
      result = result.filter(x => {
        const dt = new Date(x.value, 0, 1);
        
        return dt <= endDate;
      });
    }  

    ca8debug(`${TAG} getUniqueYear`, result, props.allData.year);
    return result.map(x => x.label).sort((a, b) => a - b);
  }

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  }

  const getUniqueMonth = (yr) => {
    let result = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
    const startDate = new Date(props.filter.startDate);
    const endDate = new Date(props.filter.endDate);
    if (props.filter.startDate && props.filter.startDate.length > 0) {
      result = result.filter(x => {
        const dt = new Date(yr, x, daysInMonth(yr, x), 23, 59);
        ca8debug(`${TAG} getUniqueMonth startDate`, x, startDate, dt);
        return dt >= startDate;
      });
    } 
    if (props.filter.endDate && props.filter.endDate.length > 0) {
      result = result.filter(x => {
        const dt = new Date(yr, x, 1);
        ca8debug(`${TAG} getUniqueMonth endDate`, x, endDate, dt);
        return dt <= endDate;
      });
    }  

    ca8debug(`${TAG} getUniqueMonth`, result, yr);
    return result;
  }

  const getMonthName = (yr, mn) => {
    const date = new Date(yr, mn, 1);  
    const month = date.toLocaleString('default', { month: 'long' });

    ca8debug(`${TAG} getMonthName`, yr, mn, date, month);
    return month;
  }

  const calculateTableData = () => {
    const td = {};
    td.items = [];
    let idx = 0;
    let incidx = 0;
    if (chartMode == 'year') {
      const uniqueYear = getUniqueYear();
      td.columns = [
        { title: 'Year',dataIndex: 'name',key: 'name' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];
      uniqueYear.forEach(x => {
        idx += 1;
        const tr1 = {};
        tr1.id = idx;
        tr1.name = x;
        tr1.value = chartData.filter(y => ((y.year === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        tr1.items = props.data.filter(y => ((y.cyear == x)));

        td.items.push(tr1);
      });
    } else {
      td.columns = [
        { title: 'Month',dataIndex: 'name', key: 'name' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];    
        const yr = allFilters.year[0];
      const uniqueMonth = getUniqueMonth(yr);
      uniqueMonth.forEach(x => {
        idx += 1;
        const tr1 = {};
        tr1.id = idx;
        tr1.name = getMonthName(yr, x);
        tr1.value = chartData.filter(y => ((+y.smonth === x + 1))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        tr1.items = props.data.filter(y => ((y.cyear == yr) && (+y.smonth === x + 1)));

        td.items.push(tr1);
      });

    }
    ca8debug(`${TAG} calculateTableData ${idx} / ${incidx}`,chartData, td, props.data, allFilters);
    return td;
  }

  const getChart = () => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, allFilters);

    if (chartMode == 'year') {
      let result = [];
      let idx = 0;
      let maxVal = 0;
      const uniqueYear = getUniqueYear();
      uniqueYear.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.year === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);
    } else {

      const yr = allFilters.year[0];

      let result = []; 
      let idx = 0;
      let maxVal = 0;
      const uniqueMonth = getUniqueMonth(yr);
      uniqueMonth.forEach(x => {
        ca8debug(`${TAG} getChart chartMode ${chartMode} uniqueMonth`, x, uniqueMonth, chartData);
        let res = {};          
        res['id'] = idx;
        res['name'] = getMonthName(yr, x);
        let val = chartData.filter(y => ((+y.smonth === x + 1))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      ca8debug(`${TAG} getChart chartMode ${chartMode} result`, result, uniqueMonth, chartData);
      return getChartItem(result);

    } 
  }    

  const getChartItem = (data) => {
    return <BarChart
      isAnimationActive={false}
      layout="vertical"
      onClick={OnChartClick}
      width={550}
      data={data}
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="2 2" vertical={false} />

      <XAxis type="number" />
      <YAxis type="category" orientation="right" tickSize={40} tickLine={{ stroke: 'lightgray', strokeDasharray: "2 2", strokeWidth: "1" }} 
        tick={{ fontSize: 14, width: 250, dy: 0 }} 
        tickFormatter = {(item) => formatExpertYear(item, props.allData.expertMode)} 
        width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>

      <Bar barSize={20} isAnimationActive={false}  dataKey='value' fill="#7B778C" >
        <LabelList dataKey="value" position="right" fill="#000000"  />
      </Bar>     
    
      {getThresholdReferences(data, true, false, props.minimized)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />

    </BarChart>    
  }

  const CustomLegend = () => {
    ca8debug(`${TAG} CustomLegend yr`,chartMode); 
    if (chartMode == 'year') {
        return FiltersLegend(allFilters, resetFilters, null);
    } else if (chartMode == 'month') {
      ca8debug(`${TAG} CustomLegend yr`, allFilters, allFilters.year);
      if (allFilters && allFilters.year && allFilters.year.length > 0) {
        let yr = allFilters.year[0];
        let yr1 = formatExpertYear(yr, props.allData.expertMode);
        ca8debug(`${TAG} CustomLegend yr`, yr, yr1);
        return FiltersLegend({ year: [formatExpertYear(yr, props.allData.expertMode)] }, resetFilters, OnDrillUp);
      } else {
        return FiltersLegend(allFilters, resetFilters, OnDrillUp);
      }
    }
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const onShowData = () => {

    const dt = calculateTableData();

    setChartTableMode('time');
    setChartTableTitle('Year / Month');
    setChartTableFilter(allFilters);
    setChartTableData(dt);
    setChartTableColumns(columns);
    setDrawerVisible(true);

  }

  const onDrawerClose = () => {
    setChartTableData([]);
    setDrawerVisible(false);
    props.selectChart('');
  } 

  return (
    <div id="divTimeChart" className="chart3-container" style={{ backgroundColor: props.selected ? '#defbee' : 'transparent'}}>
      <table>
        <tr>

      {drawerVisible
      ? ''
      : <td className="chart3-col-left">
        <div className="chart3-col-title">Year / Month</div>
        <div>
          <div className="chart3-col-title-row">
          
            <div>
                <Menu mode="vertical" style={{width: 200, border: 'none' }}>
                  <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
                    <Menu.Item disabled={loading || props.loading} icon={<FilePdfOutlined />} onClick={() => {
                      exportToPdf(exportRef.current, "test");
                    }} key="1">PDF</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
                  </SubMenu>
                  <Menu.Item 
                    disabled={loading || props.loading}
                    icon={<TableOutlined />} 
                    onClick={() => onShowData()}>
                    Show data
                  </Menu.Item>
                </Menu>
              </div>
          
          </div>
        </div>
      </td>
      }

    <td className="chart3-col-right">
      <Spin tip={loadingMessage} spinning={loading}>

      {loading
        ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>

        : data && data.length > 0
          ? <div ref={exportRef} style={{ width: drawerVisible ? 'calc(100vh - 200px)' : '100%' }}>
              <CustomLegend/>
              <ResponsiveContainer width='100%' height={400}>
              {getChart()}
              </ResponsiveContainer>
            </div>
          : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
      }
    </Spin>
    </td>
      </tr>
      </table>

      <ChartDataDrawer 
            visible={drawerVisible} 
            onClose={onDrawerClose} 
            data={chartTableData} 
            chartData={chartTableData} 
            mode={chartTableMode} 
            title={chartTableTitle} 
            filter={chartTableFilter} 
            columns={chartTableColumns}
            />
    </div>
  );
}



export default TimeChart3;