import React from "react";
//import { Layout, Menu, Breadcrumb, Button } from 'antd';
  
//const { Header, Content, Footer } = Layout;
//const { SubMenu } = Menu;


const AppLogo = (props) => {
  const TAG = 'AppLogo';

  return (
    <div className="login-header-image" onClick={() => props.onClick()}>
        <div className="login-header-version">v. 8.0</div>
    </div>
  );
};

export default AppLogo;
