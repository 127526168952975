import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Space, Radio, Spin, Table, Button, Divider, Drawer, Input } from 'antd';
import {
    DesktopOutlined,
    SettingOutlined,
    CloudOutlined,
    AlertOutlined,
    TeamOutlined,
    UserOutlined,
    FileOutlined,
    LogoutOutlined,
    PropertySafetyFilled,
  } from '@ant-design/icons';
import ErrorList from "antd/lib/form/ErrorList";

const { TextArea } = Input;

const PopulationDrawer = (props) => {
  const TAG = 'PopulationDrawer';

  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(false);

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  React.useEffect(() => {
    //console.log(`${TAG} useEffect`, props);

    loadData();

  }, [props.client, props.site]);

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/site/${props.site.id}/population`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setData([]);
        setLoading(false);
      });

  }  

  const onClose = () => {
    props.onClose();
    setData([]);
}

const columns = [
    { title: 'Year', dataIndex: 'population_year', key: 'population_year' },
    { title: 'Population', dataIndex: 'population', key: 'population' },
  ]

  return (
    <Drawer title={`Population for site: ${props.site.name}`} placement="right" onClose={props.onClose} visible={props.visible}>
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading} delay={500}>
        <Table size='small' columns={columns} dataSource={data} />
      </Spin>
      </div>
    </div>   
  </Drawer>
  );

};

export default PopulationDrawer;
