import React, { useRef, useState, useEffect } from 'react'
import { Collapse, Button, Slider } from 'antd';
import { getFillForCategory, sortCrimeCategories } from './Utils';

import { SettingOutlined,
    BgColorsOutlined,
    UpOutlined,
    QuestionOutlined,
    GlobalOutlined,
    AlertOutlined, 
    PropertySafetyFilled} from '@ant-design/icons';

const { Panel } = Collapse;
const quickAndDirtyStyle = {
  width: 400,
  //height: "200px",

  /* display: "flex", */
  //justifyContent: "center",
  //alignItems: "center",
  //position: 'sticky',
  padding: 10,
  //borderBottom: '1px solid white',
  zIndex: 10000
}

const LegendComponent = (props) => {
  const [pressed, setPressed] = useState(false)
  const [position, setPosition] = useState({x: 0, y: 0})
  const [collapsed, setCollapsed] = useState(true)
  const [opacity, setOpacity] = useState(100)
  const ref = useRef()

  const TAG = 'LegendComponent';

  // Monitor changes to position state and update DOM
  useEffect(() => {
    if (ref.current) {
      ref.current.style.transform = `translate(${position.x}px, ${position.y}px)`
    }
  }, [position])

  // Update the current position if mouse is down
  const onMouseMove = (event) => {
    if (pressed) {
      setPosition({
        x: position.x + event.movementX,
        y: position.y + event.movementY
      })
    }
  }

  const onMouseLeave = (event) => {
    setPressed(false);
  }  

  const onOpacityChange = (e) => {
    setOpacity(e);
  }

  const getLegend = () => {
      //console.log(`${TAG} props.legend`, props.legend)
      const dt = props.legend && props.legend.length > 0 ? sortCrimeCategories(props.legend) : [];
      return props.legend && props.legend.length > 0 
      ? <table>
          {dt.map((x, idx) => {
              return <tr key={`legend_row_${idx}`}>
                  <td style={{ width: 170 }}>{x}</td>
                  <td style={{ width: 30, backgroundColor: getFillForCategory(x)}}></td>
              </tr>
          })}
      </table>
      : ''
  }

  return (
    <div
      ref={ ref }
      className="noselect"
      style={{
        width: 240,
        position: 'fixed',
        bottom: 50,
        left: 15,
        padding: 1,
        zIndex: 10000,
        opacity: opacity / 100,
        background: "#FF9900", 
        color: "#FFFFFF", 
   }}
      >
        <div 
            style={{ 
            padding: 10, 
            height: 50,  
            background: "#FF9900", 
            color: "#FFFFFF", 
            cursor: pressed ? 'grabbing' : 'grab'
            }}
            onMouseLeave={ onMouseLeave }
            onMouseMove={ onMouseMove }
            onMouseDown={ () => setPressed(true) }
            onMouseUp={ () => setPressed(false) }  
        >
            <BgColorsOutlined style={{ padding: 5 }}  />Legend
        </div>
        {props.legend && props.legend.length > 0 
        ? <div style={{ backgroundColor: 'white', color: 'black', padding: 10}} >
                {getLegend()}
{/*                
                <div style={{ marginTop: 10 }}>
                    <span style={{width: 70, display: 'inline-block', verticalAlign: 'top'}}>Opacity:</span>
                    <span style={{width: 100, display: 'inline-block'}}>
                        <Slider width={100} min={50} max={100} onChange={onOpacityChange} value={opacity} />
                    </span>
                    <span style={{width: 30, display: 'inline-block', verticalAlign: 'top', marginLeft: 10}}>{opacity}</span>    
                </div>
*/}                
        </div> 
        : ''}
        
    </div>
  )
}

export default LegendComponent;