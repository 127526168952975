import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Tag, Menu, Spin, Table, Button, Space, Drawer, Divider,Upload } from 'antd';
import { AppContext } from "../../contexts/AppContext";
import PopulationDrawer from '../drawers/PopulationDrawer';
import ImportsDrawer from '../drawers/ImportsDrawer';

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  FileExcelOutlined,
  CloudUploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;  

const SettingsSites = (props) => {
  const TAG = 'SettingsSites';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [drawerPopulationVisible, setDrawerPopulationVisible] = React.useState(false);
  const [drawerIncidentsVisible, setDrawerIncidentsVisible] = React.useState(false);
  const [currentSite, setCurrentSite] = React.useState({});

  React.useEffect(() => {
    loadData();
  }, [props.client]);

  const onShowPopulation = (site) => {
    setCurrentSite(site);
    setDrawerPopulationVisible(true);
  }

  const onHidePopulation = () => {
    setCurrentSite({});
    setDrawerPopulationVisible(false);
  };

  const onShowIncidents = (site) => {
    setCurrentSite(site);
    setDrawerIncidentsVisible(true);
  }

  const onHideIncidents = () => {
    setCurrentSite({});
    setDrawerIncidentsVisible(false);
  };  

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/site`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        if (response.data) {
          setData(response.data.sites);
          setYears(response.data.years);
        } else {
          setData([]);
          setYears([]);
        }
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setData([]);
        setLoading(false);
      });

  }
  
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Group 1', dataIndex: 'state_name', key: 'state_name' },
    { title: 'Group 2', dataIndex: 'site_city', key: 'site_city' },
    {
      title: 'Absent population data',
      key: 'population_years',
      dataIndex: 'population_years',
      width: 100,
      render: population_years => {
        let zero_population = years.map(x => x.cyear).slice();
        if (population_years && population_years.length > 0) {
          const arr = population_years.split(',').map(x => +x);
          zero_population = zero_population.filter(x => !arr.includes(x));
        }
        return <span>
          {zero_population && zero_population.length > 0
            ? <Tag color={'volcano'} key={zero_population}>{zero_population.join(',')}</Tag>
            : ''
          }
        </span>
      },
    },     
    {
        title: 'Action',
        key: 'action',
        width: 200,
        render: (text, record) => (
          <Space size="middle">
            <a onClick={() => onShowPopulation(record)}>Population</a>
            <a onClick={() => onShowIncidents(record)}>History</a>
          </Space>
        ),
      },    
  
  ]

  return (
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading} delay={0}>
        <Table size='small' columns={columns} dataSource={data} title={() => 'Sites list'} />
      </Spin>
      </div>
      <PopulationDrawer visible={drawerPopulationVisible} onClose={onHidePopulation} client={props.client} site={currentSite}/>
      <ImportsDrawer visible={drawerIncidentsVisible} onClose={onHideIncidents} client={props.client} site={currentSite}/>
  </div>    
  );
}


export default SettingsSites;
