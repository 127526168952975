import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend,setTableVisibility,getThresholdReferences,ca8debug } from './Utils';

import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, message, Checkbox, Menu } from 'antd';
import { AppContext } from "../contexts/AppContext";


import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined
} from '@ant-design/icons';
import ChartDataDrawer from './drawers/ChartDataDrawer';

const { Option } = Select;
const { SubMenu } = Menu;


const DayChart = (props) => {
    const TAG = 'DayChart';

    const exportRef = useRef();

    ca8debug(`${TAG} props`, props);

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  

    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('year');
    const [dayFilter, setDayFilter] = React.useState([]);
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(false);

    const [incidentData, setIncidentData] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');

    const [chartTableData, setChartTableData] = React.useState([]);
    const [chartTableColumns, setChartTableColumns] = React.useState([]);
    const [chartTableTitle, setChartTableTitle] = React.useState('');
    const [chartTableFilter, setChartTableFilter] = React.useState(undefined);
    const [chartTableMode, setChartTableMode] = React.useState('');    
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    const columns = [
      {
        title: 'Day',
        dataIndex: 'weekday',
        key: 'weekday',
        sorter: true,
        filters: dayFilter,
        filteredValue: allFilters.weekday || null,
        onFilter: (value, record) => {
          return record.weekday.includes(value);
        },      
      },
      {
        title: 'Crime Group',
        dataIndex: 'category',
        key: 'category',
        sorter: true,
        filters: categoryFilter,
        filteredValue: allFilters.category || null,
        onFilter: (value, record) => {
          return record.category.includes(value);
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
      },
    ];
  
    ca8debug(`${TAG} started`);
  
    const tableRef = React.useRef();
  
    React.useEffect(() => {
  
      const filter = props.filter;

      ca8debug(`${TAG} useEffect started`,props);
      
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData && filter) {
        setLoading(true);
        setLoadingMessage('Loading ...');
        setDrawerVisible(false);

      apiClient.post(`api/clients/${clientId}/chart/weekday`, filter)
      .then(response => {
        ca8debug(`${TAG} data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'year': x['year'], 
                    'month': x['month'], 
                    'weekday': x['weekday'], 
                    'timeperiod': x['timeperiod'], 
                    'quarter': x['quarter'], 
                    'category': x['category'], 
                    'crime': x['crime'], 
                    'value': x['value'], 
                    'smonth': x['smonth'], 
                    'sday': x['sday'], 
                    'shour': x['shour']  
                }
              });            
              ca8debug('dt', dt);

              //updateFilters(dt);

              setData(dt);
              // set initial chart data
              //updateTableData(dt, allFilters, allSorters);

              updateFilters(dt);
              setChartData(dt);              
  
              setLoading(false);

        });
      }
        ca8debug(`${TAG} useEffect finished`,props);
    }, [props.filter]);

  const updateFilters = (dt) => {
    // read year filter
    const uniqueDay = [...new Set(dt.map(x => x.weekday))];
    setDayFilter(uniqueDay.map(x => { return {'text': x, 'value': x}}));
    // read category filter
    const uniqueCategory = [...new Set(dt.map(x => x.category))];
    ca8debug('uniqueCategory', uniqueCategory);
    setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));            
    // read crime filter
  }

  const exportToPdf = async (el, imageFileName) => {

      ca8debug(`${TAG} exportToPdf data`, data, allFilters);

      setLoadingMessage('Export to PDF ...');
      setLoading(true);
  
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      ca8debug(`${TAG} exportToPdf image`, image);
  
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
      const dt = calculateTableData();
  
      var formData = new FormData();
      formData.append("chart", image);
      formData.append("title", 'Day of Week');
      formData.append("filter", JSON.stringify(props.filter));
      formData.append("items", JSON.stringify(dt));
      formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    
      apiClient.post(`api/clients/${clientId}/report`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }, responseType: 'blob', timeout: 5000000
      })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        }).catch((error) => {
  
          setLoading(false);
          message.error(error);
  
        });       
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();

    const formData = new FormData();

    formData.append("chart", image);
    formData.append("title", 'Day of Week');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));

    apiClient.post(`api/clients/${clientId}/export`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);
        setLoading(false);

      });  
  };

  const exportToPrint = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPrint data`, data, allFilters);
  
    setLoadingMessage('Prepare to print ...');
    setLoading(true);

    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPrint image`, image);
  
    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();
  
    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Sites / Site groups');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
  
      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);

    }).catch((error) => {
        setLoading(false);
        message.error(error);
    });    
  };

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  

  const onTableChange = (pagination, filters, sorter) => {
    ca8debug(`${TAG} onTableChange filters`, filters);
    let newData = data.slice();
    if (filters.weekday) {
      newData = newData.filter(x => filters.weekday.includes(x.weekday));
    }
    if (filters.category) {
      newData = newData.filter(x => filters.category.includes(x.category));
    }
    if (filters.crime) {
      newData = newData.filter(x => filters.crime.includes(x.crime));
    }      

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }

    updateFilters(newData);
    setAllFilters(filters);
    setChartData(newData);
  }

  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData);
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('year');
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
/*    
    if (e.activePayload && e.activePayload.length == 1) {
      const payloadData = e.activePayload[0];
      if (payloadData && payloadData.payload) {
        const newChartMode = chartMode == 'year' ? 'quarter' : 'month';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (chartMode == 'year') {
          newAllFilters['year'] = [payloadData.payload.name];
        } else if (chartMode == 'quarter') {
          newAllFilters['quarter'] = [payloadData.payload.name];
        } 
        //else if (chartMode == 'month') {
        //    newAllFilters['month'] = [payloadData.payload.name];
        //}

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
      }
    }
*/    
  }

  const getWeekDayName = (day) => {
    
    const date = new Date(Date.UTC(1999, 10, 1)); // monday
    date.setDate(date.getDate() + day);
    const result = date.toLocaleString('default', { weekday: 'long' });
    ca8debug(`${TAG} getWeekDayName `, result, day, date);
    return result;
  }

  const getUniqueWeekday = () => {

    const names = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const days = props.filter.dayOfWeek && props.filter.dayOfWeek.length > 0 
      ? props.filter.dayOfWeek.split(',').map((x) => +x)
      : [0,1,2,3,4,5,6];

    const result = [];
    days.forEach(x => {
      result.push(names[x]);
    });

    ca8debug(`${TAG} getUniqueWeekday`, result);
    return result; 
  }  

  const calculateTableData = () => {
    const td = {};
    td.items = [];
    let idx = 0;
    let incidx = 0;

      const uniqueDay = getUniqueWeekday();


      td.columns = [
        { title: 'Day of Week',dataIndex: 'name',key: 'name' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];
      uniqueDay.forEach(x => {
        idx += 1;
        const tr1 = {};
        tr1.id = idx;
        tr1.name = x;
        tr1.value = chartData.filter(y => ((y.weekday === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        tr1.items = props.data.filter(y => ((y.weekday == x)));

        td.items.push(tr1);
      });


    ca8debug(`${TAG} calculateTableData ${idx} / ${incidx}`,chartData, td, props.data, allFilters);
    return td;
  }

  const getChart = () => {
    
    // get unique values
    const uniqueDay = getUniqueWeekday();

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueDay.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.weekday === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });

      ca8debug(`${TAG} getChart chartMode ${chartMode}`, chartData, result);
      return getChartItem(result);

  }    

  const getChartItem = (data) => {
    return <BarChart
    isAnimationActive={false}
      onClick={OnChartClick}
      width={550}
      height={300}
      data={data}
      layout="horizontal"
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="2 2" vertical={false} />

      <XAxis type="category" orientation="bottom" tickSize={15} tickLine={{ stroke: 'lightgray', strokeDasharray: "2 2", strokeWidth: "1" }} tick={{ fontSize: 14, width: 250, dy: -10 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>
      <YAxis allowDecimals={false} type="number" orientation="right" tickSize={40} tickLine={{ stroke: 'lightgray', strokeDasharray: "2 2", strokeWidth: "1" }} tick={{ fontSize: 14, width: 250, dy: 0 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10}/>


      <Bar barSize={20} isAnimationActive={false}  dataKey='value' fill="#7B778C" >
        <LabelList dataKey="value" position="top" fill="#000000" />
      </Bar>     
    
      {getThresholdReferences(data, false, false)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />

    </BarChart>    
  }

  const expandable = { expandedRowRender: record => <p>{record.description}</p> };

  const showDetails = (record) => {

    const filteredData = props.data.filter(x => 
        x.weekday == record.weekday && 
        x.crime_category == record.category 
        );

        ca8debug(`${TAG} showDetails`, record, filteredData)
    
    const cols = [
      { title: 'id',dataIndex: 'incident_id',key: 'incident_id' },
      { title: 'Site',dataIndex: 'site_name',key: 'site_name' },
      { title: 'Crime Type',dataIndex: 'crime_type',key: 'crime_type' },
      { title: 'Date',dataIndex: 'incident_date',key: 'incident_date' },
      { title: 'Time',dataIndex: 'incident_time',key: 'incident_time' },
      { title: 'Location',dataIndex: 'incident_location',key: 'incident_location' },
      { title: 'Victim',dataIndex: 'incident_victim',key: 'incident_victim' },
      { title: 'Comments',dataIndex: 'incident_comments',key: 'incident_comments' },
      { title: 'Offense Report',dataIndex: 'incident_offence_report_no',key: 'incident_offence_report_no' },
  ];

    return <Table 
        size="small"
        key={`table_${record.weekday}_${record.category}`}
        columns={cols} 
        dataSource={filteredData}
        />
  }

  const CustomLegend = () => {
    return FiltersLegend(allFilters, resetFilters);
  }

  const onShowData = () => {

    const dt = calculateTableData();

    setChartTableMode('weekday');
    setChartTableTitle('Day of Week');
    setChartTableFilter(allFilters);
    setChartTableData(dt);
    setChartTableColumns(columns);
    setDrawerVisible(true);

  }

  const onDrawerClose = () => {
    setChartTableData([]);
    setDrawerVisible(false);
    props.selectChart('');
  }   

  return (
    <div id="divDayChart" className="chart3-container" style={{ backgroundColor: props.selected ? '#defbee' : 'transparent'}}>
      <table>
        <tr>

          {drawerVisible
          ? ''
          : <td className="chart3-col-left">
            <div className="chart3-col-title">Day of Week</div>
            <div>
              <div className="chart3-col-title-row">
              
                <div>
                    <Menu mode="vertical" style={{width: 200, border: 'none' }}>
                      <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
                        <Menu.Item disabled={loading || props.loading} icon={<FilePdfOutlined />} onClick={() => {
                          exportToPdf(exportRef.current, "test");
                        }} key="1">PDF</Menu.Item>
                        <Menu.Item disabled={loading || props.loading} icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
                        <Menu.Item disabled={loading || props.loading} icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
                      </SubMenu>
                      <Menu.Item 
                        disabled={loading || props.loading}
                        icon={<TableOutlined />} 
                        onClick={() => onShowData()}>
                        Show data
                      </Menu.Item>
                    </Menu>
                  </div>
              
              </div>
            </div>
          </td>
          }

          <td className="chart3-col-right">
            <Spin tip={loadingMessage} spinning={loading}>

            {loading
              ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>

              : data && data.length > 0
                ? <div ref={exportRef} style={{ width: drawerVisible ? 'calc(100vh - 200px)' : '100%' }}>
                    <CustomLegend/>
                    <ResponsiveContainer width='100%' height={400}>
                    {getChart()}
                    </ResponsiveContainer>
                  </div>
                : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
            }
            </Spin>
          </td>
        </tr>
      </table>

      <ChartDataDrawer 
        visible={drawerVisible} 
        onClose={onDrawerClose} 
        data={chartTableData} 
        chartData={chartTableData} 
        mode={chartTableMode} 
        title={chartTableTitle} 
        filter={chartTableFilter} 
        columns={chartTableColumns}
        />
    </div>
  );
}

export default DayChart;