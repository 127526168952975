import './App.css';
import React, { useEffect } from "react";

import { AppProvider } from "./contexts/AppContext";
import AuthContainer from "./components/AuthContainer";
import { ca8debug } from './components/Utils';

function App(props) {
  
  const TAG = 'App';

  React.useEffect(() => {
    ca8debug(`${TAG} useEffect started`);
    window.onbeforeunload = function() { 
      ca8debug(`${TAG} useEffect onbeforeunload`);
      return "Your work will be lost."; 
    };    
  }, []);   

  return (
    <AppProvider>
      <AuthContainer />
    </AppProvider>
  );
}

export default App;
