import React, { useContext, useCallback, useState, useRef } from 'react'
import ReactDOMServer from 'react-dom/server'
import { sortAsc, sortDesc, FiltersLegend,getFillForCategory,getThresholdReferences,ca8debug,sortCrimeCategories } from './Utils';
import { useReactToPrint } from 'react-to-print';

import { ResponsiveContainer, BarChart, Bar, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, Checkbox, Menu, message, Switch, Space  } from 'antd';
import { AppContext } from "../contexts/AppContext";
import html2canvas from "html2canvas";

import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined,
  UserOutlined,
  FileOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import MaximizeMinimizeButton from './buttons/MaximizeMinimizeButton';
import SiteChartDrawer from './drawers/SiteChartDrawer';


const { Option } = Select;
const { SubMenu } = Menu;

const SitesChart3 = (props) => {
    const TAG = 'SitesChart3';

    const exportRef = useRef();
    const printRef = useRef();

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  
  
    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('group1');
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});
  
    const [loading, setLoading] = React.useState(true);
    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');

    const [useCrimeGroups, setUseCrimeGroups] = React.useState(false);
    
    const [cellCount, setCellCount] = React.useState(0);

    // crime total or crime rate
    const [showCrimeTotal, setShowCrimeTotal] = React.useState(true);

    const [chartTableData, setChartTableData] = React.useState([]);
    const [chartTableColumns, setChartTableColumns] = React.useState([]);
    const [chartTableTitle, setChartTableTitle] = React.useState('');
    const [chartTableFilter, setChartTableFilter] = React.useState(undefined);
    const [chartTableMode, setChartTableMode] = React.useState('');
    const [chartTableExtra, setChartTableExtra] = React.useState({});

    const [drawerVisible, setDrawerVisible] = React.useState(false);
  
    const [calculatingTableData, setCalculatingTableData] = React.useState(false);

    const [printSrc, setPrintSrc] = React.useState(false);

    const printFrame = undefined;

    React.useEffect(() => {
      const filter = props.filter;

      ca8debug(`${TAG} useEffect started`);

      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData && filter) {
        setLoading(true);
        setLoadingMessage('Loading ...');
        setDrawerVisible(false);
  
        apiClient.post(`api/clients/${clientId}/chart/sites`, filter)
        .then(response => {
          ca8debug(`${TAG} data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                  'id': idx + 1, 
                  'site': x.site, 
                  'address': x.address, 
                  'crime': x.crime, 
                  'value': +x.value, 
                  'category': x.category, 
                  'group1': x.group1, 
                  'group2': x.group2,  
                  'population': +x.population,  
                  //'address': x.site_address
                }
              }).sort(function (a, b) {
                return a.group1.localeCompare(b.group1) || a.group2.localeCompare(b.group2) || a.site.localeCompare(b.site);
              });            
              ca8debug('dt', dt);

              setData(dt);
              // set initial chart data
              updateTableData(dt, allFilters, allSorters, chartMode ?? 'group1');
  
              setLoading(false);
        });
      } 
    }, [props.filter]);

  const updateFilters = (dt, filters, mode) => {
    // read group1 filter
    const uniqueGroup1 = getUniqueGroup1(); // [...new Set(dt.map(x => x.group1))];
    ca8debug('uniqueGroup1', uniqueGroup1);
    //setGroup1Filter(uniqueGroup1.map(x => { return {'text': x, 'value': x}}));
    // read group2 filter
    const uniqueGroup2 = getUniqueGroup2(filters); // [...new Set(dt.map(x => x.group2))];
    ca8debug('uniqueGroup2', uniqueGroup2);
    //setGroup2Filter(uniqueGroup2.map(x => { return {'text': x, 'value': x}}));
    // read site filter
    const uniqueSite = getUniqueSite(filters); // [...new Set(dt.map(x => x.site))];
    ca8debug('uniqueSite', uniqueSite);
    //setSiteFilter(uniqueSite.map(x => { return {'text': x, 'value': x}}));

    // calculate cell count
    let cnt = uniqueGroup1 ? uniqueGroup1.length : 0;
    if (mode == 'group2') {
        cnt = uniqueGroup2 ? uniqueGroup2.length : 0;
    } else if (mode == 'sites') {
        cnt = uniqueSite ? uniqueSite.length : 0;
    }
    ca8debug(`${TAG} updateFilters cnt`,mode, cnt)
    ca8debug(`${TAG} updateFilters unique`,uniqueGroup1, uniqueGroup2,uniqueSite)
    setCellCount(cnt);

    // read category filter
    if (useCrimeGroups) {
      const uniqueCategory = [...new Set(dt.map(x => x.category))];
      ca8debug('uniqueCategory', uniqueCategory);
      //setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));            

      // read crime filter
      const uniqueCrime = [...new Set(dt.map(x => x.crime))];
      ca8debug('uniqueCrime', uniqueCrime);
      //setCrimeFilter(uniqueCrime.map(x => { return {'text': x, 'value': x}}));            
    }
  }

  const onTableChange = (pagination, filters, sorter, mode) => {
    ca8debug(`${TAG} onTableChange filters`, filters);
    
    setAllFilters(filters);
    setAllSorters(sorter);

    updateTableData(data, filters, sorter, mode ? mode : chartMode);

  }

const exportToPdf = async (el, imageFileName) => {

  ca8debug(`${TAG} exportToPdf data`, data, allFilters);

  setLoadingMessage('Export to PDF ...');
  setLoading(true);

  const canvas = await html2canvas(el);
  const image = canvas.toDataURL("image/png", 1.0);
  ca8debug(`${TAG} exportToPdf image`, image);

  const clientId = appCurrentClient.id;
//  const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
  const dt = calculateTableData(useCrimeGroups);

  var formData = new FormData();
  formData.append("chart", image);
  formData.append("title", 'Sites / Site groups');
  formData.append("filter", JSON.stringify(props.filter));
  formData.append("items", JSON.stringify(dt));
  formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));

  apiClient.post(`api/clients/${clientId}/report`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }, responseType: 'blob', timeout: 5000000
  })
  .then((response) => {
    setLoading(false);

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'file.pdf'); 
    document.body.appendChild(link);
    link.click();
    }).catch((error) => {

      setLoading(false);
      message.error(error);
   

    });    
};

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData(useCrimeGroups);

    const formData = new FormData();

    formData.append("chart", image);
    formData.append("title", 'Sites / Site groups');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/export`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); 
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const exportToPrint = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPrint data`, data, allFilters);
  
    setLoadingMessage('Prepare to print ...');
    setLoading(true);

    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPrint image`, image);
  
    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData(useCrimeGroups);
  
    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Sites / Site groups');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
  
      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);

    }).catch((error) => {
        setLoading(false);
        message.error(error);
    });    
  };

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  

  const updateTableData = (dd, filters, sorter, mode) => {
    let newData = dd.slice();

    if (filters.group1) {
      newData = newData.filter(x => filters.group1.includes(x.group1));
    }
    if (filters.group2) {
      newData = newData.filter(x => filters.group2.includes(x.group2));
    }
    if (filters.site) {
      newData = newData.filter(x => filters.site.includes(x.site));
    }
    if (useCrimeGroups) {
      if (filters.category) {
        newData = newData.filter(x => filters.category.includes(x.category));
      }
      if (filters.crime) {
        newData = newData.filter(x => filters.crime.includes(x.crime));
      }     
    }

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }
    updateFilters(newData, filters, mode);
    setChartData(newData);

  }
  
  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData, {}, 'group1');
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('group1');
  }

  const calculatePopulationForSite = (isUseCrimeGroups, value, group1, group2, site, category) => {
    const dt = isUseCrimeGroups 
    ? chartData.filter(x => ((x.group1 == group1 && x.group2 == group2 && x.site == site && x.category == category)))
    : chartData.filter(x => ((x.group1 == group1 && x.group2 == group2 && x.site == site)));
    ca8debug(`${TAG} calculatePopulationForSite dt`,dt, value, group1, group2, site, category);
    if (dt && dt.length > 0) {
      const population = dt.map(x => calculateWithPopulation(x)).reduce((a, b) => a + b, 0);

      ca8debug(`${TAG} calculatePopulationForSite population`,dt, population);
      return showCrimeTotal ? value : population > 0 ? population : 0;
    } else {
      return 0;
    }
  }

  const calculateTableData = (isUseCrimeGroups) => {

    const td = {};
    td.items = [];
    let idx = 0;
    let incidx = 0;

    setCalculatingTableData(true);
    
    if (isUseCrimeGroups) {
      td.columns = [
        { title: 'Group 1',dataIndex: 'name',key: 'name' },
        { title: 'Crime Group', dataIndex: 'category' , key: 'category' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];
      if (!showCrimeTotal) {
        td.columns.push({ title: 'Rate', key: 'rate', render: (text, record) => (record.rate.toFixed(1)) });
      }          
      const uniqueGroup1 = [...new Set(chartData.map(x => x.group1))];
      const uniqueCategory = [...new Set(props.data.map(x => x.crime_category))];
      ca8debug(`${TAG} calculateTableData uniqueCategory`,uniqueCategory);
      //console.time('uniqueGroup1 forEach');
      //const tr1dt = [];
      uniqueGroup1.forEach(g1 => {
        uniqueCategory.forEach(ca => {
          idx += 1;
          const tr1 = {};
          tr1.id = idx;
          tr1.columns = [
            { title: 'Group 2',dataIndex: 'name',key: 'name' },
            { title: 'Crime Group', dataIndex: 'category' , key: 'category' },
            { title: 'Value', dataIndex: 'value', key: 'value' }
          ];
          if (!showCrimeTotal) {
            tr1.columns.push({ title: 'Rate', key: 'rate', render: (text, record) => (record.rate.toFixed(1)) });
          }          
          tr1.name = g1;
          tr1.value = 0;
          tr1.rate = 0;
          tr1.category = ca;
          tr1.items = [];
          td.items.push(tr1);
        })
      });

      td.items.forEach(tr1 => {

        const uniqueGroup2 = [...new Set(chartData.filter(x => x.group1 == tr1.name && x.category == tr1.category).map(x => x.group2))];
        uniqueGroup2.forEach(g2 => {
          idx += 1;
          const tr2 = {};
          tr2.id = idx;
          tr2.columns = [
            { title: 'Site',dataIndex: 'name',key: 'name' },
            { title: 'Crime Group', dataIndex: 'category' , key: 'category' },
            { title: 'Value', dataIndex: 'value', key: 'value' }
          ];
          if (!showCrimeTotal) {
            tr2.columns.push({ title: 'Rate', key: 'rate', dataIndex: 'rate' });
          }          
          tr2.name = g2;
          tr2.category = tr1.category;
          tr2.parent = tr1.name;
          tr2.value = 0;
          tr2.rate = 0;
          tr2.items = [];
          //console.time('uniqueSite forEach');
          const uniqueSite = [...new Set(chartData.filter(x => x.group1 == tr1.name && x.category == tr1.category && x.group2 == g2).map(x => x.site))];
          uniqueSite.forEach(st => {
            idx += 1;
            const tr3 = {};
            tr3.id = idx;
            tr3.name = st;
            tr3.parent = tr2.name;
            tr3.category = tr1.category;
            tr3.value = 0;
            tr3.rate = 0;
            tr3.items = [];
            const dt = props.data.filter(x => ((x.state_name == tr1.name && x.site_city == g2 && x.site_name == st && x.crime_category == tr1.category)));
            const rt = calculatePopulationForSite(isUseCrimeGroups, dt.length, tr1.name, g2, st, tr1.category); // dt.length;
            const cnt = dt.length; // dt.length;
            tr3.items = dt;
            tr3.value = cnt;
            tr2.value += cnt;
            tr1.value += cnt;
            tr3.rate = showCrimeTotal ? rt : rt.toFixed(2);
            tr2.rate += rt;
            tr1.rate += rt;
            incidx += cnt;
            tr2.items.push(tr3);
          });
          tr1.items.push(tr2);
      });

          //console.timeEnd('uniqueSite forEach');
          //ca8debug(`${TAG} calculateTableData tr2`,tr2);
      });
    } else {
      td.columns = [
        { title: 'Group 1',dataIndex: 'name',key: 'name' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];
      if (!showCrimeTotal) {
        td.columns.push({ title: 'Rate', key: 'rate', render: (text, record) => (record.rate.toFixed(1)) });
      }          
      const uniqueGroup1 = [...new Set(chartData.map(x => x.group1))];
      //console.time('uniqueGroup1 forEach');
      uniqueGroup1.forEach(g1 => {
        idx += 1;
        const tr1 = {};
        tr1.id = idx;
        tr1.columns = [
          { title: 'Group 2',dataIndex: 'name',key: 'name' },
          { title: 'Value', dataIndex: 'value', key: 'value' }
        ];
        if (!showCrimeTotal) {
          tr1.columns.push({ title: 'Rate', key: 'rate', render: (text, record) => (record.rate.toFixed(1)) });
        }          
        tr1.name = g1;
        tr1.value = 0;
        tr1.rate = 0;
        tr1.items = [];
        const uniqueGroup2 = [...new Set(chartData.filter(x => x.group1 == g1).map(x => x.group2))];
        uniqueGroup2.forEach(g2 => {
          idx += 1;
          const tr2 = {};
          tr2.id = idx;
          tr2.columns = [
            { title: 'Site',dataIndex: 'name',key: 'name' },
            { title: 'Value', dataIndex: 'value', key: 'value' }
          ];
          if (!showCrimeTotal) {
            tr2.columns.push({ title: 'Rate', key: 'rate', dataIndex: 'rate' });
          }          
  
          tr2.name = g2;
          tr2.value = 0;
          tr2.rate = 0;
          tr2.items = [];
          //console.time('uniqueSite forEach');
          //const uniqueSite = [...new Set(chartData.filter(x => x.group1 == g1 && x.group2 == g2).map(x => x.site))];
          const uniqueSite = [...new Set(chartData.filter(x => x.group1 == g1 && x.group2 == g2).map(x => x.site))];
          uniqueSite.forEach(st => {
            idx += 1;
            const tr3 = {};
            tr3.id = idx;
            tr3.name = st;
            tr3.value = 0;
            tr2.rate = 0;
            tr3.items = [];
            const dt = props.data.filter(x => ((x.state_name == g1 && x.site_city == g2 && x.site_name == st)));
            const cnt = dt.length;
            const rt = calculatePopulationForSite(isUseCrimeGroups, dt.length, tr1.name, g2, st); // dt.length;
            tr3.items = dt;
            tr3.value = cnt;
            tr2.value += cnt;
            tr1.value += cnt;
            tr3.rate = showCrimeTotal ? rt : rt.toFixed(1);
            tr2.rate += rt;
            tr1.rate += rt;
            incidx += cnt;
            tr2.items.push(tr3);
          });
          //console.timeEnd('uniqueSite forEach');
          //ca8debug(`${TAG} calculateTableData tr2`,tr2);
          tr1.items.push(tr2);
        });
        td.items.push(tr1);
      });
    }
    ca8debug(`${TAG} calculateTableData ${idx} / ${incidx}`,chartData, td, props.data, allFilters);

    setCalculatingTableData(false);

    if (chartMode == 'group1') {
      return td;
    } else if (chartMode == 'group2') {
      ca8debug(`${TAG} calculateTableData group2`,allFilters, allFilters.group1[0], td.items, td.items.filter(x => x.name == allFilters.group1[0]));
      if (isUseCrimeGroups) { 
        let items = [];
        td.items.filter(x => x.name == allFilters.group1[0]).forEach(x => {
          ca8debug(`${TAG} calculateTableData result concat`, x);
          items = items.concat(x.items);
        });
        const columns = td.items[0].columns;
        ca8debug(`${TAG} calculateTableData result`,columns, items);
        return { id: 0, name: '', columns: columns, items: items.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })  };
      } else {
        return td.items[0];
      }
    } else {
      if (isUseCrimeGroups) { 
        let items1 = [];
        let items2 = [];
        td.items.filter(x => x.name == allFilters.group1[0]).forEach(x => {
          ca8debug(`${TAG} calculateTableData result concat 1`, x);
          items1 = items1.concat(x.items);
        });
        items1.filter(x => x.name == allFilters.group2[0]).forEach(x => {
          ca8debug(`${TAG} calculateTableData result concat 2`, x);
          items2 = items2.concat(x.items);
        });
        const columns = items1[0].columns;
        ca8debug(`${TAG} calculateTableData result`,columns, items2);
        return { id: 0, name: '', columns: columns, items: items2.sort(function (a, b) {
          return a.name.localeCompare(b.name);
        })  };
      } else {
        return td.items[0].items[0];
      }
    }
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
    //if (e.activePayload && e.activePayload.length == 1) {
    //  const payloadData = e.activePayload[0];
    //  if (payloadData && payloadData.payload) {
      const name = e.activeLabel;
        const newChartMode = chartMode == 'group1' ? 'group2' : 'sites';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (chartMode == 'group1') {
          newAllFilters['group1'] = [name];
        } else if (chartMode == 'group2') {
          newAllFilters['group2'] = [name];
        }

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null, newChartMode);
    //  }
    //}
  }

  const OnChartClick2 = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
    if (e.activePayload && e.activePayload.length == 1) {
      const payloadData = e.activePayload[0];
      if (payloadData && payloadData.payload) {
        const newChartMode = chartMode == 'group1' ? 'group2' : 'sites';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (chartMode == 'group1') {
          newAllFilters['group1'] = [payloadData.payload.name];
        } else if (chartMode == 'group2') {
          newAllFilters['group2'] = [payloadData.payload.name];
        }

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null, newChartMode);
      }
    }
  }  

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnDrillUp`, allFilters);
    // change chart mode
    if (chartMode != 'group1') {
        const newChartMode = chartMode == 'sites' ? 'group2' : 'group1';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (newChartMode == 'group1') {
          newAllFilters['group1'] = undefined;
          newAllFilters['group2'] = undefined;
        } else if (newChartMode == 'group2') {
          newAllFilters['group2'] = undefined;
          newAllFilters['sites'] = undefined;
        } 

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null, newChartMode);
        
    }
  }    

  const calculateWithPopulation = (x) => {
    return x.population > 0 
    ? ((x.value * 1000) / x.population) // .toFixed(6)
    : 0;
  }

  const getUniqueGroup1 = () => {
    let result = [];
    if (props.filter.group1 && props.filter.group1.length > 0) {
      const arr = props.filter.group1.split(',').map(x => +x);
      if (arr && arr.length > 0) {
        result = props.allData.group1.filter(x => arr.includes(x.value)).map(x => x.label);
      }
    } else {
      result = props.allData.group1.map(x => x.label);
    }
    ca8debug(`${TAG} getUniqueGroup1`, result, props.filter, props.allData.group1);
    return result;
  }

  const getUniqueGroup2 = (filters) => {
    let result = [];
    if (filters.group1) {
      // get parent
      const prnt = props.allData.group1.filter(x => x.label == filters.group1);
      if (prnt && prnt.length > 0) {
        if (props.filter.group2 && props.filter.group2.length > 0) {
          const arr = props.filter.group2.split(',').map(x => +x);
          if (arr && arr.length > 0) {
            result = props.allData.group2.filter(x => x.parent_id == prnt[0].value && arr.includes(x.value)).map(x => x.label)
          }
        } else {
          result = props.allData.group2.filter(x => x.parent_id == prnt[0].value).map(x => x.label);
        }
      }
    } 
    ca8debug(`${TAG} getUniqueGroup2`, result, filters, props.filter.useGroup2, props.filter.group2, props.allData.group2);
    return result;
  }

  const getUniqueSite = (filters) => {
    let result = [];
    if (filters.group1 && filters.group2) {
      // get parent
      const prnt1 = props.allData.group1.filter(x => x.label == filters.group1);
      if (prnt1 && prnt1.length > 0) {
        const prnt2 = props.allData.group2.filter(x => x.parent_id == prnt1[0].value && x.label == filters.group2);

        if (props.filter.site && props.filter.site.length > 0) {
          const arr = props.filter.site.split(',').map(x => +x);
          if (arr && arr.length > 0) {
            result = props.allData.site.filter(x => x.parent_id == prnt2[0].value && arr.includes(x.value)).map(x => x.label) 
          }
        } else {
          result = props.allData.site.filter(x => x.parent_id == prnt2[0].value).map(x => x.label);
        }
      } 
    }
    ca8debug(`${TAG} getUniqueSite`, result, filters, props.filter.useSite, props.filter.site, props.allData.site);
    return result;
  }

  const getChart = (forPrinting) => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, cellCount, showCrimeTotal, chartData);
    // group data by site and crime
    const uniqueGroup1 = getUniqueGroup1(); // [...new Set(chartData.map(x => x.group1))];
    const uniqueGroup2 = getUniqueGroup2(allFilters); // props.filter.useGroup2 ? props.filter.group2 : props.allData.group2; // [...new Set(chartData.map(x => x.group2))];
    const uniqueSite = getUniqueSite(allFilters); // props.filter.useGroup1 ? props.filter.group1 : props.allData.group1; // [...new Set(chartData.map(x => x.site))];

    const uniqueCategory = sortCrimeCategories([...new Set(chartData.map(x => x.category))]);    

    if (chartMode == 'group1') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueGroup1.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let value = 0;
        uniqueCategory.forEach(c => {
          let val = showCrimeTotal 
            ? chartData.filter(y => ((y.group1 === x && y.category == c))).map(z => z.value).reduce((a, b) => +a + +b, 0)
            : chartData.filter(y => ((y.group1 === x && y.category == c))).map(z => calculateWithPopulation(z)).reduce((a, b) => +a + +b, 0);
          res[c] = val; // = showCrimeTotal ? val : val.toFixed(4);
          value += val;
        });
        res['value'] = value;
        result.push(res);
        idx++;
    });
      ca8debug(`${TAG} getChart bbb result chartMode ${chartMode}`, result);
      return getChartItem(forPrinting, result);
 
    } else if (chartMode == 'group2') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueGroup2.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let value = 0;
        uniqueCategory.forEach(c => {
          let val = showCrimeTotal 
            ? chartData.filter(y => ((y.group2 === x && y.category == c))).map(z => z.value).reduce((a, b) => +a + +b, 0)
            : chartData.filter(y => ((y.group2 === x && y.category == c))).map(z => calculateWithPopulation(z)).reduce((a, b) => +a + +b, 0);
          res[c] = val; // = showCrimeTotal ? val : val.toFixed(4);
          value += val;
        });
        res['value'] = value;
        result.push(res);
        idx++;
      });
      ca8debug(`${TAG} getChart bbb result chartMode ${chartMode}`, result);
      return getChartItem(forPrinting, result);

    } else {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueSite.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let value = 0;
        uniqueCategory.forEach(c => {
          let val = showCrimeTotal 
            ? chartData.filter(y => ((y.site === x && y.category == c))).map(z => z.value).reduce((a, b) => +a + +b, 0)
            : chartData.filter(y => ((y.site === x && y.category == c))).map(z => calculateWithPopulation(z)).reduce((a, b) => +a + +b, 0);
          res[c] = val; // = showCrimeTotal ? val : val.toFixed(4);
          value += val;
        });
        res['value'] = value;
        result.push(res);
        idx++;
      });

      ca8debug(`${TAG} getChart bbb result chartMode ${chartMode}`, chartData, result);
      return getChartItem(forPrinting, result);      
    }
        
  }    

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const props = [];
      const pl = payload[0].payload;
      ca8debug(`${TAG} CustomTooltip`, active, payload, label, pl);
      let y = 1;
      for (var prop in pl) {
        if (Object.prototype.hasOwnProperty.call(pl, prop)) {
          if (prop != 'id' && prop != 'value' && prop != 'name') {
            props.push(<p key={`CustomTooltip_intro_${y}`} className="intro" style={{ color: getFillForCategory(prop) }}>{`${prop}: ${pl[prop]}`}</p>)
            y += 1;
          }
        }
      }      
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          {props}
          <p className="intro">{`total: ${pl.value}`}</p>
          {chartMode == 'sites'
           ? <p className="desc">{`address: ${getSiteAddress(label)}`}</p>
           : ''}
        </div>
      );
    }
  
    return null;
  };

  const getSiteAddress = (site) => {
    if (chartData) {
      const res = chartData.filter(x => x.site == site);
      if (res && res.length > 0) {
        return res[0].address;
      }
    }
    return '';
  }

  const getChartItem = (forPrinting, data) => {

    ca8debug(`${TAG} getChartItem chartMode ${chartMode}`,allFilters, data);

    return <BarChart
      onClick={OnChartClick}
      width={550}
      data={data}
      layout="vertical"
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="2 2" vertical={false} />

      <XAxis type="number"/> 
      <YAxis type="category" orientation="right" tickSize={40} tickLine={{ stroke: 'lightgray', strokeDasharray: "2 2", strokeWidth: "1" }} tick={{ fontSize: 14, width: 250, dy: 0 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>

      {getChartBars(data)}
    
      {getThresholdReferences(data, true, !showCrimeTotal, props.minimized)} 

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} content={<CustomTooltip />}/>

    </BarChart>    
  }

  const getChartBars = (data) => {
    const uniqueCategory = sortCrimeCategories([...new Set(chartData.map(x => x.category))]);    
    const result = uniqueCategory.map((c, idx) => {
      return getChartBarItem(c, idx, idx == uniqueCategory.length - 1);
    });

    ca8debug(`${TAG} getChartBars result`, result);
    return result;
  }

  const getChartBarItem = (category, idx, isLast) => {
    return <Bar key={`bar_${idx}`} label={false} barSize={20} isAnimationActive={false} dataKey={category} stackId="a" fill={getFillForCategory(category)} >
      {isLast
        ? <LabelList dataKey='value' position="right" fill="#000000" formatter={(x) => showCrimeTotal ? x : Number(x).toFixed(1)}/>
        : ''
      }
    </Bar>     
  }

  const CustomLegend = () => {
    if (chartMode == 'group1') {
      return FiltersLegend(allFilters, resetFilters, null);
    }
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const onCrimeGroupsChanged = (e) => {
    const isUseCrimeGroups = !useCrimeGroups
    setUseCrimeGroups(isUseCrimeGroups);
    if (drawerVisible) {
      onShowData(isUseCrimeGroups);      
    }
  }

  const getColumns = () => {
    const result = 
    [
      {
        title: 'Group 1',
        dataIndex: 'group1',
        key: 'group1',
        filteredValue: allFilters.group1 || null,
        onFilter: (value, record) => {
          return record.group1.includes(value);
        },      
      },
      {
        title: 'Group 2',
        dataIndex: 'group2',
        key: 'group2',
        filteredValue: allFilters.group2 || null,
        onFilter: (value, record) => {
          return record.group2.includes(value);
        },      
      },
      {
        title: 'Site',
        dataIndex: 'site',
        key: 'site',
        filteredValue: allFilters.site || null,
        onFilter: (value, record) => {
          return record.site.includes(value);
        },      
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'total_crime',
      }
    ];

    if (useCrimeGroups) {
      result.push(      
        {
          title: 'Crime Group',
          dataIndex: 'category',
          key: 'category',
          filteredValue: allFilters.category || null,
          onFilter: (value, record) => {
            return record.category.includes(value);
          },
      });
    }
    if (!showCrimeTotal) {
      result.push(      
        {
          title: 'Rate',
          key: 'rate',
          sorter: true,
          render: (text, record) => {
            return calculateWithPopulation(record)
          }
        });

    }
    return result;
  }

  const onShowCrimeTotalChange = (checked) => {
    setShowCrimeTotal(checked);
  }

  const onShowData = (isUseCrimeGroups) => {
    const dt = calculateTableData(isUseCrimeGroups);
    props.selectChart('site');

    setChartTableMode('sites');
    setChartTableTitle('Sites / Site groups');
    setChartTableFilter(allFilters);
    setChartTableData(dt);
    setChartTableColumns(getColumns());
    setDrawerVisible(true);
  }

  const onDrawerClose = () => {
    setChartTableData([]);
    setDrawerVisible(false);
    props.selectChart('');
  }  

  return (
    <div id="divSiteChart" className="chart3-container" style={{ backgroundColor: props.selected ? '#defbee' : 'transparent'}}>
      <table>
        <tr>
      {drawerVisible
      ? ''
      : <td className="chart3-col-left">
        <div className="chart3-col-title">Sites / Site Groups</div>
        <div>
          <div className="chart3-col-title-row">
          
            <div>
                <Menu mode="vertical" style={{width: 200, border: 'none' }}>
                  <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
                    <Menu.Item disabled={loading || props.loading} icon={<FilePdfOutlined />} onClick={() => {
                      exportToPdf(exportRef.current, "test");
                    }} key="export">PDF</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
                  </SubMenu>
                  <Menu.Item 
                    disabled={loading || props.loading}
                    icon={<TableOutlined />} 
                    key="table_data"
                    loading={calculatingTableData}
                    onClick={() => onShowData(useCrimeGroups)}>
                    Show data
                  </Menu.Item>
                  <Menu.Item key="use_crime_total">
                    <Switch 
                      disabled={props.loading}
                      style={{width: 150}}
                      checkedChildren="Crime total" 
                      unCheckedChildren="Crime rate" 
                      checked={showCrimeTotal} 
                      onChange={(checked) => onShowCrimeTotalChange(checked)} 
                    />
                  </Menu.Item>

                </Menu>
              </div>
          
          </div>
        </div>
      </td>
      }

      <td className="chart3-col-right">
        <Spin tip={loadingMessage} spinning={loading}>
          {loading
          ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
          : data && data.length > 0
            ? <div ref={exportRef} style={{ width: drawerVisible ? 'calc(100vh - 200px)' : '100%' }}>
                <CustomLegend/>
                <ResponsiveContainer width='100%' height={cellCount > 15 ? cellCount * 25 : 400}>
                {getChart(false)}
                </ResponsiveContainer>
              </div>
            : <div style={{width: '100%', height: 400, padding: 100}}><Empty /></div>
          }
        </Spin>
      </td>
      </tr>
      </table>

      <SiteChartDrawer 
            visible={drawerVisible} 
            onClose={onDrawerClose} 
            data={chartTableData} 
            chartData={chartTableData} 
            mode={chartTableMode} 
            title={chartTableTitle} 
            filter={chartTableFilter} 
            columns={chartTableColumns}
            useCrimeGroups={useCrimeGroups}
            onCrimeGroupsChanged={onCrimeGroupsChanged}
            calculatingTableData={calculatingTableData}
            extra={chartTableExtra}/>

    </div>
  );
}

export default SitesChart3;