import React, { useContext, useCallback, useState } from 'react'
import { sortAsc, sortDesc, FiltersLegend, emptyDiv } from './Utils';

import { Table, Button, Space, Select, DatePicker,Card,Checkbox,Divider   } from 'antd';
import { CheckOutlined,UndoOutlined } from '@ant-design/icons';
import FilterOptionsParentChild from './FilterOptionsParentChild'
import { AppContext } from "../contexts/AppContext";


const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterOptions = (props) => {
  const TAG = 'FilterOptions';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const dayOptions = [
      { label: 'Sunday', value: 6 },
      { label: 'Monday', value: 0 },
      { label: 'Tuesday', value: 1 },
      { label: 'Wednesday', value: 2 },
      { label: 'Thursday', value: 3 },
      { label: 'Friday', value: 4 },
      { label: 'Saturday', value: 5 },
  ];

  const [dateMode, setDateMode] = useState('date');
  const [useDatePeriod, setUseDatePeriod] = useState(false);
  const [useTimePeriod, setUseTimePeriod] = useState(false);
  const [useWeekdays, setUseWeekdays] = useState(false);
  const [datePeriod, setDatePeriod] = useState([]);
  const [datePeriodValue, setDatePeriodValue] = useState([]);
  const [timePeriod, setTimePeriod] = useState([]);
  const [weekdays, setWeekdays] = useState([]);

  const [useCrimeTypes, setUseCrimeTypes] = useState(false);
  const [useCrimeCategories, setUseCrimeCategories] = useState(false);
  
  const [crimeTypes, setCrimeTypes] = useState([]);
  const [crimeTypeOptions, setCrimeTypeOptions] = useState([]);
  const [crimeTypeSelection, setCrimeTypeSelection] = useState([]);
  const [crimeCategories, setCrimeCategories] = useState([]);
  const [crimeCategoryOptions, setCrimeCategoryOptions] = useState([]);
  const [crimeCategorySelection, setCrimeCategorySelection] = useState([]);

  const [useGroup1, setUseGroup1] = useState(false);
  const [group1, setGroup1] = useState([]);
  const [group1Selection, setGroup1Selection] = useState([]);

  const [useGroup2, setUseGroup2] = useState(false);
  const [group2, setGroup2] = useState([]);
  const [group2Options, setGroup2Options] = useState([]);
  const [group2Selection, setGroup2Selection] = useState([]);

  const [useSite, setUseSite] = useState(false);
  const [site, setSite] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [siteSelection, setSiteSelection] = useState([]);

  //const [filterOptions, setFilterOptions] = React.useState({});

  React.useEffect(() => {
    apiClient.get(`/chart/ctypes`)
    .then(response => {
      ////console.log(`${TAG} crime_types data response`, response);
      
          const dt = response.data.map((x, idx) => {
            return {
                value: x.crime_type_id, 
                parent_id: x.crime_category_id, 
                label: x.crime_type_name, 
            }
          });            
          ////console.log(`${TAG} crime_types dt`, dt);
          setCrimeTypes(dt);
          setCrimeTypeOptions(dt);
      
    });
    
    apiClient.get(`/chart/ccategories`)
    .then(response => {
      ////console.log(`${TAG} crime_categoties data response`, response);
      
          const dt = response.data.map((x, idx) => {
            return {
                value: x.crime_category_id, 
                label: x.crime_category_name, 
            }
          });            
          ////console.log(`${TAG} crime_types dt`, dt);
          setCrimeCategories(dt);
      
    }); 

    apiClient.get(`/chart/group1`).then(response => {
      ////console.log(`${TAG} group1 data response`, response);
      
          const dt = response.data.map((x, idx) => {
            return {
                value: x.state_id, 
                label: x.state_name, 
            }
          });            
          ////console.log(`${TAG} group1 dt`, dt);
          setGroup1(dt);
    });    

    apiClient.get(`/chart/group2`).then(response => {
      //console.log(`${TAG} group2 data response`, response);
      
          const dt = response.data.map((x, idx) => {
            return {
                value: x.city_id, 
                parent_id: x.state_id, 
                label: x.city_name, 
            }
          });            
          //console.log(`${TAG} group2 dt`, dt);
          setGroup2(dt);
          setGroup2Options(dt);
    });

    apiClient.get(`/chart/site`).then(response => {
      //console.log(`${TAG} site data response`, response);
      
          const dt = response.data.map((x, idx) => {
            return {
                value: x.site_id, 
                parent_id: x.city_id, 
                label: x.site_name, 
            }
          });            
          //console.log(`${TAG} site dt`, dt);
          setSite(dt);
          setSiteOptions(dt);
    });

  }, []);



  const onUseDatePeriodChanged = (e) => {
    setUseDatePeriod(e.target.checked);
  }
  const onUseTimePeriodChanged = (e) => {
    setUseTimePeriod(e.target.checked);
  }
  const onUseWeekdaysChanged = (e) => {
    setUseWeekdays(e.target.checked);
  }

  const onDatePeriodChange = (date, dateString) => {
    setDatePeriod(dateString);
    setDatePeriodValue(date);
  }
  const onTimePeriodChange = (date, dateString) => {
    setTimePeriod(dateString);
  }  
  const onDayChange = (checkedValues) => {
    setWeekdays(checkedValues);
  }

  const onSetaDateMode = (e) => {
    //console.log(`${TAG} onSetaDateMode`,e);
    setDateMode(e);
    setDatePeriodValue([]); 
  }  

  // crimes
  const onUseCrimeCategoriesChanged = (e) => {
    //console.log(`${TAG} onUseCrimeCategoriesChanged`,e,crimeCategories,crimeTypes);
    setUseCrimeCategories(e.target.checked);
    const ctypes = (crimeCategorySelection != null && crimeCategorySelection.length > 0) ? crimeTypes.filter(x => crimeCategorySelection.includes(x.parent_id)) : crimeTypes;
    setCrimeTypeOptions(ctypes);
  }  
  const onCrimeCategoryChange = (checkedValues) => {
    //console.log(`${TAG} onCrimeCategoryChange`,checkedValues);
    setCrimeCategorySelection(checkedValues);
    const ctypes = (checkedValues != null && checkedValues.length > 0) ? crimeTypes.filter(x => checkedValues.includes(x.parent_id)) : crimeTypes;
    setCrimeTypeOptions(ctypes);
    setCrimeCategorySelection(checkedValues);
  }

  const onUseCrimeTypesChanged = (e) => {
    //console.log(`${TAG} onUseCrimeTypesChanged`,e);
    setUseCrimeTypes(e.target.checked);
  }  
  const onCrimeTypeChange = (checkedValues) => {
    //console.log(`${TAG} onCrimeTypeChange`,checkedValues);
    setCrimeTypeSelection(checkedValues);
  }

  // geography
  const onUseGroup1Changed = (e) => {
    const fg2 = (group1Selection != null && group1Selection.length > 0) ? group2.filter(x => group2Selection.includes(x.parent_id)) : group2;
    const fg2Ids = fg2.map(x => x.value);
    const sit = (fg2 != null && fg2.length > 0) 
      ? site.filter(x => fg2Ids.includes(x.parent_id)) 
      : site;
    setUseGroup1(e.target.checked);
    setGroup2Options(fg2.sort((a, b) => (a.label > b.label) ? 1 : -1));
    setSiteOptions(sit.sort((a, b) => (a.label > b.label) ? 1 : -1));
    //console.log(`${TAG} onUseGroup1Changed`,e,fg2,sit,site);
  }  

  const onGroup1Change = (checkedValues) => {
    const fg2 = (checkedValues != null && checkedValues.length > 0) ? group2.filter(x => checkedValues.includes(x.parent_id)) : group2;
    const fg2Ids = fg2.map(x => x.value);
//    const sit = (fg2 != null && fg2.length > 0) ? site.filter(x => fg2Ids.includes(x.parent_id)) : site;
    const sit = (fg2 != null && fg2.length > 0) 
      ? site.filter(x => fg2Ids.includes(x.parent_id)) 
      : site;

    setGroup2Options(fg2.sort((a, b) => (a.label > b.label) ? 1 : -1));
    setSiteOptions(sit.sort((a, b) => (a.label > b.label) ? 1 : -1));
    //console.log(`${TAG} onGroup1Change setSiteOptions`,sit);

    setGroup1Selection(checkedValues);
    //console.log(`${TAG} onGroup1Change`,checkedValues);
  }  

  const onUseGroup2Changed = (e) => {
    //console.log(`${TAG} onUseGroup2Changed`,e);
    const sit = (group2Selection != null && group2Selection.length > 0) 
      ? site.filter(x => group2Selection.includes(x.parent_id))
      : site;
    setUseGroup2(e.target.checked);
    setSiteOptions(sit.sort((a, b) => (a.label > b.label) ? 1 : -1));
  }  
  const onGroup2Change = (checkedValues) => {
    //console.log(`${TAG} onGroup2Change`,checkedValues);
    const sit = (checkedValues != null && checkedValues.length > 0) 
      ? site.filter(x => checkedValues.includes(x.parent_id))
      : site;
    setSiteOptions(sit.sort((a, b) => (a.label > b.label) ? 1 : -1));
    setGroup2Selection(checkedValues);
  }  

  const onUseSiteChanged = (e) => {
    //console.log(`${TAG} onUseSiteChanged`,e);
    setUseSite(e.target.checked);
  }  
  const onSiteChange = (checkedValues) => {
    //console.log(`${TAG} onSiteChange`,checkedValues);
    setSiteSelection(checkedValues);
  }  

  const onClearFilters = () => {

    setDateMode('date');
    setDatePeriod([]);
    setDatePeriodValue([]);
    setTimePeriod([]);
    setWeekdays([]);
  
    //const [crimeTypes, setCrimeTypes] = useState([]);
    //const [crimeTypeOptions, setCrimeTypeOptions] = useState([]);
    setCrimeTypeSelection([]);
    //const [crimeCategories, setCrimeCategories] = useState([]);
    //const [crimeCategoryOptions, setCrimeCategoryOptions] = useState([]);
    setCrimeCategorySelection([]);
  
    //const [group1, setGroup1] = useState([]);
    setGroup1Selection([]);
  
    //const [group2, setGroup2] = useState([]);
    //const [group2Options, setGroup2Options] = useState([]);
    setGroup2Selection([]);
  
    //const [site, setSite] = useState([]);
    //const [siteOptions, setSiteOptions] = useState([]);
    setSiteSelection([]);    

    setUseTimePeriod(false);
    setUseWeekdays(false);
    setUseDatePeriod(false);

    setUseCrimeTypes(false);
    setUseCrimeCategories(false);

    setUseSite(false);
    setUseGroup2(false);
    setUseGroup1(false);

    const filterOptions = {
      useDatePeriod: false,
      useTimePeriod: false,
      useWeekdays: false,
      dateMode: 'date',
      datePeriod: [],
      timePeriod: [],
      weekdays: [],
      useCrimeCategories: false,
      useCrimeTypes: false,
      crimeCategories: [],
      crimeTypes: [],
      useGroup1: false,
      useGroup2: false,
      useSite: false,
      group1: [],
      group2: [],
      site: []
    }

    //console.log(`${TAG} onClearFilters result`,filterOptions);
    props.onUseFilters(filterOptions);    

  }

  const onRun = () => {

    // filter crimes
    const ctypes = (crimeCategorySelection != null && crimeCategorySelection.length > 0) ? crimeTypes.filter(x => crimeCategorySelection.includes(x.parent_id)) : crimeTypes;
    const ctypeIds = ctypes.map(x => x.value);
    const filteredCrimeTypes = crimeTypeSelection.filter(x => ctypeIds.includes(x));

    // filter geography
    const g2 = (group1Selection != null && group1Selection.length > 0) ? group2.filter(x => group1Selection.includes(x.parent_id)) : group2;
    const g2Ids = g2.map(x => x.value);
    const fg2Ids = group2Selection.filter(x => g2Ids.includes(x));
    //console.log(`${TAG} onRun g2`,group2,fg2Ids);
    const sit = (fg2Ids != null && fg2Ids.length > 0) ? site.filter(x => fg2Ids.includes(x.parent_id)) : site;
    const sitIds = sit.map(x => x.value);
    const fsitIds = siteSelection.filter(x => sitIds.includes(x));
    //console.log(`${TAG} onRun g2`,site,sit);


    const filterOptions = {
      useDatePeriod: useDatePeriod,
      useTimePeriod: useTimePeriod,
      useWeekdays: useWeekdays,
      dateMode: dateMode,
      datePeriod: datePeriod,
      timePeriod: timePeriod,
      weekdays: weekdays,
      useCrimeCategories: useCrimeCategories,
      useCrimeTypes: useCrimeTypes,
      crimeCategories: crimeCategorySelection,
      crimeTypes: filteredCrimeTypes,
      useGroup1: useGroup1,
      useGroup2: useGroup2,
      useSite: useSite,
      group1: group1Selection,
      group2: fg2Ids,
      site: fsitIds
    }

    //console.log(`${TAG} onRun result`,filterOptions);
    props.onUseFilters(filterOptions);
  }

  return (
    <div style={{padding: 10}}>
      <h2>Filters</h2>
      <Divider/>


      <div className={useCrimeCategories ? 'filter-option-enabled' : 'filter-option-disabled'}>
          {useCrimeCategories ? <h3>Crime Groups and Types</h3> : ''}
          <div>
          <span style={{width: 200, display: "inline-block"}}>
            <Checkbox checked={useCrimeCategories} onChange={onUseCrimeCategoriesChanged}>
              {useCrimeCategories ? 'Crime groups:' : 'Crime Groups and Types'}
            </Checkbox></span>
          {useCrimeCategories
          ? <div style={{display: "inline-block"}}>
              <Checkbox.Group options={crimeCategories} onChange={onCrimeCategoryChange} style={{height: 26}} />
            </div>
          : ''}
          {useCrimeCategories
          ? <div> 
              <Divider/>
              <span style={{width: 200, display: "inline-block", verticalAlign: 'top'}}><Checkbox onChange={onUseCrimeTypesChanged}>Crime types:</Checkbox></span>
                {useCrimeTypes
                ? <div style={{width: 1300, display: "inline-block"}}>
                    <Checkbox.Group options={crimeTypeOptions} onChange={onCrimeTypeChange} style={{height: 26}} />
                  </div>
                : ''}
            </div>
          : ''}
          </div>
        </div>

        <div className={useGroup1 ? 'filter-option-enabled' : 'filter-option-disabled'}>
          {useGroup1 ? <h3>Geography</h3> : ''}
          <div>
            <span style={{width: 200, display: "inline-block"}}>
              <Checkbox checked={useGroup1} onChange={onUseGroup1Changed}>
              {useGroup1 ? 'Group1:' : 'Geography'}
              </Checkbox></span>
            {useGroup1
            ? <div style={{display: 'inline-block'}}>
                <Checkbox.Group options={group1} onChange={onGroup1Change} style={{height: 26}} />
              </div>
            : ''}
            {useGroup1
            ? <div> 
                <Divider/>
                <span style={{width: 200, display: "inline-block", verticalAlign: 'top'}}><Checkbox onChange={onUseGroup2Changed}>Group2:</Checkbox></span>
                {useGroup2
                  ? <div style={{width: 1300, display: "inline-block"}}>
                      <Checkbox.Group options={group2Options} onChange={onGroup2Change} style={{height: 26}} />
                    </div>
                  : ''}
                {useGroup2
                ? <div> 
                    <Divider/>
                    <span style={{width: 200, display: "inline-block", verticalAlign: 'top'}}><Checkbox onChange={onUseSiteChanged}>Sites:</Checkbox></span>
                      {useSite
                      ? <div style={{width: 1300, display: "inline-block"}}>
                          <Checkbox.Group options={siteOptions} onChange={onSiteChange} style={{height: 26}} />
                        </div>
                      : ''}
                  </div>
                : ''}
              </div>
            : ''}
          </div>          
        </div>


      <div className={useDatePeriod ? 'filter-option-enabled' : 'filter-option-disabled'}>
        {useDatePeriod ? <h3>Date / Time</h3> : ''}
        <div>
          <span style={{width: 200, display: "inline-block"}}>
            <Checkbox checked={useDatePeriod} onChange={onUseDatePeriodChanged}>
            {useDatePeriod ? 'Date range:' : 'Date / Time'}
            </Checkbox></span>
          {useDatePeriod
          ? <Space direction="horizontal">
              <Space direction="vertical" size={12}>
                <RangePicker picker={dateMode} onChange={onDatePeriodChange} value={datePeriodValue}/>
              </Space>
              <Select value={dateMode} onChange={onSetaDateMode}>
                <Option value="date">Date</Option>
                <Option value="month">Month</Option>
                <Option value="year">Year</Option>
              </Select>   
            </Space>   
          : ''}
        </div> 
        {useDatePeriod  
        ? <div style={{marginTop: 10, marginBottom: 10, height: 30}}>
            <span style={{width: 200, display: "inline-block"}}>
              <Checkbox onChange={onUseTimePeriodChanged}>Time range:</Checkbox>
            </span>
            {useTimePeriod
            ? <RangePicker picker="time" onChange={onTimePeriodChange}/>
            : ''}
          </div>
        : ''}    

        {useDatePeriod 
        ? <div>
            <span style={{width: 200, display: "inline-block"}}>
              <Checkbox onChange={onUseWeekdaysChanged}>Days of week:</Checkbox>
            </span>
            {useWeekdays
            ? <Checkbox.Group options={dayOptions} onChange={onDayChange} style={{height: 26}} />
            : ''}
          </div>    
        : ''}
      </div>

        <Divider/>

        <Space direction='horizontal' style={{ float: "left", marginBottom: 50 }}>
          <Button 
            icon={<CheckOutlined />}
            type='primary' 
            onClick={() => onRun()}>Apply
          </Button>
          <Button 
            icon={<UndoOutlined />}
            type='ghost' 
            onClick={() => onClearFilters()}>Clear filters
          </Button>
        </Space>

    </div>
  );
}



export default FilterOptions;

/*
        <FilterOptionsParentChild
          onUseParent={onUseCrimeCategoriesChanged}
          parentTitle={'Crime categories:'}
          useParent={useCrimeCategories}
          parentItems={crimeCategories}
          onParentItemsChange={onCrimeCategoryChange}
          onUseChild={onUseCrimeTypesChanged}
          childTitle={'Crime types:'}
          useChild={useCrimeTypes}
          childItems={crimeTypeOptions}
          onChildItemsChange={onCrimeTypeChange}        
        />
*/