import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Space, Radio, Breadcrumb, Button, Divider, Drawer, Input, Checkbox } from 'antd';
import {
    DesktopOutlined,
    SettingOutlined,
    CloudOutlined,
    AlertOutlined,
    TeamOutlined,
    UserOutlined,
    FileOutlined,
    LogoutOutlined,
    PropertySafetyFilled,
  } from '@ant-design/icons';
import ErrorList from "antd/lib/form/ErrorList";

const { TextArea } = Input;

const UserDrawer = (props) => {
  const TAG = 'UserDrawer';

  const [data, setData] = useState({});
  const [error, setError] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [passwordCheck, setPasswordCheck] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(true);

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  React.useEffect(() => {
    //console.log(`${TAG} useEffect`, props);

    let dt = props.data;
    if (!props.data) { 
        //console.log(`${TAG} useEffect set default role`, props.data);
        dt = {};
        dt.role = 0;
    }

    setData(dt);
    setPasswordCheck('');
    setError('');
    setErrors([]);

    setIsChangePassword(!dt?.id);

  }, [props.client, props.data, props.visible]);

  const onSave = () => {
    data.isChangePassword = isChangePassword;

    //console.log(`${TAG} onSave`, data, props);

    setLoading(true);
    setErrors([]);
    setError('');
    setPasswordCheck('');

    if (data?.id) {
      apiClient.post(`api/clients/${props.client.id}/users/${data.id}`, data)
        .then(response => {
          //console.log(`${TAG} onSave update response`, response);
          setLoading(false);
          setData({});
          props.onSave('User updated successfully');
        })
        .catch(function (error) {
          //console.log(`${TAG} onSave update error`,error);
          if( error.response ){
              //console.log(`${TAG} onSave update error response`, error.response.data);
              setErrors(error.response.data.errors)
          }            
          setLoading(false);
      });   

    } else {
      apiClient.post(`api/clients/${props.client.id}/users`, data)
        .then(response => {
          //console.log(`${TAG} onSave create response`, response);
          setLoading(false);
          setData({});
          props.onSave('User created successfully');
        })
        .catch(function (error, response) {
            //console.log(`${TAG} onSave create error`,error);
            if( error.response ){
                //console.log(`${TAG} onSave create error response`, error.response.data);
                if (error.response.data.errors) {
                    setErrors(error.response.data.errors)
                } else {
                    setError(error.response.data.message);
                }
            } else {
                setError(error);
            }           
            setLoading(false);
          });   
    } 

    //setData(undefined);
  }

  const onClose = () => {
    props.onClose();
    setData({});
    setErrors([]);
    setError('');
    setPasswordCheck('');
}

  const ucr_modes = [
    { label: 'SRS', value: 1 },
    { label: 'NIBS', value: 2 },
  ];

  const isSaveButtonEnabled = !isChangePassword
    ? true
    : isChangePassword && (data?.password?.length > 0 && data?.password === passwordCheck)
      ? true
      : false; 

  return (
    <Drawer title={data?.id ? 'Edit User' : 'Add User'} placement="right" onClose={props.onClose} visible={props.visible}>
        <Space style={{ width: '100%'}} direction="vertical">
            <div>
                <div className="drawer-field-title">Name</div>
                <Input allowClear value={data?.name} onChange={(e) => setData({...data, name:e.target.value})}/>
                <div className="drawer-field-error">{errors?.name}</div>
            </div>
            <div>
                <div className="drawer-field-title" style={{ display: 'inline-block', marginRight: 20 }}>Role:</div>
                <Radio.Group onChange={(e) => setData({...data, role:e.target.value})} defaultValue={0} value={data?.role ?? 0}>
                    <Space direction="horizontal">
                    <Radio value={1}>Local admin</Radio>
                    <Radio value={0}>User</Radio>
                    </Space>
                </Radio.Group>

                <div className="drawer-field-error">{errors?.role}</div>            
            </div>
            <div>
                <div className="drawer-field-title">E-mail</div>
                <Input autoComplete="off" allowClear value={data?.email} onChange={(e) => setData({...data, email:e.target.value})}/>
                <div className="drawer-field-error">{errors?.email}</div>            
            </div>
            <div>
                    
                    {data.id > 0 
                      ? <div className="drawer-field-title">
                          <Checkbox checked={isChangePassword} onChange={(e) => setIsChangePassword(e.target.checked)} >
                            Change password
                          </Checkbox>
                        </div>
                      : <div className="drawer-field-title">Password</div>}
                    {isChangePassword &&
                    <Space direction="vertical">
                        <Input.Password autoComplete="new-password" allowClear value={data?.password} onChange={(e) => setData({...data, password:e.target.value})}/>
                        <Input.Password autoComplete="new-password" allowClear value={passwordCheck} onChange={(e) => setPasswordCheck(e.target.value)}/>
                    </Space>}
                    <div className="drawer-field-error">{errors?.password}</div>            
            </div>
            <div className="drawer-field-error">{error}</div>            

        </Space>

        <div style={{ marginTop: 20 }}>
            <Space direction="horizontal">
                
                {isSaveButtonEnabled
                    ? <Button type="primary" onClick={onSave} loading={loading}>Save</Button>
                    : <Button type="primary" disabled>Save</Button>
                }
                
                <Button type="primary" onClick={onClose} loading={loading}>Cancel</Button>
            </Space>
        </div>
  </Drawer>
  );

};

export default UserDrawer;
