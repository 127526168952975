import React, { useContext } from "react";
import { Layout, Menu, Breadcrumb, Button } from 'antd';
  
import {
    ShrinkOutlined,
    ArrowsAltOutlined,
  } from '@ant-design/icons';
  

const MaximizeMinimizeButton = (props) => {
  const TAG = 'MaximizeMinimizeButton';

  return <Button 
    style={{ position: 'absolute', bottom: 10, right: 10 }} 
    icon={props.minimized
    ? <ArrowsAltOutlined />
    : <ShrinkOutlined />} size="large" onClick={props.onClick} />
  
};

export default MaximizeMinimizeButton;


