import React, { useContext } from "react";
import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN,
} from "../constants/AuthStatus";
import AuthNotLoggedIn from "./AuthNotLoggedIn";
//import AuthSignup from "./AuthSignup";
//import AuthLogin from "./AuthLogin";
import AuthLogout from "./AuthLogout";
import { AppContext } from "../contexts/AppContext";
import AuthLogin2 from "./AuthLogin2";
import ChartsPage from "./ChartsPage";
import { Layout, Menu, Breadcrumb, Button } from 'antd';
import {
    AreaChartOutlined,
    SettingOutlined,
    CloudOutlined,
    ShopOutlined,
    TeamOutlined,
    UserOutlined,
    UploadOutlined,
    LogoutOutlined,
  } from '@ant-design/icons';

  
const { Header, Content, Footer } = Layout;
const { SubMenu } = Menu;


const AppHeader = () => {
  const TAG = 'AppHeader';

  const appContext = useContext(AppContext);
  const { authStatus, errorMessage } = appContext;
  const showNotLoggedIn = authStatus === NOT_LOGGED_IN ? "" : "hidden";
  const showLoginForm = authStatus === LOG_IN_FORM ? "" : "hidden";
  const showSignupForm = authStatus === SIGN_UP_FORM ? "" : "hidden";
  const showLoggedIn = authStatus === LOGGED_IN ? "" : "hidden";
  const { userName, logout, setCurrentPage, currentPage, appUserName } = appContext;

  return (
    <Header>
      <div className="logo"><div className="app-version">v. 0.2.232</div></div>
      <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[`main_menu_${currentPage}`]} columns="2">
        <Menu.Item key={'main_menu_dashboard'} icon={<AreaChartOutlined />} onClick={() => setCurrentPage('dashboard')}>Dashboard</Menu.Item>
        <Menu.Item key={'main_menu_settings'} icon={<SettingOutlined />} onClick={() => setCurrentPage('settings')}>Settings</Menu.Item>
        <SubMenu key={'main_menu_user'} icon={<UserOutlined />} title={`Welcome, ${userName}`}>
            <Menu.Item key={'main_menu_user_logout'} icon={<LogoutOutlined />} onClick={() => logout()}>Logout</Menu.Item>
        </SubMenu>
      </Menu>
    </Header>
  );
};

export default AppHeader;
