import React, { useContext, useCallback, useState } from 'react'
import { sortAsc, sortDesc, FiltersLegend, emptyDiv, getMenuColor, ca8debug, daysInMonth } from './Utils';

import { Switch, notification, Menu, Button, Space, Select, DatePicker,Layout,Checkbox,Divider   } from 'antd';
import { CheckOutlined,UndoOutlined } from '@ant-design/icons';
import FilterOptionsParentChild from './FilterOptionsParentChild'
import { AppContext } from "../contexts/AppContext";
import moment from 'moment';

import { FundOutlined,
  CalendarOutlined,
  CommentOutlined,
  BarChartOutlined,
  FieldTimeOutlined,
  SettingOutlined,
  GlobalOutlined,
  AlertOutlined } from '@ant-design/icons';

import { getProperDate } from './Utils';
import AppLogo from './AppLogo';

//import { useCookies, Cookies } from 'react-cookie';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const CheckboxGroup = Checkbox.Group;

const FilterOptions2 = (props) => {
  const TAG = 'FilterOptions2';

  const appContext = useContext(AppContext);
  const {
    apiClient, 
    currentPage,
    setCurrentPage, 
    appRole, 
    appClientId, 
    appCurrentClient,
    logout,
    storeFilterOptions,
    restoreFilterOptions
  } = appContext;  

  const dayValues = [
      { label: 'Sunday', value: 6 },
      { label: 'Monday', value: 0 },
      { label: 'Tuesday', value: 1 },
      { label: 'Wednesday', value: 2 },
      { label: 'Thursday', value: 3 },
      { label: 'Friday', value: 4 },
      { label: 'Saturday', value: 5 },
  ];

  const [collapsed, setCollapsed] = React.useState(false);

  const [dateMode, setDateMode] = useState('date');
  const [useDatePeriod, setUseDatePeriod] = useState(false);
  const [useTimePeriod, setUseTimePeriod] = useState(false);
  const [useWeekdays, setUseWeekdays] = useState(false);
  const [datePeriod, setDatePeriod] = useState([]);
  const [datePeriodValue, setDatePeriodValue] = useState([]);
  const [timePeriod, setTimePeriod] = useState([]);
  const [timePeriodValue, setTimePeriodValue] = useState([]);
  const [weekdays, setWeekdays] = useState([]);
  const [weekdayOptions, setWeekdayOptions] = useState([]);

  const [useCrimeTypes, setUseCrimeTypes] = useState(false);
  const [useCrimeCategories, setUseCrimeCategories] = useState(false);
  
  //const [crimeTypes, setCrimeTypes] = useState([]);
  const [crimeTypeOptions, setCrimeTypeOptions] = useState([]);
  const [crimeTypeSelection, setCrimeTypeSelection] = useState([]);
  //const [crimeCategories, setCrimeCategories] = useState([]);
  const [crimeCategoryOptions, setCrimeCategoryOptions] = useState([]);
  const [crimeCategorySelection, setCrimeCategorySelection] = useState([]);

  const [useGroup1, setUseGroup1] = useState(false);
  //const [group1, setGroup1] = useState([]);
  const [group1Options, setGroup1Options] = useState([]);
  const [group1Selection, setGroup1Selection] = useState([]);

  const [useGroup2, setUseGroup2] = useState(false);
  //const [group2, setGroup2] = useState([]);
  const [group2Options, setGroup2Options] = useState([]);
  const [group2Selection, setGroup2Selection] = useState([]);

  const [useSite, setUseSite] = useState(false);
  //const [site, setSite] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [siteSelection, setSiteSelection] = useState([]);

  const [relationshipOption, setRelationshipOption] = useState([]);
  const [relationshipSelection, setRelationshipSelection] = useState([]);

  const [isFilterOptionsRestored, setIsFilterOptionsRestored] = useState(false);

  const [filterLegend, setFilterLegend] = useState('');

  //const [cookie, setCookie] = useCookies(['ca8']);


  React.useEffect(() => {

    if (props && props.allData) {
      ca8debug(`${TAG} useEffect props.allData`, props.allData);
      const days = dayValues.map((x) => {
        return <Option key={`day_option_${x.value}`} value={x.value} label={x.label} >{x.label}</Option>
      });
      setWeekdayOptions(days);

      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData.crimeTypes) {
        const options = props.allData.crimeTypes.map((x) => {
          return <Option key={`crime_type_${x.value}`} value={x.value} label={x.label} parent_id={x.parent_id}>{x.label}</Option>
        });            
        ca8debug(`${TAG} crime_types options`, props.allData.crimeTypes, options);
        setCrimeTypeOptions(options);           
      }
    
      if (props.allData.crimeCategories) {
        const options = props.allData.crimeCategories.map((x, idx) => {
          return <Option key={`category_${idx}`} value={x.value} label={x.label}>{x.label}</Option>
        });            
        ca8debug(`${TAG} crime_categoties options`, props.allData.crimeCategories, options);
        setCrimeCategoryOptions(options);  
      }        
      
      if (props.allData.group1) {
        const options = props.allData.group1.map((x) => {
          return <Option key={`group1_${x.value}`} value={x.value} label={x.label}>{x.label}</Option>
        });             
        ca8debug(`${TAG} group1 options`, props.allData.group1, options);
        setGroup1Options(options);           
      }

      if (props.allData.group2) {
        const options = props.allData.group2.map((x) => {
          return <Option key={`group2_${x.value}`} value={x.value} label={x.label}>{x.label}</Option>
        });            
        ca8debug(`${TAG} group2 options`, props.allData.group2, options);
        setGroup2Options(options);           
      }

      if (props.allData.site) {
        const options = props.allData.site.map((x) => {
          return <Option key={`site_${x.value}`} value={x.value} label={x.label}>{x.label}</Option>
        });            
        ca8debug(`${TAG} site options`, props.allData.site, options);
        setSiteOptions(options);           
      }
      if (props.allData.relationship) {
        const options = props.allData.relationship.map((x) => {
          return x.value;
        });            
        ca8debug(`${TAG} site relationship`, props.allData.relationship, options);
        setRelationshipOption(options);           
      }

    }

    if (!isFilterOptionsRestored && props.allData !== undefined) {
      doRestoreFilterOptions();
    }

  }, [props.allData]);


  const doRestoreFilterOptions = () => {
    const filterOptions = restoreFilterOptions();
    ca8debug(`${TAG} doRestoreFilterOptions filterOptions started`, filterOptions);
    //return; 
    if (filterOptions) {
      if (filterOptions.useDatePeriod) {
        setUseDatePeriod(true);
        if (filterOptions.dateMode) {
          setDateMode(filterOptions.dateMode);
        }
        if (filterOptions.datePeriodValue) {
          ca8debug(`${TAG} doRestoreFilterOptions filterOptions.datePeriodValue`, filterOptions.datePeriodValue);
          if (filterOptions.datePeriodValue.length > 1) {
            const m0 = moment(filterOptions.datePeriod[0], 'YYYY-MM-DD');
            const m1 = moment(filterOptions.datePeriod[1], 'YYYY-MM-DD');
            setDatePeriodValue([
              m0,m1
            ]);
            setDatePeriod([
              m0.format("YYYY-MM-DD"),m1.format("YYYY-MM-DD")
            ]);
          }
        }
      }
      if (filterOptions.useWeekdays) {
        setUseWeekdays(true);
        if (filterOptions.weekdays) {
          setWeekdays(filterOptions.weekdays);
        }
      }      
      if (filterOptions.useTimePeriod) {
        setUseTimePeriod(true);
        if (filterOptions.timePeriod) {
          ca8debug(`${TAG} doRestoreFilterOptions filterOptions.timePeriod`, filterOptions.timePeriod);
          if (filterOptions.timePeriod.length > 1) {
            const arr = [
              moment(filterOptions.timePeriod[0], 'hh:mm:ss'),
              moment(filterOptions.timePeriod[1], 'hh:mm:ss')
            ];
            setTimePeriodValue(arr);
          }
        }
      }
      if (filterOptions.useGroup1 && filterOptions.group1) {
        setUseGroup1(true);
        setGroup1Selection(filterOptions.group1);
      }
      if (filterOptions.useGroup2 && filterOptions.group2) {
        setUseGroup2(true);
        setGroup2Selection(filterOptions.group2);
      }
      if (filterOptions.useSite && filterOptions.site) {
        setUseSite(true);
        setSiteSelection(filterOptions.site);
      }
      if (filterOptions.useCrimeCategories && filterOptions.crimeCategories) {
        setUseCrimeCategories(true);
        setCrimeCategorySelection(filterOptions.crimeCategories);
      }
      if (filterOptions.useCrimeTypes && filterOptions.crimeTypes) {
        setUseCrimeTypes(true);
        setCrimeTypeSelection(filterOptions.crimeTypes);
      }
//      if (filterOptions.useRelationship && filterOptions.relationship) {
  
      if (filterOptions.relationship) {
        setRelationshipSelection(filterOptions.relationship.split(','));
      }

      ca8debug(`${TAG} doRestoreFilterOptions done, going to Run`);

      props.onUseFilters(filterOptions);    
      onSetFilterLegend(filterOptions);
      //onRun();
      
    } else {
      onClearFilters();
    }
    setIsFilterOptionsRestored(true);

  }

  const onCollapse = (value) => {
    setCollapsed(value);
  };

  const onUseDatePeriodChanged = (e) => {
    setUseDatePeriod(e.target.checked);
  }
  const onUseTimePeriodChanged = (e) => {
    setUseTimePeriod(e.target.checked);
  }
  const onUseWeekdaysChanged = (e) => {
    setUseWeekdays(e.target.checked);
  }

  const onDatePeriodChange = (date, dateString) => {
    ca8debug(`${TAG} onDatePeriodChange`, date, dateString);
    setDatePeriod(dateString);
    setDatePeriodValue(date);
  }
  const onTimePeriodChange = (date, dateString) => {
    ca8debug(`${TAG} onTimePeriodChange`, date, dateString);
    setTimePeriod(dateString);
    setTimePeriodValue(date);
  }  
  const onDayChange = (checkedValues) => {
    setWeekdays(checkedValues);
  }

  const onSetaDateMode = (e) => {
    ca8debug(`${TAG} onSetaDateMode`,e);
    setDateMode(e);
    setDatePeriodValue([]); 
  }  

  // crimes
  const onUseCrimeCategoriesChanged = (e) => {
    ca8debug(`${TAG} onUseCrimeCategoriesChanged`,e);
    setUseCrimeCategories(e.target.checked);
    setUseCrimeTypes(e.target.checked);

    refreshCrimesSelection(crimeCategorySelection);
  }  
  const onCrimeCategoryChange = (checkedValues) => {
    ca8debug(`${TAG} onCrimeCategoryChange`,checkedValues);
    setCrimeCategorySelection(checkedValues);
    refreshCrimesSelection(checkedValues);
  }
  const onRelationshipChange = (values) => {
    ca8debug(`${TAG} onRelationshipChange`,values);
    setRelationshipSelection(values);
  }

  const refreshCrimesSelection = (selection) => {
    const ctypes = (selection != null && selection.length > 0) 
        ? props.allData.crimeTypes.filter(x => selection.includes(x.parent_id)) : props.allData.crimeTypes;
    const allCtypes = ctypes.map((y) => y.value);
    const ctypesSelection = (crimeTypeSelection != null && crimeTypeSelection.length > 0) 
        ? crimeTypeSelection.filter((x) => allCtypes.includes(x))
        : [];
    const options = ctypes.map((x) => {
        return <Option key={`crime_type_${x.value}`} value={x.value} label={x.label} parent_id={x.parent_id}>{x.label}</Option>
    });            

    setCrimeTypeOptions(options);
    setCrimeTypeSelection(ctypesSelection);
  }
  const onCrimeTypeChange = (checkedValues) => {
    ca8debug(`${TAG} onCrimeTypeChange`,checkedValues);
    setCrimeTypeSelection(checkedValues);
  }

  // geography
  const onUseGroup1Changed = (e) => {
    setUseGroup1(e.target.checked);
    setUseGroup2(e.target.checked);
    setUseSite(e.target.checked);

    refreshGroup2Selection(group1Selection);

    ca8debug('onUseGroup1Changed ', group1Options);
  }  

  const refreshGroup2Selection = (selection) => {
    const fg2 = (selection != null && selection.length > 0) 
        ? props.allData.group2.filter(x => selection.includes(x.parent_id)) 
        : props.allData.group2;
    const fg2Ids = fg2.map(x => x.value);
    const fg2Selection = (group2Selection != null && group2Selection.length > 0)
        ? group2Selection.filter((x) => fg2Ids.includes(x))
        : [];
    const options = fg2.map((x) => {
        return <Option key={`group2_${x.value}`} label={x.label} value={x.value} parent_id={x.parent_id}>{x.label}</Option>
    });    

    ca8debug(`${TAG} refreshGroup2Selection`,selection, options, fg2Selection);

    setGroup2Options(options);
    setGroup2Selection(fg2Selection);
    
    // if none of group2 selected, pass the whole list
    ca8debug(`${TAG} refreshGroup2Selection before refreshSiteSelection`,fg2Selection && fg2Selection.length > 0 , fg2Selection , fg2Ids);

    refreshSiteSelection(fg2Selection && fg2Selection.length > 0 ? fg2Selection : fg2Ids);
  }
  const refreshSiteSelection = (selection) => {
    const sit = (selection != null && selection.length > 0) 
      ? props.allData.site.filter(x => selection.includes(x.parent_id)) 
      : props.allData.site;
    const sitIds = sit.map((x) => x.value);
    const sitSelection = (siteSelection != null && siteSelection.length > 0)
    ? siteSelection.filter((x) => sitIds.includes(x))
    : [];

    const options = sit.map((x) => {
        return <Option key={`site_${x.value}`} label={x.label} value={x.value} parent_id={x.parent_id}>{x.label}</Option>
    });      

    ca8debug(`${TAG} refreshSiteSelection`,selection, sit, sitIds, sitSelection);

    setSiteOptions(options);
    setSiteSelection(sitSelection);
  }

  const onGroup1Change = (checkedValues) => {
    setGroup1Selection(checkedValues);
    refreshGroup2Selection(checkedValues);
    ca8debug(`${TAG} onGroup1Change`,checkedValues);
  }  

  const onGroup2Change = (checkedValues) => {
    ca8debug(`${TAG} onGroup2Change`,checkedValues);
    setGroup2Selection(checkedValues);
    refreshSiteSelection(checkedValues);
  }  

  const onSiteChange = (checkedValues) => {
    ca8debug(`${TAG} onSiteChange`,checkedValues);
    setSiteSelection(checkedValues);
  }  

  const onClearFilters = () => {

    setDateMode('date');
    setDatePeriod([]);
    setDatePeriodValue([]);
    setTimePeriod([]);
    setTimePeriodValue([]);
    setWeekdays([]);
  
    //const [crimeTypes, setCrimeTypes] = useState([]);
    //const [crimeTypeOptions, setCrimeTypeOptions] = useState([]);
    setCrimeTypeSelection([]);
    //const [crimeCategories, setCrimeCategories] = useState([]);
    //const [crimeCategoryOptions, setCrimeCategoryOptions] = useState([]);
    setCrimeCategorySelection([]);
  
    //const [group1, setGroup1] = useState([]);
    setGroup1Selection([]);
  
    //const [group2, setGroup2] = useState([]);
    //const [group2Options, setGroup2Options] = useState([]);
    setGroup2Selection([]);
  
    //const [site, setSite] = useState([]);
    //const [siteOptions, setSiteOptions] = useState([]);
    setSiteSelection([]);    

    setUseTimePeriod(false);
    setUseWeekdays(false);
    setUseDatePeriod(false);

    setUseCrimeTypes(false);
    setUseCrimeCategories(false);

    setUseSite(false);
    setUseGroup2(false);
    setUseGroup1(false);

    setRelationshipSelection([]);

    const filterOptions = {
      useDatePeriod: false,
      useTimePeriod: false,
      useWeekdays: false,
      dateMode: 'date',
      datePeriod: [],
      timePeriod: [],
      weekdays: [],
      useCrimeCategories: false,
      useCrimeTypes: false,
      crimeCategories: [],
      crimeTypes: [],
      useGroup1: false,
      useGroup2: false,
      useSite: false,
      group1: [],
      group2: [],
      site: [],
      useRelationship: false,
      relationship: ''
    }
    
    setFilterLegend('');
    ca8debug(`${TAG} onClearFilters result`,filterOptions);
    props.onUseFilters(filterOptions);    
    //onShowLegend('');
    props.onSetFilterLegend('');
    storeFilterOptions(false);
  }

  const onRun = () => {

    // stranger / non-stranger
    const useRelationship = relationshipSelection && relationshipSelection.length > 0;
    let relVal = "";
    if (useCrimeCategories && useCrimeTypes && crimeCategorySelection && crimeCategorySelection.includes(1) && useRelationship) {
      relVal = relationshipSelection.join(',');
    }   

    // take care of date mode
    let newDatePeriod = [];
    let newDatePeriodValue = [];
    if (datePeriod?.length && datePeriodValue?.length) {
    
      if (dateMode === 'year') {
        newDatePeriod = [
          `${datePeriod[0]}-01-01 00:00`,
          `${datePeriod[1]}-12-31 23:59`,
        ];
        newDatePeriodValue = [
          moment(newDatePeriod[0], 'YYYY-MM-DD hh:mm:ss'),
          moment(newDatePeriod[1], 'YYYY-MM-DD hh:mm:ss'),
        ];
      } if (dateMode === 'month') {
        const arr = datePeriod[1].split('-');
        const days = daysInMonth(arr[1], arr[0]);
        newDatePeriod = [
          `${datePeriod[0]}-01 00:00`,
          `${datePeriod[1]}-${days} 23:59`,
        ];
        newDatePeriodValue = [
          moment(newDatePeriod[0], 'YYYY-MM-DD hh:mm:ss'),
          moment(newDatePeriod[1], 'YYYY-MM-DD hh:mm:ss'),
        ];
      } else if (dateMode === 'date') {
        newDatePeriod = [
          `${datePeriod[0]} 00:00`,
          `${datePeriod[1]} 23:59`,
        ];
        newDatePeriodValue = [
          moment(newDatePeriod[0], 'YYYY-MM-DD hh:mm:ss'),
          moment(newDatePeriod[1], 'YYYY-MM-DD hh:mm:ss'),
        ];
      }
    }

    ca8debug(`${TAG} onRun datePeriodValue 1`, dateMode, datePeriod, datePeriodValue);
    ca8debug(`${TAG} onRun datePeriodValue 2`, newDatePeriod, newDatePeriodValue);

    const filterOptions = {
      useDatePeriod: useDatePeriod,
      useTimePeriod: useTimePeriod,
      useWeekdays: useWeekdays,
      dateMode: dateMode,
      datePeriod: newDatePeriod,
      datePeriodValue: newDatePeriodValue,
      timePeriod: timePeriod,
      timePeriodValue: timePeriodValue,
      weekdays: weekdays,
      useCrimeCategories: useCrimeCategories,
      useCrimeTypes: useCrimeCategories,
      crimeCategories: crimeCategorySelection,
      crimeTypes: crimeTypeSelection,
      useGroup1: useGroup1,
      useGroup2: useGroup1,
      useSite: useGroup1,
      group1: group1Selection,
      group2: group2Selection,
      site: siteSelection,
      useRelationship: useRelationship,
      relationship: relVal
    }

    ca8debug(`${TAG} onRun result`,filterOptions);
    props.onUseFilters(filterOptions);
    onSetFilterLegend(filterOptions);
    storeFilterOptions(filterOptions);
  }

  const onSetFilterLegend = (filterOptions) => {
    let showCrimeCategory = false;
    let showCrimeType = false;

    let crimeCategoryLegend = ''
    let crimeTypeLegend = ''

    let showGroop1 = false;
    let showGroop2 = false;
    let showSite = false;

    let group1Legend = '';
    let group2Legend = '';
    let siteLegend = '';

    let showDatePeriod = false;
    let showTimePeriod = false;
    let showWeekdays = false;

    let datePeriodLegend = '';
    let timePeriodLegend = '';
    let weekdaysLegend = '';

    ca8debug(`${TAG} onSetFilterLegend`,filterOptions, props.allData);

    if (filterOptions.useCrimeCategories) {
      if (filterOptions.crimeCategories && filterOptions.crimeCategories.length > 0) {
        showCrimeCategory = true;
        crimeCategoryLegend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Crime group:</td>
          <td>{props.allData?.crimeCategories?.filter((x) => filterOptions.crimeCategories.includes(x.value)).map((x) => x.label.trim()).join(', ')}</td>
        </tr>
      }

      if (filterOptions.crimeTypes && filterOptions.crimeTypes.length > 0) {
        showCrimeType = true;
        crimeTypeLegend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Crime type:</td>
          <td>{props.allData?.crimeTypes?.filter((x) => filterOptions.crimeTypes.includes(x.value)).map((x) => x.label.trim()).join(', ')}</td>
        </tr>
      }
    } 

    if (filterOptions.useGroup1) {
      if (filterOptions.group1 && filterOptions.group1.length > 0) {
        showGroop1 = true;
        group1Legend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Group 1:</td>
          <td>{props.allData?.group1?.filter((x) => filterOptions.group1.includes(x.value)).map((x) => x.label.trim()).join(', ')}</td>
        </tr>
      }

      if (filterOptions.group2 && filterOptions.group2.length > 0) {
        showGroop2 = true;
        group2Legend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Group 2:</td>
          <td>{props.allData?.group2?.filter((x) => filterOptions.group2.includes(x.value)).map((x) => x.label.trim()).join(', ')}</td>
        </tr>
      }

      if (filterOptions.site && filterOptions.site.length > 0) {
        showSite = true;
        siteLegend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Site:</td>
          <td>{props.allData?.site?.filter((x) => filterOptions.site.includes(x.value)).map((x) => x.label.trim()).join(', ')}</td>
        </tr>
      }
    } 

    if (filterOptions.useDatePeriod) {
      if (filterOptions.datePeriod && filterOptions.datePeriod.length > 0) {
        showDatePeriod = true;
        datePeriodLegend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Date range:</td>
          <td>{`from ${getProperDate(filterOptions.datePeriod[0],filterOptions.dateMode)} to ${getProperDate(filterOptions.datePeriod[1],filterOptions.dateMode)}`}</td>
        </tr>
      }
    }
    if (filterOptions.useTimePeriod) {
      if (filterOptions.timePeriod && filterOptions.timePeriod.length > 0) {
        showTimePeriod = true;
        timePeriodLegend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Time range:</td>
          <td>{`from ${filterOptions.timePeriod[0]} to ${filterOptions.timePeriod[1]}`}</td>
        </tr>
      }
    }
    if (filterOptions.useWeekdays) {
      if (filterOptions.weekdays && filterOptions.weekdays.length > 0) {
        showWeekdays = true;
        weekdaysLegend = <tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Weekdays:</td>
          <td>{dayValues.filter((x) => filterOptions.weekdays.includes(x.value)).map((x) => x.label.trim()).join(', ')}</td>
        </tr>
      }
    }     
    let rows = [];

    if (showCrimeCategory || showCrimeType) {
      rows.push(<tr style={{fontSize: 14}}><td colSpan="3">Crime groups and types</td></tr>);
      if (showCrimeCategory) {
        rows.push(crimeCategoryLegend);
      }
      if (showCrimeType) {
        rows.push(crimeTypeLegend);
      }
    }

    if (showGroop1 || showGroop2 || showSite) {
      rows.push(<tr style={{fontSize: 14}}><td colSpan="3">Geography</td></tr>);
      if (showGroop1) {
        rows.push(group1Legend);
      }
      if (showGroop2) {
        rows.push(group2Legend);
      }
      if (showSite) {
        rows.push(siteLegend);
      }
    }

    if (showDatePeriod || showTimePeriod || showWeekdays) {
      rows.push(<tr style={{fontSize: 14}}><td colSpan="3">Date / Time</td></tr>);
      if (showDatePeriod) {
        rows.push(datePeriodLegend);
      }
      if (showTimePeriod) {
        rows.push(timePeriodLegend);
      }
      if (showWeekdays) {
        rows.push(weekdaysLegend);
      }
    }

    if (filterOptions.useRelationship) {
      rows.push(<tr><td className="td-empty-short"></td>
          <td className="td-legend-row-title">Relationship:</td>
          <td>{filterOptions.relationship}</td>
        </tr>);
    }     

    const legend = <table>{rows}</table>

    setFilterLegend(legend);
    props.onSetFilterLegend(legend);
    storeFilterOptions(filterOptions);

    const args = {
      message: 'Filter options',
      description: legend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);    

  }
/*
  const onShowLegend = (legend) => {

    const args = {
      message: 'Filter options',
      description: (legend && legend.length) > 0 ? legend : filterLegend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);
    
  }
*/
  const onChangeDOW = (checked) => {
    ca8debug(`onChangeDOW switch to ${checked}`);
    props.onSetShowDOW(checked);
  }

  const onChangeTOD = (checked) => {
    ca8debug(`onChangeTOD switch to ${checked}`);
    props.onSetShowTOD(checked);
  }

  const onSearchCrimeGroup = (value) => {
    ca8debug(`onSearchCrimeGroup`, value);
    const lowValue = value && value.length > 0 ? value.toLowerCase() : '';
    const options = value && value.length > 0 
    ? props.allData.crimeCategories.filter(x => x.label.toLowerCase().includes(lowValue)).map((x, idx) => {
      return <Option key={`category_${idx}`} value={x.value} label={x.label}>{x.label}</Option>
    })
    : props.allData.crimeCategories.map((x, idx) => {
      return <Option key={`category_${idx}`} value={x.value} label={x.label}>{x.label}</Option>
    });            
    setCrimeCategoryOptions(options);     

  }
  
  const onAppLogoClick = () => {
    if (appRole == 2 || appRole == 1) {
      setCurrentPage('settings');
    } else {
      setCurrentPage('dashboard_fixed3');
    }
  }

  return (
    <Sider theme='light' width={270}>

      <AppLogo onClick={onAppLogoClick} />

      <Divider/>

    <Menu theme="light" defaultSelectedKeys={['1']} defaultOpenKeys={['1', '2', '3']} mode="inline" selectable="true">
      {appRole == 2 || appRole == 1
        ? <Menu.Item 
            style={{ paddingLeft: collapsed? 32 : 25 }}
              onClick={() => setCurrentPage('settings')}
              key="left_menu_settings" 
              icon={<SettingOutlined />}>
              <span >Settings</span>
            </Menu.Item>       
        : ''
      }
    <Menu.Item 
      onClick={() => setCurrentPage('dashboard_fixed3')}
      key="left_menu_dashboard3_fixed" 
      color='red'
      icon={<BarChartOutlined />}>
      <span >Dashboard</span>
    </Menu.Item>  
    <Menu.Item 
      onClick={() => setCurrentPage('frequency_all')}
      key="left_menu_frequency_all" 
      icon={<FundOutlined />}>
      <span >Calendar</span>
    </Menu.Item>        

    <Divider/>

      <div style={{ marginLeft: 25, fontWeight: 700 }}>Data Filter</div>

      <div style={{ textAlign: 'center', paddingTop: 20, paddingBottom: 20 }}>
        <Space direction='horizontal'>
            <Button
                style={{ width: 100 }} 
                icon={<CheckOutlined />}
                type='primary' 
                loading={props.loading || props.allDataLoading}
                onClick={() => onRun()}>Apply
            </Button>
            <Button 
                style={{ width: 100 }} 
                icon={<UndoOutlined />}
                type='ghost' 
                loading={props.loading || props.allDataLoading}
                onClick={() => onClearFilters()}>Clear
            </Button>            
        </Space>
      </div>

    <SubMenu 
        key="1" 
        icon={<AlertOutlined style={{ color: getMenuColor(useCrimeCategories) }} />} 
        title={<span style={{ color: getMenuColor(useCrimeCategories) }}>Crime groups and types</span>}
        selectable="true"
    >
        <div style={{ padding: 15 }}>
            <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useCrimeCategories} onChange={onUseCrimeCategoriesChanged}>Use crime groups and types</Checkbox>
                </span>
                {useCrimeCategories
                ? <div style={{display: "block"}}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                      <div className="filter-option-title">Crime Group</div>
                      <Select
                        mode="multiple"
                        allowClear
                        filterOption={true}
                        optionFilterProp="label"
                        style={{ width: '100%' }}
                        placeholder="Please select Crime Group"
                        value={crimeCategorySelection}
                        onChange={onCrimeCategoryChange}
                      >
                        {crimeCategoryOptions}
                      </Select>     
                      {crimeCategorySelection && crimeCategorySelection.includes(1) && relationshipOption && relationshipOption.length > 0
                      ?  <div style={{ padding: 25 }}>
                          <CheckboxGroup options={relationshipOption} value={relationshipSelection} onChange={onRelationshipChange} />
                        </div>                      
                      : '' }

                      <div className="filter-option-title">Crime Type</div>  
                      <Select
                        mode="multiple"
                        allowClear
                        filterOption={true}
                        optionFilterProp="label"
                        style={{ width: '100%' }}
                        placeholder="Please select Crime Type"
                        value={crimeTypeSelection}
                        onChange={onCrimeTypeChange}
                      >
                        {crimeTypeOptions}
                      </Select>
                    </Space>         
                </div>
                : ''}
            </div>
        </div>
    </SubMenu>

    <SubMenu 
        key="2" 
        icon={<GlobalOutlined style={{ color: getMenuColor(useGroup1) }} />} 
        title={<span style={{ color: getMenuColor(useGroup1) }}>Geography</span>}
        selectable="true"
    >
        <div style={{ padding: 25 }}>
            <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useGroup1} onChange={onUseGroup1Changed}>Use geography</Checkbox>
                </span>
                {useGroup1
                ? <div style={{display: "block"}}>
                    <Space direction='vertical' style={{ width: '100%' }}>
                        <div className="filter-option-title">Group 1</div>       
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: '100%' }}
                            placeholder="Please select Group 1"
                            filterOption={true}
                            optionFilterProp="label"
                            value={group1Selection}
                            onChange={onGroup1Change}
                        >
                            {group1Options}
                        </Select>
                        <div className="filter-option-title">Group 2</div>       
                        <Select
                            mode="multiple"
                            allowClear
                            filterOption={true}
                            optionFilterProp="label"
                            style={{ width: '100%' }}
                            placeholder="Please select Group 2"
                            value={group2Selection}
                            onChange={onGroup2Change}
                        >
                            {group2Options}
                        </Select>
                        <div className="filter-option-title">Site</div>       
                        <Select
                            mode="multiple"
                            allowClear
                            filterOption={true}
                            optionFilterProp="label"
                            style={{ width: '100%' }}
                            placeholder="Please select Site"
                            value={siteSelection}
                            onChange={onSiteChange}
                        >
                            {siteOptions}
                        </Select>
                    </Space>         
                </div>
                : ''}
            </div>
        </div>
    </SubMenu>

    <SubMenu 
        key="3" 
        icon={<FieldTimeOutlined style={{ color: getMenuColor(useDatePeriod || useTimePeriod || useWeekdays) }} />} 
        title={<span style={{ color: getMenuColor(useDatePeriod || useTimePeriod || useWeekdays) }}>Date / Time</span>}
        selectable="true"
    >
        <div style={{ padding: 25 }}>
            <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useDatePeriod} onChange={onUseDatePeriodChanged}>Use date range</Checkbox>
                </span>
                {useDatePeriod
                ? <Space direction="vertical">
                    <Select value={dateMode} onChange={onSetaDateMode}>
                        <Option value="date">Date</Option>
                        <Option value="month">Month</Option>
                        <Option value="year">Year</Option>
                    </Select>   
                    <Space direction="vertical" size={12}>
                        <RangePicker picker={dateMode} onChange={onDatePeriodChange} value={datePeriodValue}/>
                    </Space>
                    </Space>   
                : ''}
            </div>
            <div style={{ paddingTop: 10 }}>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useTimePeriod} onChange={onUseTimePeriodChanged}>Use time range</Checkbox>
                </span>
                {useTimePeriod
                ? <RangePicker picker="time" onChange={onTimePeriodChange} value={timePeriodValue}/> 
                : ''}
            </div>
            <div style={{ paddingTop: 10 }}>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useWeekdays} onChange={onUseWeekdaysChanged}>Use days of week</Checkbox>
                </span>
                {useWeekdays
                ? <Select
                      mode="multiple"
                      allowClear
                      style={{ width: '100%' }}
                      placeholder="Please select weekdays"
                      value={weekdays}
                      onChange={onDayChange}
                  >
                      {weekdayOptions}
                  </Select> 
                : ''}
            </div>
        </div>
    </SubMenu>
{/*
    <SubMenu 
        key="4" 
        icon={<CommentOutlined style={{ color: getMenuColor(useRelationshipStranger || useRelationshipNonStranger || useRelationshipUnknown) }} />} 
        title={<span style={{ color: getMenuColor(useRelationshipStranger || useRelationshipNonStranger || useRelationshipUnknown) }}>Relationship</span>}
        selectable="true"
    >
        <div style={{ padding: 25 }}>
            <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useRelationshipStranger} onChange={(e) => setUseRelationshipStranger(e.target.checked)}>Stranger</Checkbox>
                </span>
            </div>
            <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useRelationshipNonStranger} onChange={(e) => setUseRelationshipNonStranger(e.target.checked)}>Non-Stranger</Checkbox>
                </span>
            </div>
            <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={useRelationshipUnknown} onChange={(e) => setUseRelationshipUnknown(e.target.checked)}>Unknown</Checkbox>
                </span>
            </div>
        </div>
    </SubMenu>
*/}
{/*
    <SubMenu 
        key="show_hide_charts" 
        icon={<BarChartOutlined style={{ color: 'black' }} />} 
        title={<span style={{ color: 'black' }}>Show / hide charts</span>}
        selectable="true"
    >

      <div style={{ paddingTop: 25 }}>
        <div style={{ marginBottom: 15 }}>
          <span className="filter-option-title-inline">Show Day of Week</span>
          <Switch onChange={onChangeDOW} />
        </div>
        <div>
          <span className="filter-option-title-inline">Show Time of Day</span>
          <Switch onChange={onChangeTOD} />
        </div>
      </div>

    </SubMenu>
*/}      
    
    </Menu>

    </Sider>
  );
}

export default FilterOptions2;
