import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend,setTableVisibility,getThresholdReferences,ca8debug,getFillForCategory,sortCrimeCategories } from './Utils';

import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, message, Checkbox, Menu } from 'antd';
import { AppContext } from "../contexts/AppContext";

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined
} from '@ant-design/icons';
import ChartDataDrawer from './drawers/ChartDataDrawer';

const { Option } = Select;
const { SubMenu } = Menu;

const CrimeChartFixed = (props) => {
    const TAG = 'CrimeChartFixed';

    const exportRef = useRef();

    ca8debug(`${TAG} props`, props);

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  

    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('category');
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(true);

    const [incidentData, setIncidentData] = React.useState([]);

    const [crimeTypes, setCrimeTypes] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');

    const [chartTableData, setChartTableData] = React.useState([]);
    const [chartTableColumns, setChartTableColumns] = React.useState([]);
    const [chartTableTitle, setChartTableTitle] = React.useState('');
    const [chartTableFilter, setChartTableFilter] = React.useState(undefined);
    const [chartTableMode, setChartTableMode] = React.useState('');    
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    const [cellCount, setCellCount] = React.useState(0);

    const columns = [
      {
        title: 'Crime Group',
        dataIndex: 'category',
        key: 'category',
        sorter: true,
        filters: categoryFilter,
        filteredValue: allFilters.category || null,
        onFilter: (value, record) => {
          return record.category.includes(value);
        },      
      },
      {
        title: 'Crime Type',
        dataIndex: 'crime',
        key: 'crime',
        sorter: true,
        filters: crimeFilter,
        filteredValue: allFilters.crime || null,
        onFilter: (value, record) => {
          return record.crime.includes(value);
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
      },
    ];
  
    ca8debug(`${TAG} started`);
  
    const tableRef = React.useRef();
  
    React.useEffect(() => {
  
      const filter = props.filter;

      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData && filter) {
        setLoading(true);
        setLoadingMessage('Loading ...');
        setDrawerVisible(false);

      apiClient.post(`api/clients/${clientId}/chart/crime`, filter)
        .then(response => {
          ca8debug(`${TAG} data response`, response.data);
          
              // parse results
              //value,crime,category,cyear,cmonth,weekday,chour
              //console.log('results', results)
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'category': x.category, 
                    'crime': x.crime, 
                    'value': x.value, 
                }
              });            
              ca8debug('dt', dt);

              updateFilters(dt, chartMode);

              setData(dt);
 
              // set initial chart data
              setChartData(dt);
  
              setLoading(false);
        });

      }

    }, [props.filter]);
 
  const updateFilters = (dt, mode) => {
    // read category filter
    const uniqueCategory = getUniqueCrimeCategory(); // [...new Set(dt.map(x => x.category))];
    ca8debug('uniqueCategory', uniqueCategory);
    setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}})); 
    
    // read crime filter
    const uniqueCrime = getUniqueCrimeType(); // [...new Set(dt.map(x => x.crime))];
    ca8debug('uniqueCrime', uniqueCrime);
    setCrimeFilter(uniqueCrime.map(x => { return {'text': x, 'value': x}}));   

    // calculate cell count
    let cnt = uniqueCategory ? uniqueCategory.length : 0;
    if (mode == 'crime') {
        cnt = uniqueCrime ? uniqueCrime.length : 0;
    }
    //console.log(`${TAG} updateFilters cnt`,mode, cnt)
    //console.log(`${TAG} updateFilters unique`,uniqueCategory, uniqueCrime)

    setCellCount(cnt);

  }

  const filterUsingAllFilters = () => {
    ca8debug(`${TAG} filterUsingAllFilters data`, data, allFilters);
    let result = data.slice();
    if (allFilters.category) {
      result = result.filter(x => allFilters.category.includes(x.category));
    }
    if (allFilters.crime) {
      result = result.filter(x => allFilters.crime.includes(x.crime));
    }
    ca8debug(`${TAG} filterUsingAllFilters result`, result);
    return result;
  }

  const exportToPdf = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPdf data`, data, allFilters);

    setLoadingMessage('Export to PDF ...');
    setLoading(true);

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPdf image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Crimes / Crime Groups');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));

    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();

    const formData = new FormData();

    formData.append("chart", image);
    formData.append("title", 'Crimes / Crime Groups');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));

    apiClient.post(`api/clients/${clientId}/export`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);
        setLoading(false);

      });    
  };

  const exportToPrint = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPrint data`, data, allFilters);
  
    setLoadingMessage('Prepare to print ...');
    setLoading(true);

    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPrint image`, image);
  
    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();
  
    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Crimes / Crime Groups');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
  
      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);

    }).catch((error) => {
        setLoading(false);
        message.error(error);
    });    
  };

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  

  const onTableChange = (pagination, filters, sorter, mode) => {
    ca8debug(`${TAG} onTableChange filters`, filters);
    let newData = data.slice();
    if (filters.category) {
      newData = newData.filter(x => filters.category.includes(x.category));
    }
    if (filters.crime) {
      newData = newData.filter(x => filters.crime.includes(x.crime));
    }      

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }

    updateFilters(newData, mode ? mode : chartMode);
    setAllFilters(filters);
    setChartData(newData);
    
  }
  
  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData,'category');
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('category');
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, chartMode, allFilters);
    // change chart mode
    if (e.activePayload && e.activePayload.length == 1) {
        const payloadData = e.activePayload[0];
        if (payloadData && payloadData.payload) {
          const newChartMode = chartMode == 'category' ? 'crime' : 'crime';
          const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;
  
          if (chartMode == 'category') {
            newAllFilters['category'] = [payloadData.payload.name];
          } else if (chartMode == 'crime') {
            //newAllFilters['crime'] = [payloadData.payload.name];
          } 
  
          setChartMode(newChartMode);
          onTableChange(null, newAllFilters, null, newChartMode);
        }
      }
  
  }

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnDrillUp`, allFilters);
    // change chart mode
    if (chartMode != 'category') {
        const newChartMode = 'category';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (newChartMode == 'category') {
          newAllFilters['category'] = undefined;
          newAllFilters['crime'] = undefined;
        }
        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null, newChartMode);
        
    }
  }  

  const getUniqueCrimeCategory = () => {
    let result = [];
    if (props.filter.crimeCategories && props.filter.crimeCategories.length > 0) {
      const arr = props.filter.crimeCategories.split(',').map(x => +x);
      if (arr && arr.length > 0) {
        result = props.allData.crimeCategories.filter(x => arr.includes(x.value)).map(x => x.label);
      }
    } else {
      result = props.allData.crimeCategories.map(x => x.label);
    }
    ca8debug(`${TAG} getUniqueCrimeCategory`, result, props.filter, props.allData.crimeCategories);
    return result;
  }  

  const getUniqueCrimeType = () => {
    let result = [];
    const category = props.allData.crimeCategories.find(x => x.label == allFilters.category);
    if (category) {
      if (props.filter.crimeType && props.filter.crimeType.length > 0) {
        const arr = props.filter.crimeType.split(',').map(x => +x);
        if (arr && arr.length > 0) {
          result = props.allData.crimeTypes.filter(x => arr.includes(x.value) && x.parent_id == category.value).map(x => x.label);
        }
      } else {
        result = props.allData.crimeTypes.filter(x => x.parent_id == category.value).map(x => x.label);
      }
    } else {
      result = props.allData.crimeTypes.map(x => x.label);
    }
    ca8debug(`${TAG} getUniqueCrimeType`, result, props.filter, props.allData.crimeTypes, allFilters, category);
    return result;
  }  

  const getChart = () => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, chartData);
    // get unique values
    const uniqueCategory = sortCrimeCategories(getUniqueCrimeCategory()); // [...new Set(crimeTypes.map(x => x.category))];
    
    if (chartMode == 'category') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueCategory.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.category === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);

    } else {

      const uniqueCrime = getUniqueCrimeType(); // [...new Set(crimeTypes.filter(x => allFilters.category.includes(x.category)).map(x => x.crime))];

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueCrime.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.crime === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);

    } 
  }    

  const calculateTableData = () => {

    const td = {};
    td.items = [];
    let idx = 0;
    let incidx = 0;

    if (chartMode == 'category') {

      const uniqueCategory = sortCrimeCategories(getUniqueCrimeCategory());

      td.columns = [
        { title: 'Crime group',dataIndex: 'name',key: 'name' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];
      uniqueCategory.forEach(x => {
        idx += 1;
        const tr1 = {};
        tr1.id = idx;
        tr1.name = x;
        tr1.value = chartData.filter(y => ((y.category === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        tr1.items = props.data.filter(y => ((y.crime_category == x)));

        td.items.push(tr1);
      });

    } else {

      const uniqueCrime = getUniqueCrimeType();

      td.columns = [
        { title: 'Crime',dataIndex: 'name',key: 'name' },
        { title: 'Value', dataIndex: 'value', key: 'value' }
      ];
      uniqueCrime.forEach(x => {
        idx += 1;
        const tr1 = {};
        tr1.id = idx;
        tr1.name = x;
        tr1.value = chartData.filter(y => ((y.crime === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        tr1.items = props.data.filter(y => ((y.crime_type == x && y.crime_category == allFilters.category)));

        td.items.push(tr1);
      });
    }     

    ca8debug(`${TAG} calculateTableData ${idx} / ${incidx}`,chartData, td, props.data, allFilters);
    return td;

 };

  const getChartItem = (data) => {
    //const thresholds = calculateThresholds(data);
    //console.log(`${TAG} getChartItem chartMode ${chartMode} allFilters`,allFilters);
    const cellColor = allFilters && allFilters.category && allFilters.category.length > 0 ? getFillForCategory(allFilters.category[0]) : "#7B778C";
// getFillForCategory(allFilters["category"])
    const angle = chartMode == 'category' ? 0 : 270;
    const tickMargin = chartMode == 'category' ? 0 : 60;
    const height = chartMode == 'category' ? 30 : 100;

    return <BarChart
    isAnimationActive={false}
      onClick={OnChartClick}
      width={550}
      data={data}
      layout="vertical"
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="2 2" vertical={false} />
 
      <XAxis type="number" />
      {/* <YAxis type="category" tick={{ fontSize: 10, width: chartMode == 'crime' ? 250 : 150 }} width={chartMode == 'crime' ? 200 : 100} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/> */}
      <YAxis type="category" orientation="right" tickSize={40} tickLine={{ stroke: 'lightgray', strokeDasharray: "2 2", strokeWidth: "1" }} tick={{ fontSize: 14, width: 250, dy: 0 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>

      {chartMode == "category"
      ? <Bar barSize={20} isAnimationActive={false}  dataKey='value' layout="vertical" >
        <LabelList dataKey="value" position="right" fill="#000000" padding={{ left: 0, right: 0 }} margin={{ left: 0, right: 0 }}/>
        {
          data.map((x, idx) => (
            <Cell key={`cell-${idx}`} fill={getFillForCategory(x.name)} />
          ))
        }        
      </Bar>     
      : <Bar barSize={20} isAnimationActive={false} fill={cellColor} dataKey='value' layout="vertical" >
      <LabelList dataKey="value" position="right" fill="#000000" padding={{ left: 0, right: 0 }} margin={{ left: 0, right: 0 }}/>
    </Bar>}     
  
      {getThresholdReferences(data, true, false)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />

    </BarChart>    
  }

  const CustomLegend = () => {
    if (chartMode == 'category') {
      return FiltersLegend(allFilters, resetFilters, null);
    }
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const onShowData = () => {

    const dt = calculateTableData();

    setChartTableMode('time');
    setChartTableTitle('Crimes / Crime Groups');
    setChartTableFilter(allFilters);
    setChartTableData(dt);
    setChartTableColumns(columns);
    setDrawerVisible(true); 

  }

  const onDrawerClose = () => {
    setChartTableData([]);
    setDrawerVisible(false);
    props.selectChart('');
  }

  return (
    <div id="divCrimeChart" className="chart3-container" style={{ backgroundColor: props.selected ? '#defbee' : 'transparent'}}>
      <table>
        <tr>

      {drawerVisible
      ? ''
      : <td className="chart3-col-left">
        <div className="chart3-col-title">Crimes / Crime Groups</div>
        <div>
          <div className="chart3-col-title-row">
          
            <div>
                <Menu mode="vertical" style={{width: 200, border: 'none' }}>
                  <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
                    <Menu.Item disabled={loading || props.loading} icon={<FilePdfOutlined />} onClick={() => {
                      exportToPdf(exportRef.current, "test");
                    }} key="1">PDF</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
                  </SubMenu>
                  <Menu.Item 
                    disabled={loading || props.loading}
                    icon={<TableOutlined />} 
                    onClick={() => onShowData()}>
                    Show data
                  </Menu.Item>
                </Menu>
              </div>
          
          </div>
        </div>
      </td>
      }

    <td className="chart3-col-right">
      <Spin tip={loadingMessage} spinning={loading}>
      {loading
        ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
        : data && data.length > 0
          ? <div ref={exportRef} style={{ width: drawerVisible ? 'calc(100vh - 200px)' : '100%' }}>
              <CustomLegend/>
              <ResponsiveContainer width='100%' height={cellCount > 15 ? cellCount * 25 : 400}>
              {getChart()}
              </ResponsiveContainer>
            </div>
          : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
      }
    </Spin>
    </td>
      </tr>
    </table>

      <ChartDataDrawer
            visible={drawerVisible} 
            onClose={onDrawerClose} 
            data={chartTableData} 
            chartData={chartTableData} 
            mode={chartTableMode} 
            title={chartTableTitle} 
            filter={chartTableFilter} 
            columns={chartTableColumns}
            />
    </div>    
  );
}

export default CrimeChartFixed;