import React, { useContext, useState } from "react";
import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN,
} from "../constants/AuthStatus";
import AuthNotLoggedIn from "./AuthNotLoggedIn";
import { sortAsc, sortDesc, FiltersLegend, emptyDiv, getMenuColor, ca8debug } from './Utils';
//import AuthSignup from "./AuthSignup";
//import AuthLogin from "./AuthLogin";
import { AppContext } from "../contexts/AppContext";
import { Layout, Menu, PageHeader, Button, Divider, Space } from 'antd';
//import { useCookies, Cookies } from 'react-cookie';

import {
    SettingOutlined,
    BarChartOutlined,
    DatabaseOutlined,
    AlertOutlined,
    TeamOutlined,
    UserOutlined,
    CalendarOutlined,
    FundOutlined,
  } from '@ant-design/icons';
import AppHeader from './AppHeader';
import AppFooter from './AppFooter';
import SettingsClients from "./settings/SettingsClients";
import SettingsUsers from "./settings/SettingsUsers";
import SettingsImport from "./settings/SettingsImport";
import SettingsIncidents from "./settings/SettingsIncidents";
import SettingsPopulation from "./settings/SettingsPopulation";
import SettingsRegions from "./settings/SettingsRegions";
import SettingsSites from "./settings/SettingsSites";
import SettingsCrimeTypes from "./settings/SettingsCrimeTypes";
import PageTitle from './PageTitle'
import AppLogo from "./AppLogo";

  
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;


const SettingsPage = (props) => {
  const TAG = 'SettingsPage';

  const [currentSettingsPage, setCurrentSettingsPage] = useState('clients');
  const appContext = useContext(AppContext);
  const { 
    apiClient, 
    authStatus, 
    errorMessage, 
    setCurrentPage, 
    appRole,
    logout,
    // setAppRole,
    appClientId,
    // setAppClientId,
    appCurrentClient, 
    setAppCurrentClient,
    userId

  } = appContext;
  const showNotLoggedIn = authStatus === NOT_LOGGED_IN ? "" : "hidden";
  const showLoginForm = authStatus === LOG_IN_FORM ? "" : "hidden";
  const showSignupForm = authStatus === SIGN_UP_FORM ? "" : "hidden";
  const showLoggedIn = authStatus === LOGGED_IN ? "" : "hidden";
  //const [currentClient, setCurrentClient] = useState(undefined);
  //const [cookie, setCookie] = useCookies(['ca8']);

  React.useEffect((props) => {
        // ca8debug(`${TAG} useEffect response`, response);
        ca8debug(`${TAG} useEffect appRole`, appRole);
        ca8debug(`${TAG} useEffect appClientId`, appClientId);
        ca8debug(`${TAG} useEffect appCurrentClient`, appCurrentClient);
  }, []);

  const getComponent = () => {
    ca8debug(`${TAG} getComponent`, currentSettingsPage);
/*
    if (currentSettingsPage == 'clients') {
      return <SettingsClients onSelectClient={onSetClient}/>
    } else {
      return <SettingsCrimeTypes/>
    }
*/
    switch (currentSettingsPage) {
      case 'clients':
        return <SettingsClients onSelectClient={onSetClient}/>
      case 'crimeTypes':
        return <SettingsCrimeTypes client={appCurrentClient ? appCurrentClient : { id: 0 }}/>
      case 'incidents':
        return <SettingsIncidents client={appCurrentClient} />
      //case 'population':
      //  return <SettingsPopulation client={currentClient} />
      case 'regions':
        return <SettingsRegions client={appCurrentClient} />
      case 'sites':
        return <SettingsSites client={appCurrentClient} />
      case 'users':
        return <SettingsUsers client={appCurrentClient} />
      case 'import':
        return <SettingsImport client={appCurrentClient} />
        
      default:
        return <div>Unknown component</div>
    }
  }

  const onSetClient = (client, setPage = true) => {
    ca8debug(`${TAG} onSetClient`, client);
    // setCurrentClient(client);
    // setAppClientId(client.id);
    setAppCurrentClient(userId, client);

    if (setPage) {
      setCurrentSettingsPage('users');
    }
  }


  const getSiteAdminMenu = () => {
    return <Menu theme="light" defaultSelectedKeys={['left_menu_clients']} mode="inline" selectable="true">
      <Menu.Item 
        onClick={() => setCurrentSettingsPage('clients')}
        key="left_menu_clients" 
        icon={<TeamOutlined style={{ color: getMenuColor(currentSettingsPage == 'clients') }}/>}>
        <span style={{ color: getMenuColor(currentSettingsPage == 'clients') }}>Clients</span>
      </Menu.Item>  
      <Menu.Item 
        key="left_menu_crime_types" 
        onClick={() => setCurrentSettingsPage('crimeTypes')}
        icon={<AlertOutlined style={{ color: getMenuColor(currentSettingsPage == 'crimeTypes') }}/>}>
        <span style={{ color: getMenuColor(currentSettingsPage == 'crimeTypes') }}>Crime Types</span>
      </Menu.Item> 
{/*                 
      <Divider/>
      <Menu.Item 
        onClick={() => logout()}
        key="left_menu_logout" 
        icon={<LogoutOutlined />}>
        <span >Logout</span>
      </Menu.Item>        
*/}
    </Menu>
  }

  const getClientAdminMenu = () => {
    ca8debug(`${TAG} getClientAdminMenu`, currentSettingsPage );
    return <Menu theme="light" defaultSelectedKeys={['left_menu_users']} mode="inline" selectable="true">
{/*      
      <Menu.Item 
        onClick={() => setCurrentPage('dashboard')}
        key="left_menu_dashboard" 
        icon={<BarChartOutlined />}>
        <span >Dashboard</span>
      </Menu.Item>      
*/}
{/*        
      <Menu.Item 
        onClick={() => setCurrentPage('dashboard_fixed')}
        key="left_menu_dashboard_fixed" 
        color='red'
        icon={<BarChartOutlined />}>
        <span >Dashboard</span>
      </Menu.Item>     
      <Menu.Item 
        onClick={() => setCurrentPage('dashboard_fixed2')}
        key="left_menu_dashboard2_fixed" 
        color='red'
        icon={<BarChartOutlined />}>
        <span >Dashboard (two columns)</span>
      </Menu.Item>      
*/}          
      <Menu.Item 
        onClick={() => setCurrentPage('dashboard_fixed3')}
        key="left_menu_dashboard3_fixed" 
        color='red'
        icon={<BarChartOutlined />}>
        <span >Dashboard</span>
      </Menu.Item>        
{/*      
      <Menu.Item 
        onClick={() => setCurrentPage('calendar')}
        key="left_menu_calendar" 
        icon={<CalendarOutlined />}>
        <span >Frequency - Calendar</span>
      </Menu.Item>        
      <Menu.Item 
        onClick={() => setCurrentPage('frequency')}
        key="left_menu_frequency" 
        icon={<FundOutlined />}>
        <span >Frequency - Chart</span>
      </Menu.Item>     
*/}
    <Menu.Item 
      onClick={() => setCurrentPage('frequency_all')}
      key="left_menu_frequency_all" 
      icon={<FundOutlined />}>
      <span >Calendar</span>
    </Menu.Item>           

      <Divider/>
      <Menu.Item 
        onClick={() => setCurrentSettingsPage('users')}
        key="left_menu_users" 
        icon={<UserOutlined style={{ color: getMenuColor(currentSettingsPage == 'users') }}/>}>
        <span style={{ color: getMenuColor(currentSettingsPage == 'users') }}>Users</span>
      </Menu.Item>        
      <SubMenu key="left_sub_menu_data" icon={<DatabaseOutlined />} title="Data">
        <Menu.Item key="left_sub_menu_sites" onClick={() => setCurrentSettingsPage('sites')}>Sites</Menu.Item>
        <Menu.Item key="left_sub_menu_regions" onClick={() => setCurrentSettingsPage('regions')}>Regions</Menu.Item>
        <Menu.Item key="left_sub_menu_incidents" onClick={() => setCurrentSettingsPage('incidents')}>Incidents</Menu.Item>
        <Menu.Item key="left_sub_menu_crime_types" onClick={() => setCurrentSettingsPage('crimeTypes')}>Crime Types</Menu.Item>

        {/* <Menu.Item key="left_sub_menu_population" onClick={() => setCurrentSettingsPage('population')}>Population</Menu.Item> */}

        <Menu.Item key="left_sub_menu_db" onClick={() => setCurrentSettingsPage('import')}>DB</Menu.Item>
      </SubMenu>
{/*
      <Divider/>
      <Menu.Item 
              onClick={() => logout()}
              key="left_menu_logout" 
              icon={<LogoutOutlined />}>
              <span >Logout</span>
      </Menu.Item>        
*/}      
    </Menu>  
  }

  const getClientHeader = () => {
    ca8debug(`${TAG} getClientHeader`, appCurrentClient);
    return appCurrentClient
    ? <PageHeader
      className="site-page-header"
      title={appCurrentClient.name}
      subTitle={appCurrentClient.address}
      extra={[
        <Button key="select_another_client" onClick={() => {
          // localStorage.removeItem("appCurrentClient");
          setCurrentSettingsPage('clients');
        }}>Select another Client</Button>,
      ]}      
    />
    : ''
  }

  const getMenu = () => {
    ca8debug(`${TAG} getMenu`, appCurrentClient);
    return appCurrentClient ? getClientAdminMenu() : getSiteAdminMenu();
  }

  const onAppLogoClick = () => {
    if (appRole == 2 || appRole == 1) {
      setCurrentPage('settings');
      setCurrentSettingsPage('clients');
    } else {
      setCurrentPage('dashboard_fixed3');
    }
  }  

  return (
    <Layout style={{ minHeight: '100vh' }}>
    <Sider theme='light' width={270}>
      <AppLogo onClick={onAppLogoClick} />
      <Divider/>

      {getMenu()}

    </Sider>

      
    <Layout>
      <PageTitle
        icon={<SettingOutlined />}
        title="Settings"
        clientButton={appRole == 2 &&
          <Button key="select_another_client" size="small" onClick={() => {
            // localStorage.removeItem("appCurrentClient");
            setCurrentSettingsPage('clients');
          }}>Select another Client</Button>
        }      
      />      

      <Content style={{ margin: '0 5px' }}>

      <div>
        {getComponent()}
      </div>
      
      </Content>
      </Layout>
      </Layout>
  );

};

export default SettingsPage;
