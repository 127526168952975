import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend,setTableVisibility,getThresholdReferences,ca8debug,getFillForCategory } from './Utils';

import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, message, Checkbox, Menu } from 'antd';
import { AppContext } from "../contexts/AppContext";

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined
} from '@ant-design/icons';

const { Option } = Select;
const { SubMenu } = Menu;

const CrimeChart = (props) => {
    const TAG = 'CrimeChart';

    const exportRef = useRef();

    ca8debug(`${TAG} props`, props);

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  

    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('category');
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(true);

    const [incidentData, setIncidentData] = React.useState([]);

    const [crimeTypes, setCrimeTypes] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');
    const [exportAggregatedData, setExportAggregatedData] = React.useState(true);

    const columns = [
      {
        title: 'Crime Group',
        dataIndex: 'category',
        key: 'category',
        sorter: true,
        filters: categoryFilter,
        filteredValue: allFilters.category || null,
        onFilter: (value, record) => {
          return record.category.includes(value);
        },      
      },
      {
        title: 'Crime Type',
        dataIndex: 'crime',
        key: 'crime',
        sorter: true,
        filters: crimeFilter,
        filteredValue: allFilters.crime || null,
        onFilter: (value, record) => {
          return record.crime.includes(value);
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
      },
    ];
  
    ca8debug(`${TAG} started`);
  
    const tableRef = React.useRef();
  
    React.useEffect(() => {
  
      const filter = props.filter;
      setLoading(true);
      setLoadingMessage('Loading ...');

      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      apiClient.post(`api/clients/${clientId}/chart/crime?startDate=${filter.startDate}&endDate=${filter.endDate}&` +
      `startTime=${filter.startTime}&endTime=${filter.endTime}&dayOfWeek=${filter.dayOfWeek}&` +
      `crimeCategory=${filter.crimeCategory}&crimeType=${filter.crimeType}&group1=${filter.group1}&` +
      `group2=${filter.group2}&site=${filter.site}`)
        .then(response => {
          ca8debug(`${TAG} data response`, response.data);
          
              // parse results
              //value,crime,category,cyear,cmonth,weekday,chour
              //console.log('results', results)
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'category': x.category, 
                    'crime': x.crime, 
                    'value': x.value, 
                }
              });            
              ca8debug('dt', dt);

              updateFilters(dt);

              setData(dt);
              // set initial chart data
              setChartData(dt);
  
              setLoading(false);
        });

        apiClient.get(`api/clients/${clientId}/chart/ctypes`)
        .then(response => {
          ca8debug(`${TAG} crime_types data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'category': x.crime_category_name, 
                    'crime': x.name, 
                }
              });            
              ca8debug(`${TAG} crime_types dt`, dt);
              setCrimeTypes(dt);
          
        });

    }, [props.refreshed]);
 
  const updateFilters = (dt) => {
    // read category filter
    const uniqueCategory = [...new Set(dt.map(x => x.category))];
    ca8debug('uniqueCategory', uniqueCategory);
    setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));            
    // read crime filter
    const uniqueCrime = [...new Set(dt.map(x => x.crime))];
    ca8debug('uniqueCrime', uniqueCrime);
    setCrimeFilter(uniqueCrime.map(x => { return {'text': x, 'value': x}}));   
  }

  const onExportAggregatedDataChanged = (e) => {
    setExportAggregatedData(e.target.checked);
  }  

  const filterUsingAllFilters = () => {
    ca8debug(`${TAG} filterUsingAllFilters data`, data, allFilters);
    let result = data.slice();
    if (allFilters.category) {
      result = result.filter(x => allFilters.category.includes(x.category));
    }
    if (allFilters.crime) {
      result = result.filter(x => allFilters.crime.includes(x.crime));
    }
    ca8debug(`${TAG} filterUsingAllFilters result`, result);
    return result;
  }

  const exportToPdf = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPdf data`, data, allFilters);

    setLoadingMessage('Export to PDF ...');
    setLoading(true);

    const filteredData = filterUsingAllFilters();

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPdf image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", exportAggregatedData);
    formData.append("data", JSON.stringify(filteredData));

    apiClient.post(`api/clients/${clientId}/pdfcrime`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.pdf'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const filteredData = filterUsingAllFilters();

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    var formData = new FormData();
    formData.append("chart", image);
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    formData.append("is_aggregated", exportAggregatedData);
    formData.append("data", JSON.stringify(filteredData));

    //formData.append("data", JSON.stringify(props.data));

    apiClient.post(`api/clients/${clientId}/xlscrime`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);

      });    
  };

  const onTableChange = (pagination, filters, sorter) => {
    ca8debug(`${TAG} onTableChange filters`, filters);
    let newData = data.slice();
    if (filters.category) {
      newData = newData.filter(x => filters.category.includes(x.category));
    }
    if (filters.crime) {
      newData = newData.filter(x => filters.crime.includes(x.crime));
    }      

    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }

    updateFilters(newData);
    setAllFilters(filters);
    setChartData(newData);
  }
  
  const resetFilters = () => {
    let newData = data.slice();
    updateFilters(newData);
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('category');
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters);
    // change chart mode
    if (e.activePayload && e.activePayload.length == 1) {
        const payloadData = e.activePayload[0];
        if (payloadData && payloadData.payload) {
          const newChartMode = chartMode == 'category' ? 'crime' : 'crime';
          const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;
  
          if (chartMode == 'category') {
            newAllFilters['category'] = [payloadData.payload.name];
          } else if (chartMode == 'crime') {
            //newAllFilters['crime'] = [payloadData.payload.name];
          } 
  
          setChartMode(newChartMode);
          onTableChange(null, newAllFilters, null);
        }
      }
  
  }

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnDrillUp`, allFilters);
    // change chart mode
    if (chartMode != 'category') {
        const newChartMode = 'category';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        if (newChartMode == 'category') {
          newAllFilters['category'] = undefined;
          newAllFilters['crime'] = undefined;
        }
        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
        
    }
  }  

  const getChart = () => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, chartData);
    // get unique values
    const uniqueCategory = [...new Set(crimeTypes.map(x => x.category))];
    
    if (chartMode == 'category') {

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueCategory.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.category === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);

    } else {

      const uniqueCrime = [...new Set(crimeTypes.filter(x => allFilters.category.includes(x.category)).map(x => x.crime))];

      let result = [];
      let idx = 0;
      let maxVal = 0;
      uniqueCrime.forEach(x => {
        let res = {};          
        res['id'] = idx;
        res['name'] = x;
        let val = chartData.filter(y => ((y.crime === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        res['value'] = val;
        result.push(res);
        idx++;
      });
      return getChartItem(result);

    } 
  }    

  const tickFormatter = (value) => {
    if (value && value.length > 0) {
      const limit = 15; // put your maximum character
      if (value.length < limit) return value;
      return `${value.substring(0, limit)}...`;
    }
    return '';
 };

  const getChartItem = (data) => {
    //const thresholds = calculateThresholds(data);
    //console.log(`${TAG} getChartItem chartMode ${chartMode} allFilters`,allFilters);
    const cellColor = allFilters && allFilters.category && allFilters.category.length > 0 ? getFillForCategory(allFilters.category[0]) : "#7B778C";
// getFillForCategory(allFilters["category"])
    const angle = chartMode == 'category' ? 0 : 270;
    const tickMargin = chartMode == 'category' ? 0 : 60;
    const height = chartMode == 'category' ? 30 : 100;

    return <BarChart
    isAnimationActive={false}
      onClick={OnChartClick}
      width={550}
      height={300}
      data={data}
      layout="vertical"
      margin={{
      top: 20,
      right: 30,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="3 3" />
 
      <XAxis type="number" />
      <YAxis type="category" tick={{ fontSize: 10, width: 250 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>

      {chartMode == "category"
      ? <Bar isAnimationActive={false}  dataKey='value' layout="vertical" >
        <LabelList dataKey="value" position="right" fill="#000000" padding={{ left: 0, right: 0 }} margin={{ left: 0, right: 0 }}/>
        {
          data.map((x, idx) => (
            <Cell key={`cell-${idx}`} fill={getFillForCategory(x.name)} />
          ))
        }        
      </Bar>     
      : <Bar isAnimationActive={false} fill={cellColor} dataKey='value' layout="vertical" >
      <LabelList dataKey="value" position="right" fill="#000000" padding={{ left: 0, right: 0 }} margin={{ left: 0, right: 0 }}/>
    </Bar>}     
  
      {getThresholdReferences(data, true)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />

    </BarChart>    
  }

  const expandable = { expandedRowRender: record => <p>{record.description}</p> };

  const showDetails = (record) => {

    const filteredData = props.data.filter(x => 
        x.crime_category == record.category &&
        x.crime_type == record.crime 
        );

        ca8debug(`${TAG} showDetails`, record, filteredData)
    
    const cols = [
      { title: 'id',dataIndex: 'incident_id',key: 'incident_id' },
      { title: 'Site',dataIndex: 'site_name',key: 'site_name' },
      { title: 'Crime Type',dataIndex: 'crime_type',key: 'crime_type' },
      { title: 'Date',dataIndex: 'incident_date',key: 'incident_date' },
      { title: 'Time',dataIndex: 'incident_time',key: 'incident_time' },
      { title: 'Location',dataIndex: 'incident_location',key: 'incident_location' },
      { title: 'Victim',dataIndex: 'incident_victim',key: 'incident_victim' },
      { title: 'Comments',dataIndex: 'incident_comments',key: 'incident_comments' },
      { title: 'Offense Report',dataIndex: 'incident_offence_report_no',key: 'incident_offence_report_no' },
  ];

    return <Table 
        size="small"
        key={`table_${record.category}`}
        columns={cols} 
        dataSource={filteredData}
        />
  }

  const CustomLegend = () => {
    if (chartMode == 'category') {
      return FiltersLegend(allFilters, resetFilters, null);
    }
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const showHideTable = () => {
    const isShowTable = !showTable;

    setShowTable(isShowTable);
  }    

  return (
    <div id="divCrimeChart" className="chart-container">
      {loading || props.loading
        ? ''
        :  <Menu style={{ position: 'absolute', top: 10, right: 10, width: 150 }} mode="vertical">
        <SubMenu key="sub1" icon={<PrinterOutlined />} title="Export">
            <Checkbox checked={exportAggregatedData} onChange={onExportAggregatedDataChanged} style={{margin: 15}}>
              Export aggregated data
            </Checkbox>
            <Menu.Item icon={<FilePdfOutlined />} onClick={() => {
              exportToPdf(exportRef.current, "test");
            }} key="1">PDF</Menu.Item>
            <Menu.Item icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
          </SubMenu>
          </Menu>
        
      }       
    <h2>Crimes / Crime Groups</h2>
    <Spin tip={loadingMessage} spinning={loading}>

      {loading
        ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
        : data && data.length > 0
          ? <div ref={exportRef}>
              <ResponsiveContainer width='100%' height={400}>
              {getChart()}
              </ResponsiveContainer>
              <CustomLegend/>
            </div>
          : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
        }

      <div style={{ marginTop: 50, marginBottom: 20, position: 'relative' }}>
        <Button icon={<TableOutlined />} loading={props.loading} size='small' type="ghost" onClick={() => showHideTable()}>{ showTable ? 'Hide data' : 'Show data' }</Button>
      </div>

      {showTable
      ? <Table 
        ref={tableRef} 
        rowKey='id'
        columns={columns} 
        dataSource={data} 
        onChange={onTableChange}
        expandable={{
            expandedRowRender: (record) => showDetails(record),
          }}         
        />
      : ''}
    </Spin>
    </div>
  );
}

export default CrimeChart;