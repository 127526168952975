import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend,setTableVisibility,ca8debug } from './Utils';

//import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';

import { HeatMapGrid } from 'react-grid-heatmap'

import { Table, Button, Empty, Select, Spin, message, Switch, Menu, Space } from 'antd';
import { AppContext } from "../contexts/AppContext";

import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  ExportOutlined,
  TableOutlined
} from '@ant-design/icons';
import ChartDataDrawer from './drawers/ChartDataDrawer';

const { Option } = Select;
const { SubMenu } = Menu;

const HeatmapChart = (props) => {
    const TAG = 'HeatmapChart';

    const exportRef = useRef();

    ca8debug(`${TAG} props`, props);

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient
    } = appContext;  

    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('period8');
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [crimeFilter, setCrimeFilter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(true);

    const [filteredData, setFilteredData] = React.useState([]);
    const [filteredTitle, setFilteredTitle] = React.useState('');

    const [crimeTypes, setCrimeTypes] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');

    const [chartTableData, setChartTableData] = React.useState([]);
    const [chartTableColumns, setChartTableColumns] = React.useState([]);
    const [chartTableTitle, setChartTableTitle] = React.useState('');
    const [chartTableFilter, setChartTableFilter] = React.useState(undefined);
    const [chartTableMode, setChartTableMode] = React.useState('');    
    const [drawerVisible, setDrawerVisible] = React.useState(false);
    const [useUnknownForCalculation, setUseUnknownForCalculation] = React.useState(true);

    const columns = [
      {
        title: 'Crime Group',
        dataIndex: 'category',
        key: 'category',
        sorter: true,
        filters: categoryFilter,
        filteredValue: allFilters.category || null,
        onFilter: (value, record) => {
          return record.category.includes(value);
        },      
      },
      {
        title: 'Crime Type',
        dataIndex: 'crime',
        key: 'crime',
        sorter: true,
        filters: crimeFilter,
        filteredValue: allFilters.crime || null,
        onFilter: (value, record) => {
          return record.crime.includes(value);
        },
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        sorter: true,
      },
    ];
  
    ca8debug(`${TAG} started`);
  
    const tableRef = React.useRef();
  
    React.useEffect(() => {
  
      const filter = props.filter;
      setLoading(true);
      setLoadingMessage('Loading ...');
      //setTableVisibility(false,"divCrimeChart");
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData && filter) {
        setLoading(true);
        setLoadingMessage('Loading ...');
        setDrawerVisible(false);

        apiClient.post(`api/clients/${clientId}/chart/heatmap`, filter)
          .then(response => {
            ca8debug(`${TAG} data response`, response.data);
            
                // parse results
                //value,crime,category,cyear,cmonth,weekday,chour
                //ca8debug('results', results)
                const dt = response.data.map((x, idx) => {
                  return {
                      'id': idx + 1, 
                      'period1': x.period1, 
                      'period2': x.period2, 
                      'period4': x.period4, 
                      'period8': x.period8, 
                      'weekday': x.weekday, 
                      'sday': x.sday, 
                      'value': x.value, 
                  }
                });            
                ca8debug('dt', dt);

                //updateFilters(dt);

                setData(dt);
                // set initial chart data
                setChartData(dt);

                setFilteredData([]);
                setFilteredTitle('');
    
                setLoading(false);
            
          });
      }
    }, [props.filter]);

    const exportToPdf = async (el, imageFileName) => {

      ca8debug(`${TAG} exportToPdf data`, data, allFilters);
  
      setLoadingMessage('Export to PDF ...');
      setLoading(true);
  
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      ca8debug(`${TAG} exportToPdf image`, image);
  
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
  
      var formData = new FormData();
      formData.append("chart", image);
      formData.append("title", 'Temporal Analysis');
      formData.append("filter", JSON.stringify(props.filter));
      formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
      formData.append("is_aggregated", false);
  
      apiClient.post(`api/clients/${clientId}/pdfhm`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }, responseType: 'blob', timeout: 5000000
      })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        }).catch((error) => {
  
          setLoading(false);
          message.error(error);
  
        });    
    };
    const exportToPrint = async (el, imageFileName) => {

      ca8debug(`${TAG} exportToPrint data`, data, allFilters);
    
      setLoadingMessage('Prepare to print ...');
      setLoading(true);
  
      document.body.querySelectorAll('iframe').forEach( n => n.remove() );
    
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      ca8debug(`${TAG} exportToPrint image`, image);
    
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    
      var formData = new FormData();
      formData.append("chart", image);
      formData.append("title", 'Temporal Analysis');
      formData.append("filter", JSON.stringify(props.filter));
      formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
      formData.append("is_aggregated", false);
    
      apiClient.post(`api/clients/${clientId}/pdfhm`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }, responseType: 'blob', timeout: 5000000
      })
      .then((response) => {
        setLoading(false);
    
        const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
  
        printPdf(data);
  
      }).catch((error) => {
          setLoading(false);
          message.error(error);
      });    
    };
  
    const printPdf = (data) => {
      ca8debug(`${TAG} printPdf started`);
      const printFrame1 = document.createElement('iframe');
      document.body.appendChild(printFrame1);
  
      printFrame1.style.display = 'none';
      printFrame1.onload = function() {
        setTimeout(function() {
          ca8debug(`${TAG} exportToPrint onload`);
  
          printFrame1.focus();
          printFrame1.contentWindow.print();
          ca8debug(`${TAG} exportToPrint printed`);
        }, 1);
      };
      printFrame1.src = data;
    }  

  const getChart = () => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, chartData);
    
    return getChartItem(chartData);
  }    

  const formatHour = (value) => {
    var s = "00" + value;
    return s.slice(-2);
  }  

  const getWeekDayName = (day) => {
    
    const date = new Date(Date.UTC(1999, 10, 1)); // monday
    date.setDate(date.getDate() + day);
    const result = date.toLocaleString('default', { weekday: 'long' });
    ca8debug(`${TAG} getWeekDayName `, result, day, date);
    return result;
  }

  const getUniqueWeekday = () => {

    const names = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const days = props.filter.dayOfWeek && props.filter.dayOfWeek.length > 0 
      ? props.filter.dayOfWeek.split(',').map((x) => +x)
      : [0,1,2,3,4,5,6];

    const result = [];
    days.forEach(x => {
      result.push(names[x]);
    });

    ca8debug(`${TAG} getUniqueWeekday`, result);
    return result; 
  } 

  const getChartItem = (pData) => {
    ca8debug(`${TAG} getChartItem chartMode ${chartMode} data`, pData);

    const yLabels = [];
    for (let i = 0; i < 24; i++) {
      let hr = formatHour(i);
      yLabels.push(`${hr}:00 - ${hr}:59`);
    }
    //yLabels.push('Unknown');
    const totalRows = 24; // yLabels.length;

    const xLabels = getUniqueWeekday(); //["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

    const uniqueDays = getUniqueWeekday();
    //const xLabels = uniqueDays.map(x => getWeekDayName(x));

    ca8debug(`${TAG} getChartItem labels`, uniqueDays, yLabels);

    let data = [ ];
    let totalX = [ ];
    let totalY = [ ];
    let noTime = [ ];

    // calc data
    for(var i = 0; i < totalRows; ++i) {
        data[i] = [ ];
        totalY[i] = [pData.filter(m => ((m.period1 == '' ? 'Unknown' : m.period1) === yLabels[i])).map(n => +n.value).reduce((a, b) => +a + +b, 0)];
        for(var j = 0; j < xLabels.length; ++j) {
//              let val = pData.filter(m => ((m.period1 === yLabels[i]) && (m.sday == uniqueDays[j]))).map(n => +n.value).reduce((a, b) => +a + +b, 0);
              let val = pData.filter(m => (((m.period1 == '' ? 'Unknown' : m.period1) === yLabels[i]) && (m.weekday == xLabels[j]))).map(n => +n.value).reduce((a, b) => +a + +b, 0);
              data[i][j] = val;
        }
    }  
    
    // calc y totals
    const tempYLabels = [...yLabels, 'Unknown'];
    for(var i = 0; i < tempYLabels.length; ++i) {
      totalY[i] = [pData.filter(m => ((m?.period1 == '' ? 'Unknown' : m?.period1) === tempYLabels[i])).map(n => +n.value).reduce((a, b) => +a + +b, 0)];
    }

    // calc x totals
    for(var j = 0; j < xLabels.length; ++j) {
      totalX[j] = pData.filter(m => (m.weekday == xLabels[j])).map(n => +n.value).reduce((a, b) => +a + +b, 0);
      noTime[j] = pData.filter(m => (m.weekday == xLabels[j] && m.period1 == '')).map(n => +n.value).reduce((a, b) => +a + +b, 0);
      // if (!useUnknownForCalculation) {
      //   totalX[j] = totalX[j] - noTime[j];
      // }
    }

    const totalSum = totalX.reduce((partialSum, a) => partialSum + a, 0);

    ca8debug(`${TAG} getChartItem data 1`, totalRows, data, totalX, noTime, totalY);
 
    return <div style={{ position: 'relative', height: 660 }}>
      <div  style={{width: 900, display: 'inline-block'}}>
    <HeatMapGrid
        key='hm_all'
        data={data}
        xLabels={xLabels}
        yLabels={yLabels}
        // Reder cell with tooltip
        cellRender={(x, y, value) => (
        <div title={`${xLabels[y]}, ${yLabels[x]}, value: ${value}`}>{value}</div>
        )}
        xLabelsStyle={index => ({
        color: "#777",
        //fontSize: ".65rem"
        })}
        yLabelsPos="left"
        yLabelsStyle={() => ({
        //fontSize: ".65rem",
        color: "#777"
        })}
        cellStyle={(_x, _y, ratio) => ({
        background: `rgb(123, 119, 140, ${_x == totalRows ? 0 : ratio})`,
        //fontSize: ".7rem",
        color: `rgb(0, 0, 0, ${_x == totalRows ? 1 : ratio / 2 + 0.4})`
        })}
        cellHeight="1.4rem"
        xLabelsPos="top"
        onClick={(x, y) => {
          showDetails(xLabels[y], yLabels[x]);
        }}
    />   
    </div>
    <div  style={{width: 200, display: 'inline-block', verticalAlign: 'top'}}>
    <HeatMapGrid
        key='hm_total_y'
        data={totalY}
        xLabels={['Total']}
        yLabels={yLabels}
        // Reder cell with tooltip
        cellRender={(x, y, value) => (
        <div title={`${yLabels[x]}, Total: ${value}`}>{value}</div>
        )}
        xLabelsStyle={index => ({
        color: "#777",
        //fontSize: ".65rem"
        })}
        yLabelsPos="right"
        yLabelsStyle={() => ({
        //fontSize: ".65rem",
        color: "#777"
        })}
        cellStyle={(_x, _y, ratio) => ({
        background: `rgb(123, 119, 140, 0)`,
        // background: `rgb(123, 119, 140, ${_x == totalRows ? 0 : ratio})`,
        //fontSize: ".7rem",
        color: `rgb(0, 0, 0, 1)`
        // color: `rgb(0, 0, 0, ${_x == totalRows ? 1 : ratio / 2 + 0.4})`
        })}
        cellHeight="1.4rem"
        xLabelsPos="top"
        onClick={(x, y) => {
          // showDetails(xLabels[y], yLabels[x]);
        }}
    /> 
    </div>

    <div  style={{width: 900, position: 'relative'}}>
    <div style={{ position: 'absolute', width: 900, top: -22 }}>
    <HeatMapGrid
        key='hm_notime_x'
        data={[noTime]}
        yLabels={['Unknown']}
        //xLabels={xLabels}
        // Reder cell with tooltip
        cellRender={(x, y, value) => (
          <div title={`${xLabels[y]}, ${yLabels[x]}, value: ${value}`}>{value}</div>
          )}
        xLabelsStyle={index => ({
        color: "#777",
        //fontSize: ".65rem"
        })}
        yLabelsPos="left"
        yLabelsStyle={() => ({
          width: 86,
        //fontSize: ".65rem",
        color: "#777"
        })}
        cellStyle={(_x, _y, ratio) => ({
        background: `rgb(123, 119, 140, 0)`,
        // background: `rgb(123, 119, 140, ${ratio})`,
        //fontSize: ".7rem",
        color: `rgb(0, 0, 0, 1)`
        // color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`
        })}
        cellHeight="1.4rem"
        xLabelsPos="bottom"
        onClick={(x, y) => {
          // showDetails(xLabels[y], yLabels[x]);
        }}
    />     

    <HeatMapGrid
        key='hm_total_x'
        data={[totalX]}
        yLabels={['Total']}
        xLabels={xLabels}
        // Reder cell with tooltip
        cellRender={(x, y, value) => (
        <div title={`${xLabels[y]}, Total: ${value}`}>{value}</div>
        )}
        xLabelsStyle={index => ({
        color: "#777",
        //fontSize: ".65rem"
        })}
        yLabelsPos="left"
        yLabelsStyle={() => ({
          width: 86,
        //fontSize: ".65rem",
        color: "#777"
        })}
        cellStyle={(_x, _y, ratio) => ({
        // background: `rgb(123, 119, 140, ${ratio})`,
        background: `rgb(123, 119, 140, 0)`,
        //fontSize: ".7rem",
        color: `rgb(0, 0, 0, 1)`
        //color: `rgb(0, 0, 0, ${ratio / 2 + 0.4})`
        })}
        cellHeight="1.4rem"
        xLabelsPos="bottom"
        onClick={(x, y) => {
          // showDetails(xLabels[y], yLabels[x]);
        }}
    />     
    </div> 
    <div style={{ position: 'absolute', top: 0, left: 900, width: 114, textAlign: 'center', fontWeight: 700 }}>{totalSum}</div>
    </div>
    
    </div>
  }

  const showDetails = (weekday, timeperiod) => {

    const dt = props.data.filter(x => 
        x.weekday == weekday &&
        x.period1 == timeperiod 
        );
    const drawerData = {
      columns: cols,
      items: dt
    };

    ca8debug(`${TAG} showDetails`, weekday, timeperiod, dt)

    setFilteredTitle(`${weekday}, ${timeperiod}`);
    setFilteredData(dt);

    setChartTableMode('heatmap');
    setChartTableTitle(`Temporal Analysis: ${weekday}, ${timeperiod}`);

    setChartTableFilter(allFilters);
    setChartTableData(drawerData);
    setChartTableColumns(cols);
    setDrawerVisible(true);    

  }

  const onDrawerClose = () => {
    setChartTableData([]);
    setDrawerVisible(false);
    props.selectChart('');
  } 

  const onChangeDOW = (checked) => {
    ca8debug(`onChangeDOW switch to ${checked}`);
    props.onSetShowDOW(checked);
  }

  const onChangeTOD = (checked) => {
    ca8debug(`onChangeTOD switch to ${checked}`);
    props.onSetShowTOD(checked);
  }

  // const onChangeUseUnknown = (checked) => {
  //   ca8debug(`onChangeUseUnknown switch to ${checked}`);
  //   setUseUnknownForCalculation(checked);
  // }


  const cols = [
    { title: 'id',dataIndex: 'incident_id',key: 'incident_id' },
    { title: 'Site',dataIndex: 'site_name',key: 'site_name' },
    { title: 'Crime Type',dataIndex: 'crime_type',key: 'crime_type' },
    { title: 'Date',dataIndex: 'incident_date',key: 'incident_date' },
    { title: 'Time',dataIndex: 'incident_time',key: 'incident_time' },
    { title: 'Relationship',dataIndex: 'incident_relationship',key: 'incident_location' },
    { title: 'Victim',dataIndex: 'incident_victim',key: 'incident_victim' },
    { title: 'Comments',dataIndex: 'incident_comments',key: 'incident_comments' },
    { title: 'Offense Report',dataIndex: 'incident_offence_report_no',key: 'incident_offence_report_no' },
  ];  

  return (

    <div id="divHeatmapChart" className="chart3-container" style={{ backgroundColor: props.selected ? '#defbee' : 'transparent'}}>
      <table>
        <tr>

      {drawerVisible
      ? ''
      : <td className="chart3-col-left">
        <div className="chart3-col-title">Temporal Analysis</div>
        <div>
          <div className="chart3-col-title-row">
          
            <div>
                <Menu mode="vertical" style={{width: 200, border: 'none' }}>
                  <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
                    <Menu.Item disabled={loading || props.loading} icon={<FilePdfOutlined />} onClick={() => {
                      exportToPdf(exportRef.current, "test");
                    }} key="1">PDF</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
                  </SubMenu>
                  <div style={{ paddingTop: 25 }}>
                    <Space direction="vertical">
                    <div>
                      <span className="filter-option-title-inline">Show Day of Week</span>
                      <Switch onChange={onChangeDOW} />
                    </div>
                    <div>
                      <span className="filter-option-title-inline">Show Time of Day</span>
                      <Switch onChange={onChangeTOD} />
                    </div>
                    {/* <div>
                      <span className="filter-option-title-inline">Show unknown</span>
                      <Switch defaultChecked value={useUnknownForCalculation} onChange={setUseUnknownForCalculation} />
                    </div> */}
                    </Space>
                  </div>

                </Menu>
              </div>
          
          </div>
        </div>
      </td>
      }

    <td className="chart3-col-right">
      <Spin tip={loadingMessage} spinning={loading}>
      {loading
        ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
        : data && data.length > 0
          ? <div ref={exportRef} style={{ minWidth: 1200, marginBottom: 30 }}>{getChart()}</div>
          : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
      }
    </Spin>
    </td>
      </tr>
    </table>    

    <ChartDataDrawer 
      visible={drawerVisible} 
      onClose={onDrawerClose} 
      data={chartTableData} 
      chartData={chartTableData} 
      mode={chartTableMode} 
      title={chartTableTitle} 
      filter={chartTableFilter} 
      columns={chartTableColumns}
      />

    </div>
  );
}



export default HeatmapChart;