import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Space, Radio, Breadcrumb, Button, Divider, Drawer, Input, Switch } from 'antd';
import {
    DesktopOutlined,
    SettingOutlined,
    CloudOutlined,
    AlertOutlined,
    TeamOutlined,
    UserOutlined,
    FileOutlined,
    LogoutOutlined,
    PropertySafetyFilled,
  } from '@ant-design/icons';
import ErrorList from "antd/lib/form/ErrorList";

const { TextArea } = Input;

const ClientDrawer = (props) => {
  const TAG = 'ClientDrawer';

  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = React.useState(false);

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  React.useEffect(() => {
    //console.log(`${TAG} useEffect`, props);
    setData(props?.client);
  }, [props.client]);

  const onSave = () => {
    //console.log(`${TAG} onSave`, data);

    setLoading(true);
    if (data.id) {
      apiClient.post(`api/clients/${data.id}`, data)
        .then(response => {
          //console.log(`${TAG} onClientSave update response`, response);
          setLoading(false);
          setData({});
          setErrors([]);
          props.onSave('Client updated successfully', data);
        })
        .catch(function (error) {
          //console.log(`${TAG} onClientSave update error`,error);
          if( error?.response ){
              //console.log(`${TAG} onClientSave update error response`, error.response.data);
              setErrors(error.response?.data?.errors)
          }            
          setLoading(false);
      });   

    } else {
      apiClient.post(`api/clients`, data)
        .then(response => {
          //console.log(`${TAG} onClientSave create response`, response);
          setLoading(false);
          setData({});
          setErrors([]);
          props.onSave('Client created successfully');
        })
        .catch(function (error, response) {
            //console.log(`${TAG} onClientSave create error`,error);
            if( error.response ){
                //console.log(`${TAG} onClientSave create error response`, error.response.data);
                setErrors(error.response.data.errors)
            }            
            setLoading(false);
          });   
    } 

    //setData(undefined);
  }

  const onClose = () => {
    props.onClose();
    setData({});
    setErrors([]);
  }

  const ucr_modes = [
    { label: 'SRS', value: 1 },
    { label: 'NIBRS', value: 2 },
  ];



  return (
    <Drawer title={props.client ? 'Edit Client' : 'Add Client'} placement="right" onClose={props.onClose} visible={props.visible}>
        <Space style={{ width: '100%'}} direction="vertical">
            <div>
                <div className="drawer-field-title">Name</div>
                <Input allowClear value={data?.name} onChange={(e) => setData({...data, name:e.target.value})}/>
                <div className="drawer-field-error">{errors.name}</div>
            </div>
            <div>
                <div className="drawer-field-title" style={{ display: 'inline-block', marginRight: 20 }}>{props.client?.id} UCR System:</div>
                {props.client?.id
                ? data?.ucr_system == 1 ? 'SRS' : 'NIBRS'
                : <Radio.Group onChange={(e) => setData({...data, ucr_system:e.target.value})} value={data?.ucr_system}>
                    <Space direction="horizontal">
                    <Radio value={1}>SRS</Radio>
                    <Radio value={2}>NIBRS</Radio>
                    </Space>
                </Radio.Group>}    

                <div className="drawer-field-error">{errors.ucr_system}</div>            
            </div>
            <div>
                <div className="drawer-field-title">Address</div>
                <Input allowClear value={data?.address} onChange={(e) => setData({...data,address:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">City</div>
                <Input allowClear value={data?.city} onChange={(e) => setData({...data, city:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">Zip</div>
                <Input allowClear value={data?.zip} onChange={(e) => setData({...data, zip:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">Phone</div>
                <Input allowClear value={data?.phone} onChange={(e) => setData({...data, phone:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">E-mail</div>
                <Input allowClear value={data?.email} onChange={(e) => setData({...data, email:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">Url</div>
                <Input allowClear value={data?.url} onChange={(e) => setData({...data, url:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">Description</div>
                <TextArea allowClear value={data?.description} onChange={(e) => setData({...data, description:e.target.value})}/>
            </div>
            <div>
                <div className="drawer-field-title">Expert mode</div>
                <Switch 
                  style={{width: 150, marginBottom: 20}}
                  checkedChildren="Expert" 
                  unCheckedChildren="Not expert" 
                  checked={data?.is_expert ?? false} 
                  onChange={(checked) => setData({...data, is_expert:checked})} 
                />                 
            </div>

        </Space>

        <div style={{ marginTop: 20 }}>
            <Space direction="horizontal">
                <Button type="primary" onClick={onSave} loading={loading}>Save</Button>
                <Button type="primary" onClick={onClose} loading={loading}>Cancel</Button>
            </Space>
        </div>
  </Drawer>
  );

};

export default ClientDrawer;
