import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Layout, Menu, Spin, Table, Button, Space, Drawer, Divider, Popconfirm } from 'antd';
import { AppContext } from "../../contexts/AppContext";

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import UserDrawer from '../drawers/UserDrawer';


const SettingsUsers = (props) => {
  const TAG = 'SettingsUsers';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(true);
  
  const [users, setUsers] = React.useState([]);

  const [currentUser, setCurrentUser] = React.useState(undefined);


  const [drawerVisible, setDrawerVisible] = React.useState(false);

    const getRoleName = (role) => {
        switch (role) {
            case 2:
                return 'Global admin';
            case 1:
                return 'Local admin';
            case 0:
                return 'User';
    
            default:
                return 'Unknown';
      }
  }

  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'E-mail', dataIndex: 'email', key: 'email' },
    { title: 'Role', dataIndex: 'role', key: 'role', render: (text, record) => getRoleName(record?.role)},
    {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => onEditUser(record)}>Edit</a>
          {record.role !== 2 
          ? <Popconfirm
                title="Are you sure to delete this user?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteUser(record)}
          >
            <a>Delete</a>
          </Popconfirm>
          : ''}
          
        </Space>
      ),
    },    
  ]

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/users`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setUsers(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setLoading(false);
      });

  }

  React.useEffect(() => {
    loadData();
  }, [props.client]);

  const addUser = () => {
    setCurrentUser(undefined);
    setDrawerVisible(true);
  }

  const onEditUser = (user) => {
    //console.log(`${TAG} onEditUser started`,user);
    setCurrentUser(user);
    setDrawerVisible(true);
  }

  const onDeleteUser = (data) => {

    const clientId = props.client.id;

    console.log(`${TAG} onDeleteUser started`,clientId,data);

    apiClient.post(`api/clients/${clientId}/users/${data.id}/delete`, data)
    .then(response => {
      console.log(`${TAG} onDeleteUser data response`, response.data);
      
      message.success(`User ${data.name} deleted`);
      loadData();

    })
    .catch(e => {

      console.log(`${TAG} onDeleteUser error`,e);

      message.error(e.message);
    });
    //setCurrentUser(user);
    //setDrawerVisible(true);
  }

  const onUserSave = (msg) => {
    setCurrentUser({});
    setDrawerVisible(false);
    loadData();
    message.success(msg);
  }

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  return (
    
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
        <Space direction='horizontal'>
          <Button type="primary" onClick={addUser} loading={loading} icon={<UserAddOutlined />}>
            Add User
          </Button>
          <Button type="primary" onClick={loadData} loading={loading} icon={<ReloadOutlined />}>
            Refresh
          </Button>
        </Space>
      </div>
      <Spin tip="Loading..." spinning={loading} delay={0}>
      <Table size='small' columns={columns} dataSource={users} title={() => 'Users list'} />
      </Spin>
      <UserDrawer visible={drawerVisible} onSave={onUserSave} onClose={onDrawerClose} client={props.client} data={currentUser}/>
  </div>    
  );
}


export default SettingsUsers;
