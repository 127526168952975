import React, { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { Space, Radio, Spin, Table, Button, Divider, Drawer, Input } from 'antd';
import {
  CloseOutlined,
    SettingOutlined,
    CloudOutlined,
    AlertOutlined,
    TeamOutlined,
    UserOutlined,
    FileOutlined,
    LogoutOutlined,
    PropertySafetyFilled,
  } from '@ant-design/icons';
import ErrorList from "antd/lib/form/ErrorList";
import { ca8debug, getFilterTitle } from '../Utils';

const { TextArea } = Input;

const ChartDataDrawer = (props) => {
  const TAG = 'ChartDataDrawer';

  const [data, setData] = useState({});
  const [loading, setLoading] = React.useState(false);

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const expandedColumns = [
    //{ title: 'id',dataIndex: 'incident_id',key: 'incident_id' },
    { title: 'Site',dataIndex: 'site_name',key: 'site_name' },
    { title: 'Crime Type',dataIndex: 'crime_type',key: 'crime_type' },
    { 
      title: 'Date / Time',
      //dataIndex: 'incident_date',
      key: 'incident_date',
      render: (text, record) => `${record.incident_date} ${record.incident_time ? record.incident_time : ''}`
    },
    //{ title: 'Time',dataIndex: 'incident_time',key: 'incident_time' },
    { title: 'Location',dataIndex: 'incident_location',key: 'incident_location' },
    { title: 'Victim',dataIndex: 'incident_victim',key: 'incident_victim' },
    { title: 'Comment',dataIndex: 'incident_comments',key: 'incident_comments' },
    { title: 'Relationship',dataIndex: 'incident_relationship',key: 'incident_relationship' },
    { title: 'Offense Report',dataIndex: 'incident_offence_report_no',key: 'incident_offence_report_no' },
  ];

  React.useEffect(() => {
    ca8debug(`${TAG} useEffect`, props.columns, props.data);
    //setData(props.chartData);

  }, [props.chartData]);

  const showDetails = (record) => {
    const cols = record.columns ? record.columns : expandedColumns;
    ca8debug(`${TAG} showDetails`, record);
   
    if (record.columns) {
      return <Table 
        size='small' 
        rowKey='id'
        key={`table_${record.id}`}
        columns={cols} 
        dataSource={record.items} 
     
      />      
    } else {
      
      return <Table 
        size='small' 
        rowKey='incident_id'
        key={`table_${record.id}`}
        columns={cols} 
        dataSource={record.items} 
    
      />      
    }
  }

  const showComment = (record) => {
    /*    
        switch (props.mode) {
          case 'sites':
            return showSitesDetails(record);
          case 'time':
            return showTimeDetails(record);
          default:
            break;
        }
    */
    const cols = record.columns ? record.columns : expandedColumns;
    ca8debug(`${TAG} showComment`, record);
    return <table style={{ marginLeft: 10, paddingRight: 2 }}>
      <tr>
        <td className="incident-comment-row-title">Site:</td>
        <td className="incident-comment-row-data">{record.site_name}</td></tr>
      <tr>
        <td className="incident-comment-row-title">Location:</td>
        <td className="incident-comment-row-data">{record.incident_location}</td>
      </tr>
      <tr>
        <td className="incident-comment-row-title">Victim:</td>
        <td className="incident-comment-row-data">{record.incident_victim}</td>
      </tr>
      <tr>
        <td className="incident-comment-row-title">Comment:</td>
        <td className="incident-comment-row-data">{record.incident_comments}</td>
      </tr>
    </table>

  }

  const showSitesDetails = (record) => {

    const filteredData = props.extra && props.useCrimeGroups ? props.data.filter(x => 
        x.state_name == record.group1 && 
        x.site_city == record.group2 &&
        x.site_name == record.site &&
        x.crime_category == record.category) 
        : props.data.filter(x => 
          x.state_name == record.group1 && 
          x.site_city == record.group2 &&
          x.site_name == record.site
        );

    ca8debug(`${TAG} showSitesDetails`, record, filteredData)
    
    return <Table 
        size="small"
        key={`table_${record.group1}_${record.group2}_${record.site}_${record.category ? record.category : 0}`}
        columns={expandedColumns} 
        dataSource={filteredData}
        />
  }  

  const showTimeDetails = (record) => {

    const filteredData = props.data.filter(x => 
        x.cyear == record.year && 
        x.quarter == record.quarter &&
        x.cmonth == record.month &&
        x.crime_category == record.category 
        );

    ca8debug(`${TAG} showTimeDetails`, record, filteredData)
    
    return <Table 
        size="small"
        key={`table_${record.year}_${record.quarter}_${record.month}_${record.category}`}
        columns={expandedColumns} 
        dataSource={filteredData}
        />
  }  

  const cols = {}

  const onClose = () => {
    //setData({children:[],columns:[]});
    props.onClose();
  }

  const getTitle = () => {

    const flt = props.filter && Object.keys(props.filter).map((x, idx) => {
      return props.filter[x] != null 
        ? <span key={`tr${idx}`}>
            <span style={{ marginRight: 10 }}><b>{getFilterTitle(x)}:</b></span>
            <span style={{ marginRight: 20 }}>{props.filter[x] != null ? props.filter[x].join(', ') : ''}</span>
          </span> 
        : <span key={`tr${idx}`}></span>;
      })

    return <div>
      <div style={{ fontSize: 18, fontWeight: 600 }}>{props.title}</div>
      <div style={{ fontSize: 14, fontWeight: 300, marginTop: 10 }}>{flt}</div>
    </div>
  }

  return (
    <Drawer 
      destroyOnClose={true} width='100%'
      getContainer={false}
      style={{ position: 'absolute' }}
      closeIcon={<CloseOutlined style={{ color: 'red' }}/>}
      title={getTitle()} 
      placement="right" 
      onClose={onClose} 
      visible={props.visible}>
    <div>

      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading}>
        {props.mode == 'heatmap'
        ? <Table 
            size='small' 
            rowKey='id'
            columns={props.data.columns} 
            dataSource={props.data.items} 
          />
        : <Table 
          size='small' 
          rowKey='id'
          columns={props.data.columns} 
          dataSource={props.data.items} 
          expandable={{
            expandedRowRender: (record) => showDetails(record),
          }}         
        />
      }
      </Spin>
      </div>
    </div>   
  </Drawer>
  );

};

export default ChartDataDrawer;
