import React, { useContext, useCallback, useState, useRef } from 'react'
import { sortAsc, sortDesc, FiltersLegend,setTableVisibility,getThresholdReferences, ca8debug } from './Utils';

import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label, LabelList } from 'recharts';
import { Table, Button, Empty, Select, Spin, message, Checkbox, Menu, Switch } from 'antd';
import { AppContext } from "../contexts/AppContext";


import html2canvas from "html2canvas";
import ReactDOMServer from 'react-dom/server'
import {
  PrinterOutlined,
  FilePdfOutlined,
  FileExcelOutlined,
  TableOutlined,
  ExportOutlined
} from '@ant-design/icons';
import ChartDataDrawer from './drawers/ChartDataDrawer';

const { Option } = Select;
const { SubMenu } = Menu;

const HourChart = (props) => {
    const TAG = 'HourChart';

    ca8debug(`${TAG} props`, props);

    const exportRef = useRef();
    const tableRef = useRef();

    const appContext = useContext(AppContext);
    const {
      apiClient,
      appRole,
      appClientId,
      appCurrentClient,
      minimizedChartWidth,
      maximizedChartWidth
    } = appContext;  
  
    const [data, setData] = React.useState([]);
    const [chartData, setChartData] = React.useState([]);
    const [chartMode, setChartMode] = React.useState('period8');
    const [categoryFilter, setCategoryFilter] = React.useState([]);
    const [period1Filter, setPeriod1Filter] = React.useState([]);
    const [period2Filter, setPeriod2Filter] = React.useState([]);
    const [period4Filter, setPeriod4Filter] = React.useState([]);
    const [period8Filter, setPeriod8Filter] = React.useState([]);
    const [showTable, setShowTable] = React.useState(false);
    const [show24hMode, setShow24hMode] = React.useState(true);
  
    const [allFilters, setAllFilters] = React.useState([]);
    const [allSorters, setAllSorters] = React.useState({});

    const [loading, setLoading] = React.useState(true);

    const [incidentData, setIncidentData] = React.useState([]);

    const [loadingMessage, setLoadingMessage] = React.useState('Loading ...');
    const [exportAggregatedData, setExportAggregatedData] = React.useState(true);
    const [cellCount, setCellCount] = React.useState(0);

    const [uniqueTimePeriod, setUniqueTimePeriod] = React.useState([]);
    const [drillDownHistory, setDrillDownHistory] = React.useState([]);

    const [chartTableData, setChartTableData] = React.useState([]);
    const [chartTableColumns, setChartTableColumns] = React.useState([]);
    const [chartTableTitle, setChartTableTitle] = React.useState('');
    const [chartTableFilter, setChartTableFilter] = React.useState(undefined);
    const [chartTableMode, setChartTableMode] = React.useState('');    
    const [drawerVisible, setDrawerVisible] = React.useState(false);

    ca8debug(`${TAG} started`);
  
    React.useEffect(() => {
  
      const filter = props.filter;
    
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

      if (props.allData && filter) {
        setLoading(true);
        setLoadingMessage('Loading ...');
        setDrawerVisible(false);
      apiClient.post(`api/clients/${clientId}/chart/periods`, filter)
      .then(response => {
          ca8debug(`${TAG} data response`, response);
          
              const dt = response.data.map((x, idx) => {
                return {
                    'id': idx + 1, 
                    'category': x.crime_category_name, 
                    'period1': x.period1, 
                    'period2': x.period2, 
                    'period4': x.period4, 
                    'period8': x.period8, 
                    'value': x.value, 
                }
              });            
              ca8debug(`${TAG} dt`, dt);

              setChartMode('period8');

              setAllFilters([]);

              setShow24hMode(true);
              updateFilters(dt, 'period8', true);
              calculateUniqueTimePeriod('', 24, 1);

              setDrillDownHistory([]);


              //calculateUniqueTimePeriod('00:00 - 23:59', 24, 8);

              setData(dt);
              // set initial chart data
              //updateTableData(dt, allFilters, allSorters);
              setChartData(dt);

              setLoading(false);
  
        });
      }
    }, [props.filter]);

  const updateFilters = (dt, mode, isShow24hMode) => {
    // read period1 filter
    const uniquePeriod1 = [...new Set(dt.map(x => x.period1))];
    setPeriod1Filter(uniquePeriod1.map(x => { return {'text': x, 'value': x}}));
    // read period2 filter
    const uniquePeriod2 = [...new Set(dt.map(x => x.period2))];
    setPeriod2Filter(uniquePeriod2.map(x => { return {'text': x, 'value': x}}));
    // read period1 filter
    const uniquePeriod4 = [...new Set(dt.map(x => x.period4))];
    setPeriod4Filter(uniquePeriod4.map(x => { return {'text': x, 'value': x}}));
    // read period8 filter
    const uniquePeriod8 = [...new Set(dt.map(x => x.period8))];
    setPeriod8Filter(uniquePeriod8.map(x => { return {'text': x, 'value': x}}));
    // read category filter
    const uniqueCategory = [...new Set(dt.map(x => x.category))];
    ca8debug('uniqueCategory', uniqueCategory);
    setCategoryFilter(uniqueCategory.map(x => { return {'text': x, 'value': x}}));        
    
    // calculate cell count
/*    
    let cnt = uniquePeriod8 ? uniquePeriod8.length + 1 : 1;
    if (isShow24hMode) {
      cnt = uniquePeriod1 ? uniquePeriod1.length + 1 : 1;
    } else {
      if (mode == 'period4') {
          cnt = uniquePeriod4 ? uniquePeriod4.length : 0;
      } else if (mode == 'period2') {
          cnt = uniquePeriod2 ? uniquePeriod2.length : 0;
      } else if (mode == 'period1') {
        cnt = uniquePeriod1 ? uniquePeriod1.length + 1 : 1;
      }
    }
    //console.log(`${TAG} updateFilters cnt`,mode, cnt, isShow24hMode, uniquePeriod1)
    setCellCount(cnt);
*/    
    
  }

  const filterUsingAllFilters = () => {
    ca8debug(`${TAG} filterUsingAllFilters data`, data, allFilters);
    let result = data.slice();
    if (allFilters.period8) {
      result = result.filter(x => allFilters.period8.includes(x.period8));
    }
    if (allFilters.period4) {
      result = result.filter(x => allFilters.period4.includes(x.period4));
    }
    if (allFilters.period2) {
      result = result.filter(x => allFilters.period2.includes(x.period2));
    }
    if (allFilters.period1) {
      result = result.filter(x => allFilters.period1.includes(x.period1));
    }
    if (allFilters.category) {
      result = result.filter(x => allFilters.category.includes(x.category));
    }
    ca8debug(`${TAG} filterUsingAllFilters result`, result);
    return result;
  }

  const exportToPdf = async (el, imageFileName) => {

      ca8debug(`${TAG} exportToPdf data`, data, allFilters);

      setLoadingMessage('Export to PDF ...');
      setLoading(true);
  
      const canvas = await html2canvas(el);
      const image = canvas.toDataURL("image/png", 1.0);
      ca8debug(`${TAG} exportToPdf image`, image);
  
      const clientId = appCurrentClient.id;
      //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
      const dt = calculateTableData();
  
      var formData = new FormData();
      formData.append("chart", image);
      formData.append("title", 'Time of Day');
      formData.append("filter", JSON.stringify(props.filter));
      formData.append("items", JSON.stringify(dt));
      formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
    
      apiClient.post(`api/clients/${clientId}/report`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }, responseType: 'blob', timeout: 5000000
      })
      .then((response) => {
        setLoading(false);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.pdf'); //or any other extension
        document.body.appendChild(link);
        link.click();
        }).catch((error) => {
  
          setLoading(false);
          message.error(error);
  
        });         
  };

  const exportToExcel = async (el, imageFileName) => {

    setLoading(true);
    setLoadingMessage('Export to Excel ...');

    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    //downloadImage(image, imageFileName);
    ca8debug(`${TAG} exportAsImage image`, image);

    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();

    const formData = new FormData();

    formData.append("chart", image);
    formData.append("title", 'Time of Day');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));

    apiClient.post(`api/clients/${clientId}/export`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob'
    })
    .then((response) => {
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.xlsx'); //or any other extension
      document.body.appendChild(link);
      link.click();
      }).catch((error) => {

        setLoading(false);
        message.error(error);
        setLoading(false);

      });    
  };

  const exportToPrint = async (el, imageFileName) => {

    ca8debug(`${TAG} exportToPrint data`, data, allFilters);
  
    setLoadingMessage('Prepare to print ...');
    setLoading(true);

    document.body.querySelectorAll('iframe').forEach( n => n.remove() );
  
    const canvas = await html2canvas(el);
    const image = canvas.toDataURL("image/png", 1.0);
    ca8debug(`${TAG} exportToPrint image`, image);
  
    const clientId = appCurrentClient.id;
    //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;
    const dt = calculateTableData();
  
    var formData = new FormData();
    formData.append("chart", image);
    formData.append("title", 'Time of Day');
    formData.append("filter", JSON.stringify(props.filter));
    formData.append("items", JSON.stringify(dt));
    formData.append("legend", ReactDOMServer.renderToString(props.mainLegend));
  
    apiClient.post(`api/clients/${clientId}/report`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }, responseType: 'blob', timeout: 5000000
    })
    .then((response) => {
      setLoading(false);
  
      const data = window.URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));

      printPdf(data);

    }).catch((error) => {
        setLoading(false);
        message.error(error);
    });    
  };

  const printPdf = (data) => {
    ca8debug(`${TAG} printPdf started`);
    const printFrame1 = document.createElement('iframe');
    document.body.appendChild(printFrame1);

    printFrame1.style.display = 'none';
    printFrame1.onload = function() {
      setTimeout(function() {
        ca8debug(`${TAG} exportToPrint onload`);

        printFrame1.focus();
        printFrame1.contentWindow.print();
        ca8debug(`${TAG} exportToPrint printed`);
      }, 1);
    };
    printFrame1.src = data;
  }  

  const onTableChange = (pagination, filters, sorter) => {
    ca8debug(`${TAG} onTableChange filters`, filters);
   
    
    setAllFilters(filters);
    setAllSorters(sorter);

    updateTableData(data, filters, sorter);

  }

  const updateTableData = (dd, filters, sorter) => {
    let newData = dd.slice();
    if (filters.period1) {
      newData = newData.filter(x => filters.period1.includes(x.period1));
    } 
    if (filters.period2) {
      newData = newData.filter(x => filters.period2.includes(x.period2));
    } 
    if (filters.period4) {
      newData = newData.filter(x => filters.period4.includes(x.period4));
    } 
    if (filters.period8) {
      newData = newData.filter(x => filters.period8.includes(x.period8));
    } 

    if (filters.category) {
      newData = newData.filter(x => filters.category.includes(x.category));
    }
    
    if (sorter) {
      if (sorter.column) {
        if (sorter.order === 'ascend') {
          sortAsc(data, sorter.column.dataIndex);
        } else {
          sortDesc(data, sorter.column.dataIndex);
        }
      }
    }     
    updateFilters(newData, chartMode, show24hMode);
    setChartData(newData);
  }
  
  const onChartModeChange = (value) => {
    ca8debug(`${TAG} onChartModeChange selected ${value}`);
    setChartMode(value);
  }

  const resetFilters = (isShow24hMode) => {
    let newData = data.slice();
    updateFilters(newData, 'period8', isShow24hMode);
    setChartData(newData);      
    setAllFilters([]);
    setChartMode('period8');
    setDrillDownHistory([]);
    if (isShow24hMode) {
      calculateUniqueTimePeriod('', 24, 1);
    } else {
      calculateUniqueTimePeriod('00:00 - 23:59', 24, 8);
    }
  }

  const OnChartClick = (e) => {
    ca8debug(`${TAG} OnChartClick`, e, allFilters, chartMode);
      if (!show24hMode) {
      // change chart mode
      if (e.activePayload && e.activePayload.length == 1 && e.activeLabel !== "Time Unknown") {
        const payloadData = e.activePayload[0];
        if (payloadData && payloadData.payload) {

          const newChartMode = chartMode == 'period8' ? 'period4' : chartMode == 'period4' ? 'period2' : 'period1';
          const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;
          if (chartMode == 'period8') {
            calculateUniqueTimePeriod(payloadData.payload.name, 8, 4);
            newAllFilters['period8'] = [payloadData.payload.name];
          } else if (chartMode == 'period4') {
            calculateUniqueTimePeriod(payloadData.payload.name, 4, 2);
            newAllFilters['period4'] = [payloadData.payload.name];
          } else if (chartMode == 'period2') {
            calculateUniqueTimePeriod(payloadData.payload.name, 2, 1);
            newAllFilters['period2'] = [payloadData.payload.name];
          } 

          // update drill down history
          if (newChartMode != chartMode) {
            const hist = drillDownHistory.slice();
            hist.push(payloadData.payload.name);
            setDrillDownHistory(hist);
          }

          //updateFilters(newData, newChartMode, show24hMode);
          setChartMode(newChartMode);
          onTableChange(null, newAllFilters, null);
        }
      }
    }
  }

  const OnDrillUp = () => {
    ca8debug(`${TAG} OnResetLastFilter`, allFilters);
    // change chart mode
    if (chartMode != 'period8') {
        const newChartMode = chartMode == 'period1' ? 'period2' : chartMode == 'period2' ? 'period4' : 'period8';
        const newAllFilters = Array.isArray(allFilters) ? {} : allFilters;

        let period = 24;
        let size = 1;
        if (newChartMode == 'period8') {
          newAllFilters['period8'] = undefined;
          newAllFilters['period4'] = undefined;
          period = 24;
          size = 8;
        } else if (newChartMode == 'period4') {
          newAllFilters['period4'] = undefined;
          newAllFilters['period2'] = undefined;
          period = 8;
          size = 4;
        } else if (newChartMode == 'period2') {
          newAllFilters['period2'] = undefined;
          newAllFilters['period1'] = undefined;
          period = 4;
          size = 2;
        } 

        // update drill down history
        if (newChartMode != chartMode) {
          if (drillDownHistory && drillDownHistory.length > 0) {
            const hist = drillDownHistory.slice();
            hist.pop();
            if (hist.length > 0) {
              calculateUniqueTimePeriod(hist[hist.length - 1], period, size);
            } else {
              calculateUniqueTimePeriod('00:00 - 23:59', period, size);
            }
            setDrillDownHistory(hist);
          }
        }

        setChartMode(newChartMode);
        onTableChange(null, newAllFilters, null);
    }
  }  

  const getLeadingZeroItem = (value) => {
      return ("00" + value).slice(-2);
  }

  const getFilterTimeValue = (isStartTime) => {
    let result = isStartTime ? 0 : 24;
    if (isStartTime) {
      if (props.filter && props.filter.startTime) {
        const arr = props.filter.startTime.split(':');
        result = Number(arr[0]);
      }
    } else {
      if (props.filter && props.filter.endTime) {
        const arr = props.filter.endTime.split(':');
        result = Number(arr[0]);
      }
    }
    return result;
  }

  const calculateUniqueTimePeriod = (timeString, period, size) => {
    ca8debug(`${TAG} calculateUniqueTimePeriod started`, timeString, period, size);
    const result = [];

    const filterStart = getFilterTimeValue(true);
    const filterEnd = getFilterTimeValue(false);
    ca8debug(`${TAG} calculateUniqueTimePeriod filter`, filterStart, filterEnd);
    if (timeString && timeString.length > 0) {
      const arr1 = timeString.split('-');
      if (arr1 && arr1.length > 0) {
        const arr2 = arr1[0].split(':');
        if (arr2 && arr2.length > 0) {
          const start = Number(arr2[0]);
          if (start !== undefined) {
            const finish = start + period;
            for (let i = start; i < finish; i += size) {
              if ((i + size - 1) >= filterStart && i <= filterEnd) {
                ca8debug(`${TAG} calculateUniqueTimePeriod result.push`,`${getLeadingZeroItem(i)}:00 - ${getLeadingZeroItem(i + size - 1)}:59`);
                result.push(`${getLeadingZeroItem(i)}:00 - ${getLeadingZeroItem(i + size - 1)}:59`);
              }
            }
          }
        }
      }
    } else {
      // whole 24 hours
      for (let i = 0; i < 24; i++) {
        if (i >= filterStart && i <= filterEnd) {
          const item = getLeadingZeroItem(i);
          result.push(`${item}:00 - ${item}:59`);
        }
      }
    }
    ca8debug(`${TAG} calculateUniqueTimePeriod result`, result);
    setUniqueTimePeriod(result);
    setCellCount(result.length);
/*    
    let result = [];
    let count = 0;
    if (show24hMode) {
      count = 24;
      new Array(24).fill(0).map((x, idx) => );
    } else {
      if (mode == 'period8') {
        count = 3;
      } else if (mode == 'period4') {
          cnt = uniquePeriod4 ? uniquePeriod4.length : 0;
      } else if (mode == 'period2') {
          cnt = uniquePeriod2 ? uniquePeriod2.length : 0;
      } else if (mode == 'period1') {
        cnt = uniquePeriod1 ? uniquePeriod1.length + 1 : 1;
      }
    }  
*/      
  }

  const getTimeUnknown = () => {

    let result = 0;
    if (!show24hMode) {
      if (chartMode == 'period8') {
        result = chartData.filter(y => ((!y.period8 || y.period8 === ''))).map(z => z.value).reduce((a, b) => +a + +b, 0);
      }
    } else {
      result = chartData.filter(y => ((!y.period1 || y.period1 === ''))).map(z => z.value).reduce((a, b) => +a + +b, 0);
    }

    ca8debug(`${TAG} getTimeUnknown result 1 ${result}`, chartMode, chartData);

    if (result && result > 0) {
      return <div style={{ paddingBottom: 20 }}>Time Unknown: {result}</div>
    } else {
      return '';
    }
  }

  const getChart = () => {
    ca8debug(`${TAG} getChart chartMode ${chartMode}`, chartData);
    let result = [];

    if (!show24hMode) {
      if (chartMode == 'period8') {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            let res = {};          
            res['id'] = idx;
            res['name'] = x;
            let val = chartData.filter(y => ((y.period8 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            res['value'] = val;
            result.push(res);
          } 
          idx++;
        });
/*
        let emptyRes = {};          
        emptyRes['id'] = idx;
        emptyRes['name'] = 'Time Unknown';
        let val = chartData.filter(y => ((!y.period8 || y.period8 === ''))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        emptyRes['value'] = val;
        result.push(emptyRes);
*/
        ca8debug(`${TAG} getChart uniqueTimeperiod`, uniqueTimePeriod, result);

      } else if (chartMode == 'period4') {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            let res = {};          
            res['id'] = idx;
            res['name'] = x;
            let val = chartData.filter(y => ((y.period4 === x))).map(z => +z.value).reduce((a, b) => +a + +b, 0);
            res['value'] = val;
            result.push(res);
          }
          idx++;
        });
      } else if (chartMode == 'period2') {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            let res = {};          
            res['id'] = idx;
            res['name'] = x;
            let val = chartData.filter(y => ((y.period2 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            res['value'] = val;
            result.push(res);
          }
          idx++;
        });
      } else {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            let res = {};          
            res['id'] = idx;
            res['name'] = x;
            let val = chartData.filter(y => ((y.period1 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            res['value'] = val;
            result.push(res);
          }
          idx++;
        });
      }
    } else {

      let idx = 0;
      let maxVal = 0;
      uniqueTimePeriod.forEach(x => {
        if (x && x != '') {
          let res = {};          
          res['id'] = idx;
          res['name'] = x;
          let val = data.filter(y => ((y.period1 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
          res['value'] = val;
          result.push(res);
        }
        idx++;
      });
/*      
      let emptyRes = {};          
      emptyRes['id'] = idx;
      emptyRes['name'] = 'Time Unknown';
      let val = chartData.filter(y => ((!y.period1 || y.period1 === ''))).map(z => z.value).reduce((a, b) => +a + +b, 0);
      emptyRes['value'] = val;
      result.push(emptyRes); 
*/           
    }      
    return getChartItem(result);

  }    

  const getChartItem = (data) => {
    return <BarChart
    isAnimationActive={false}
      onClick={OnChartClick}
      width={550}
      layout="vertical"
      data={data}
      margin={{
      top: 20,
      right: 100,
      left: 0,
      bottom: 5,
    }}>
      <CartesianGrid strokeDasharray="2 2" vertical={false} />

      <XAxis type="number" />
      <YAxis type="category" orientation="right" tickSize={40} tickLine={{ stroke: 'lightgray', strokeDasharray: "2 2", strokeWidth: "1" }} tick={{ fontSize: 14, width: 250, dy: 0 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} dataKey="name"/>

      <Bar barSize={20} isAnimationActive={false}  dataKey='value' fill="#7B778C" >
        <LabelList dataKey="value" position="right" fill="#000000"  />
      </Bar>     
    
      {getThresholdReferences(data, true, false)}

      <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />

    </BarChart>    
  }

  const CustomLegend = () => {
    if (chartMode == 'period8') {
      return FiltersLegend(allFilters, resetFilters, null);
    } 
    return FiltersLegend(allFilters, resetFilters, OnDrillUp);
  }

  const columns = [
    {
      title: '8h period',
      dataIndex:  'period8',
      key: 'period8',
      sorter: true,
      filters: period8Filter,
      filteredValue: allFilters.period8,
      onFilter: (value, record) => {
        return record.period8.includes(value);
      },      
    },
    {
      title: '4h period',
      dataIndex:  'period4',
      key: 'period4',
      sorter: true,
      filters: period4Filter,
      filteredValue: allFilters.period4,
      onFilter: (value, record) => {
        return record.period4.includes(value);
      },      
    },
    {
      title: '2h period',
      dataIndex:  'period2',
      key: 'period2',
      sorter: true,
      filters: period2Filter,
      filteredValue: allFilters.period2,
      onFilter: (value, record) => {
        return record.period2.includes(value);
      },      
    },
    {
      title: '1h period',
      dataIndex:  'period1',
      key: 'period1',
      sorter: true,
      filters: period1Filter,
      filteredValue: allFilters.period1,
      onFilter: (value, record) => {
        return record.period1.includes(value);
      },      
    },
    {
      title: 'Crime Group',
      dataIndex: 'category',
      key: 'category',
      sorter: true,
      filters: categoryFilter,
      filteredValue: allFilters.category || null,
      onFilter: (value, record) => {
        return record.category.includes(value);
      },
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      sorter: true,
    },
  ];

  const calculateTableData = () => {

    const td = {};
    td.items = [];
    let idx = 0;
    let incidx = 0;

    td.columns = [
      { title: 'Time period',dataIndex: 'name',key: 'name' },
      { title: 'Value', dataIndex: 'value', key: 'value' }
    ];    

    if (!show24hMode) {
      if (chartMode == 'period8') {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            idx += 1;
            const tr1 = {};
            tr1.id = idx;
            tr1.name = x;
            let val = chartData.filter(y => ((y.period8 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            tr1.value = val;
            tr1.items = props.data.filter(y => ((y.period8 == x)));
            td.items.push(tr1);
          } 
        });

        let emptyRes = {};          
        emptyRes.id = idx + 1;
        emptyRes.name = 'Time Unknown';
        let val = chartData.filter(y => ((!y.period8 || y.period8 === ''))).map(z => z.value).reduce((a, b) => +a + +b, 0);
        emptyRes.value = val;
        emptyRes.items = props.data.filter(y => ((!y.period8 || y.period8 === '')));
        td.items.push(emptyRes);

      } else if (chartMode == 'period4') {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            idx += 1;
            const tr1 = {};
            tr1.id = idx;
            tr1.name = x;
            let val = chartData.filter(y => ((y.period4 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            tr1.value = val;
            tr1.items = props.data.filter(y => ((y.period4 == x)));
            td.items.push(tr1);
          }
        });
      } else if (chartMode == 'period2') {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            idx += 1;
            const tr1 = {};
            tr1.id = idx;
            tr1.name = x;
            let val = chartData.filter(y => ((y.period2 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            tr1.value = val;
            tr1.items = props.data.filter(y => ((y.period2 == x)));
            td.items.push(tr1);
          }
        });
      } else {

        let idx = 0;
        let maxVal = 0;
        uniqueTimePeriod.forEach(x => {
          if (x && x != '') {
            idx += 1;
            const tr1 = {};
            tr1.id = idx;
            tr1.name = x;
            let val = chartData.filter(y => ((y.period1 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
            tr1.value = val;
            tr1.items = props.data.filter(y => ((y.period1 == x)));
            td.items.push(tr1);
          }
        });
      }
    } else {

      let idx = 0;
      let maxVal = 0;
      uniqueTimePeriod.forEach(x => {
        if (x && x != '') {
          idx += 1;
          const tr1 = {};
          tr1.id = idx;
          tr1.name = x;
          let val = chartData.filter(y => ((y.period1 === x))).map(z => z.value).reduce((a, b) => +a + +b, 0);
          tr1.value = val;
          tr1.items = props.data.filter(y => ((y.period1 == x)));
          td.items.push(tr1);
        }
      });

      let emptyRes = {};          
      emptyRes.id = idx + 1;
      emptyRes.name = 'Time Unknown';
      let val = chartData.filter(y => ((!y.period1 || y.period1 === ''))).map(z => z.value).reduce((a, b) => +a + +b, 0);
      emptyRes.value = val;
      emptyRes.items = props.data.filter(y => ((!y.period1 || y.period1 === '')));
      td.items.push(emptyRes);
    }      
    ca8debug(`${TAG} calculateTableData ${idx} / ${incidx}`,chartData, td, props.data, allFilters);
    return td;

  }

  const onSetShow24hMode = (checked) => {
    setShow24hMode(!checked);
    resetFilters(!checked);
  }

  const onShowData = () => {

    const dt = calculateTableData();

    setChartTableMode('time');
    setChartTableTitle('Time of Day');
    setChartTableFilter(allFilters);
    setChartTableData(dt);
    setChartTableColumns(columns);
    setDrawerVisible(true);

  }

  const onDrawerClose = () => {
    setChartTableData([]);
    setDrawerVisible(false);
    props.selectChart('');
  } 

  return (
    <div id="divHourChart" className="chart3-container" style={{ backgroundColor: props.selected ? '#defbee' : 'transparent'}}>
      <table>
        <tr>

      {drawerVisible
      ? ''
      : <td className="chart3-col-left">
        <div className="chart3-col-title">Time of Day</div>
        {chartMode === 'period8'
    ? <Switch 
        style={{ marginLeft: 0, width: 130 }}
            disabled={props.loading}
            checkedChildren="Time Blocks" 
            unCheckedChildren="24h" 
            checked={!show24hMode} 
            onChange={(checked) => onSetShow24hMode(checked)} 
          />
    : ''}        
        <div>
          <div className="chart3-col-title-row">
          
            <div>
                <Menu mode="vertical" style={{width: 200, border: 'none' }}>
                  <SubMenu key="sub1" icon={<ExportOutlined />} title="Export">
                    <Menu.Item disabled={loading || props.loading} icon={<FilePdfOutlined />} onClick={() => {
                      exportToPdf(exportRef.current, "test");
                    }} key="1">PDF</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<FileExcelOutlined />} onClick={() => exportToExcel(exportRef.current, "test")} key="2">Excel</Menu.Item>
                    <Menu.Item disabled={loading || props.loading} icon={<PrinterOutlined />} onClick={() => exportToPrint(exportRef.current, "test")} key="3">Print</Menu.Item>
                  </SubMenu>
                  <Menu.Item 
                    disabled={loading || props.loading}
                    icon={<TableOutlined />} 
                    onClick={() => onShowData()}>
                    Show data
                  </Menu.Item>
                </Menu>
              </div>
          
          </div>
        </div>
      </td>
      }

    <td className="chart3-col-right">
    <Spin tip={loadingMessage} spinning={loading}>

{loading
  ? <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>

  : data && data.length > 0
    ? <div ref={exportRef} style={{ width: drawerVisible ? 'calc(100vh - 200px)' : '100%' }}>
        <CustomLegend/>
        <ResponsiveContainer width='100%' height={cellCount > 15 ? cellCount * 25 : 400}>
        {getChart()}
        </ResponsiveContainer>
        {getTimeUnknown()}
      </div>
    : <div style={{width: '100%', height: 400, padding: 100}}><Empty/></div>
}
</Spin>
</td>
</tr>
</table>

<ChartDataDrawer 
      visible={drawerVisible} 
      onClose={onDrawerClose} 
      data={chartTableData} 
      chartData={chartTableData} 
      mode={chartTableMode} 
      title={chartTableTitle} 
      filter={chartTableFilter} 
      columns={chartTableColumns}
      />
</div>
    


  );
}



export default HourChart;