import React, { useContext, useState } from "react";
import 'antd/dist/antd.css';
import { AppContext } from "../contexts/AppContext";

import { Row , Space, PageHeader, Divider, Tag } from 'antd';

import { DesktopOutlined,
    BarChartOutlined,
    TeamOutlined,
    HomeOutlined,
    UserOutlined } from '@ant-design/icons';
import LogoutButton from "./LogoutButton";

const PageTitle = (props) => {

    const appContext = useContext(AppContext);
    const {
      currentPage,
      apiClient,
      appRole,
      appClientId,
      appCurrentClient,
      appUserName
    } = appContext;  

  const getContent = () => {
    const city = appCurrentClient && appCurrentClient?.city && appCurrentClient?.city?.length > 0 
      ? `${appCurrentClient.city}, ` 
      : '';
    const address = appCurrentClient?.address && appCurrentClient?.address?.length > 0 
      ? appCurrentClient.address
      : '';
    const is_expert = appCurrentClient && appCurrentClient?.is_expert && appCurrentClient?.is_expert > 0
      ? <span style={{ marginRight: 20, color: '#1890ff' }}>Expert Mode</span>
      : ''
    return appCurrentClient 
      ? <Row>
          <Space direction="vertical">
          <div>
            <span style={{ marginRight: 20, color: '#1890ff' }}><TeamOutlined style={{ marginRight: 5 }}/>{appCurrentClient.name}</span>
            {city.length > 0 && address.length > 0 
              ? <span style={{ marginRight: 20, color: '#1890ff' }}><HomeOutlined style={{ marginRight: 5}} />{`${city}${appCurrentClient.address}`}</span>
              : ''
            }
            {is_expert}
            {props.clientButton
              ? props.clientButton
              : ''
            }
          </div>
          <div>
            <span style={{ marginRight: 20, color: 'limegreen' }}><UserOutlined style={{ marginRight: 5 }}/>{appUserName}</span>
          </div>
          </Space>
          <Divider/>
        </Row>
      : '';
  }
  return (
    <PageHeader
    style={{ position: 'relative' }}
      backIcon={props.icon}
      onBack={() => null}
      title={props.title}
      className="site-page-header"
      extra={props.extra
        ? props.extra.join([<LogoutButton/>])
        : [<LogoutButton/>]}
      >
      {getContent()}
    </PageHeader>    
  );
};

export default PageTitle;
