import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Layout, Tag, Spin, Table, Button, Space, Drawer, Divider,Upload } from 'antd';
import { AppContext } from "../../contexts/AppContext";
//import {useDropzone} from 'react-dropzone'

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  FileExcelOutlined,
  CloudUploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;  

const SettingsCrimeTypes = (props) => {
  const TAG = 'SettingsCrimeTypes';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [ucrSystem, setUcrSystem] = React.useState([]);

  React.useEffect(() => {
    loadData();
  }, [props.client]);

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props); 
    setLoading(true);
    apiClient.get(`api/settings/${props.client.id}/crime`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setData(response.data);

        if (response.data) {
          const uniqueUcr = [...new Set(response.data.map(x => x.ucr_system))];
          setUcrSystem(uniqueUcr);
        }

        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setData([]);
        setLoading(false);
      });

  }

  const onActivateCrime = (record) => {
    //console.log(`${TAG} onActivateCrime started`, record); 
    setLoading(true);
    apiClient.post(`api/settings/${props.client.id}/crime/${record.id}/activate`)
      .then(response => {
        //console.log(`${TAG} onActivateCrime response`, response);
        loadData();
      })
      .catch(function (error) {
        console.error(`${TAG} onActivateCrime response`,error);
        setLoading(false);
      });
  }

  const onHideCrime = (record) => {
    //console.log(`${TAG} onHideCrime started`, record); 
    setLoading(true);
    apiClient.post(`api/settings/${props.client.id}/crime/${record.id}/hide`)
      .then(response => {
        //console.log(`${TAG} onHideCrime response`, response);
        loadData();
      })
      .catch(function (error) {
        console.error(`${TAG} onHideCrime response`,error);
        setLoading(false);
      });
  }

  const showTables = () => {
    return ucrSystem.map(x => showUcrSystem(x));
  }

  const showUcrSystem = (ucrSystem) => {

    const columns = [
      { title: 'Name', dataIndex: 'name', key: 'name' },
    ]
  
    const dt = data.filter(x => x.ucr_system == ucrSystem);
    const uniqueCategory = [...new Set(dt.map(x => x.category_name))].map(x => {
      return { name: x };
    });

    //console.log(`${TAG} showUcrSystem for ${ucrSystem}`, dt, uniqueCategory);

    return (
      <Table 
        key={`table_ucr_${ucrSystem}`}
        rowKey='name'
        size='small' 
        columns={columns} 
        dataSource={uniqueCategory} 
        title={() => `${ucrSystem == 1 ? 'SRS' : 'NIBRS'} Crime Types List`} 
        expandable={{
          expandedRowRender: (record) => showTypes(record, ucrSystem),
        }}           
      />
    );
  }

  const showTypes = (record, ucrSystem) => {
    //console.log(`${TAG} showTypes for ${ucrSystem}`, record, ucrSystem);

    const dt = data.filter(x => x.ucr_system == ucrSystem && x.category_name == record.name);

    const cols = [
      { title: 'Name',dataIndex: 'name',key: 'name' },
      /* { title: 'Description',dataIndex: 'description',key: 'description' }, */
      {
        title: '',
        key: 'is_disabled',
        dataIndex: 'is_disabled',
        width: 100,
        render: is_disabled => (
          <span>
            {props.client.id > 0
              ? is_disabled && is_disabled > 0 
                ? <Tag color={'volcano'} key={is_disabled}>{'HIDDEN'}</Tag>
                : <Tag color={'green'} key={is_disabled}>{'ACTIVE'}</Tag>
              : ''
            }
          </span>
        ),
      },      
      {
        title: 'Action',
        key: 'action',
        width: 100,
        render: (text, record) => (
          props.client.id > 0
          ? record.is_disabled && record.is_disabled > 0 
            ? <a onClick={() => onActivateCrime(record)}>Activate</a>
            : <a onClick={() => onHideCrime(record)}>Hide</a>
          : ''
        ),
      },      
    ];

    return <Table 
        size="small"
        key={`table_${ucrSystem}_${record.name}`}
        columns={cols} 
        dataSource={dt}
        rowClassName={record => record.is_disabled && record.is_disabled > 0 ? 'row-settings-crime-hidden' : ''}
        />
        
    return '';        
  }

  const columnsType = [
    { title: 'Name', dataIndex: 'crime_type_name', key: 'crime_type_name' },
    { title: 'Category', dataIndex: 'crime_category_name', key: 'crime_category_name' },
  ]

  return (

    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading} delay={0}>
        {showTables()}
      </Spin>
      </div>
  </div>    
  );
}


export default SettingsCrimeTypes;
