import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Layout, Menu, Spin, Table, Button, Space, Tag, Divider,Popconfirm } from 'antd';
import { AppContext } from "../../contexts/AppContext";

import { DesktopOutlined,
  UsergroupAddOutlined,
  ReloadOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import ClientDrawer from '../drawers/ClientDrawer';


const SettingsClients = (props) => {
  const TAG = 'SettingsClients';

  const appContext = useContext(AppContext);
  const {apiClient, appCurrentClient, appRole} = appContext;  

  const [loading, setLoading] = React.useState(true);
  
  const [clients, setClients] = React.useState([]);

  const [currentClient, setCurrentClient] = React.useState({});


  const [drawerVisible, setDrawerVisible] = React.useState(false);



  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { 
      title: 'Name', 
      key: 'name',
      render: (text, record) => (
        <Space size="middle">
          {record.name}
          {record.is_expert && record.is_expert > 0
            ? <Tag color={'blue'} >{'EXPERT MODE'}</Tag>
            : ''
          }
        </Space>
      ),

    },
    { title: 'E-mail', dataIndex: 'email', key: 'email' },
    { title: 'Url', dataIndex: 'url', key: 'url' },
    { title: 'Description', dataIndex: 'description', key: 'description' },
    {
      title: 'Action',
      key: 'action',
      width: 200,
      render: (text, record) => (
        <Space size="middle">
          {appRole == 2 && <a onClick={() => onSelectClient(record)}>Select</a>}
          <a key={`action_edit_${record.id}`} onClick={() => onEditClient(record)}>Edit</a>
          {appRole == 2 && <Popconfirm
                title="Are you sure to delete this client?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => onDeleteClient(record)}
            >
                <a key={`action_delete_${record.id}`}>Delete</a>
            </Popconfirm>}
        </Space>
      ),
    },    
  ]

  const loadData = () => {
    //console.log(`${TAG} loadData started`);
    setLoading(true);
    apiClient.get(`api/clients`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setClients(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setLoading(false);
      });

  }

  React.useEffect(() => {
    loadData();
  }, []);

  const addClient = () => {
    setCurrentClient(undefined);
    setDrawerVisible(true);
  }

  const onDeleteClient = (client) => {
    //console.log(`${TAG} onDeleteClient started`,client);

    
    //setDrawerVisible(false);
    

    const clientId = appCurrentClient ? appCurrentClient.id : 0;

    setLoading(true);
    apiClient.get(`api/clients/${client.id}/delete`)
      .then(response => {
        //console.log(`${TAG} onDeleteClient response`, response);
        if (clientId == client.id) {
    
          setCurrentClient({});   
          props.onSelectClient({});
        }
        loadData();

        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        loadData();

        setLoading(false);
      });


    

  }

  const onEditClient = (client) => {
    //console.log(`${TAG} onEditClient started`,client);
    setCurrentClient(client);
    setDrawerVisible(true);
  }

  const onSelectClient = (client) => {
    //console.log(`${TAG} onSelectClient`, client);
    props.onSelectClient(client);
  }  

  const onClientSave = (msg, client) => {
/*    
    setLoading(true);
    if (client.id) {
      apiClient.post(`api/clients/${client.id}`, client)
        .then(response => {
          //console.log(`${TAG} onClientSave update response`, response);
          message.success('Client updated successfully');
          setDrawerVisible(false);
          setLoading(false);
          setCurrentClient(undefined);
          loadData();
        })
        .catch(function (error) {
          console.error(`${TAG} onClientSave update error`,error);
          setLoading(false);
        });   

    } else {
      apiClient.post(`api/clients`, client)
        .then(response => {
          //console.log(`${TAG} onClientSave create response`, response);
          message.success('Client created successfully');
          setDrawerVisible(false);
          setLoading(false);
          setCurrentClient(undefined);
          loadData();
        })
        .catch(function (error) {
          console.error(`${TAG} onClientSave create error`,error);
          setLoading(false);
        });   
    } 
*/
    setCurrentClient({});
    setDrawerVisible(false);
    loadData();

    const clientId = appCurrentClient ? appCurrentClient.id : 0;
    if (clientId == client.id) {
      onSelectClient(client, false);      
    }

    message.success(msg);
  }

  const onDrawerClose = () => {
    setDrawerVisible(false);
  };

  return (
    
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
        <Space direction='horizontal'>
          {appRole == 2 && <Button type="primary" onClick={addClient} loading={loading} icon={<UsergroupAddOutlined />}>
            Add Client
          </Button>}
          <Button type="primary" onClick={loadData} loading={loading} icon={<ReloadOutlined />}>
            Refresh
          </Button>
        </Space>
      </div>
      <Spin tip="Loading..." spinning={loading} delay={0}>
      <Table rowKey="id" size='small' columns={columns} dataSource={clients} title={() => 'Clients list'} />
      </Spin>
      <ClientDrawer 
        visible={drawerVisible} 
        onSave={onClientSave} 
        onClose={onDrawerClose} 
        client={currentClient}
        onSelect={props.onSelectClient}
      />
    
  </div>    
  );
}


export default SettingsClients;
