import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Popconfirm, Select , Spin, Table, Button, Space, Drawer, Divider,Upload } from 'antd';
import { AppContext } from "../../contexts/AppContext";
//import {useDropzone} from 'react-dropzone'

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  DeleteOutlined,
  CloudUploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;  
const { Option } = Select;

const SettingsIncidents = (props) => {
  const TAG = 'SettingsIncidents';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);
  const [years, setYears] = React.useState([]);
  const [selectedYear, setSelectedYear] = React.useState(0);


  React.useEffect(() => {
    loadData();
  }, [props.client]);

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/incident`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setData(response.data);

        const yrs = [...new Set(response.data.map(x => {
          const date = new Date(x.incident_date);
          return date.getFullYear();
        }))];

        //console.log(`${TAG} loadData years`, yrs);
        setYears(yrs);
        if (yrs && yrs.length > 0) {
          setSelectedYear(yrs[0]);
        }

        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setData([]);
        setLoading(false);
      });

  }

  const onDeleteIncidents = () => {

    //console.log(`${TAG} onDeleteIncidents started`, props);
    setLoading(true);
    apiClient.post(`api/clients/${props.client.id}/incidents/delete?year=${selectedYear}`)
      .then(response => {
        //console.log(`${TAG} onDeleteIncidents response`, response);
        loadData();
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        message.error(error);
        setLoading(false);
      });

  }

  const columns = [
    { title: 'Offence Report #', dataIndex: 'incident_offence_report_no', key: 'incident_offence_report_no' },
    { title: 'Crime', dataIndex: 'crime_type_name', key: 'crime_type_name' },
    { title: 'Date', dataIndex: 'incident_date', key: 'incident_date' },
    { title: 'Time', dataIndex: 'incident_time', key: 'incident_time' },
    { title: 'Location', dataIndex: 'incident_location', key: 'incident_location' },
    { title: 'Victim', dataIndex: 'incident_victim', key: 'incident_victim' },
    ]

  return (
    
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading} delay={0}>
        {years && years.length > 0
          ? <div style={{ marginBottom: 20 }}>
              <Space direction='horizontal'>
                <span>Delete incidents for selected year:</span>
                <Select value={selectedYear} onChange={(value) => setSelectedYear(value)} style={{ width: 120 }} >
                  {years.map(x => <Option value={x}>{x}</Option>)}
                </Select>    
                <Popconfirm 
                  title={`Are you sure to delete incidents for year ${selectedYear}?`} 
                  onConfirm={() => onDeleteIncidents()}
                  okText="Yes" cancelText="No">
                  <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      loading={loading}
                    >Delete</Button>      
                </Popconfirm>
              </Space>
            </div>
          : ''}
        <Table size='small' columns={columns} dataSource={data} title={() => 'Incident list'} />
      </Spin>
      </div>
  </div>    
  );
}


export default SettingsIncidents;
