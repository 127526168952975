import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Layout, Menu, PageHeader, Select, Space, message, Empty, Spin,Collapse } from 'antd';
import FilterOptions2 from './FilterOptions2';
import { AppContext } from "../contexts/AppContext";
import AppHeader from './AppHeader';
import DraggableComponent from './DraggableComponent'
//import LogoutButton from './LogoutButton'
import PageTitle from './PageTitle'


import { getProperDate,getFillForCategory, ca8debug } from './Utils';
import AppFooter from './AppFooter';

 
import { DesktopOutlined,
  BarChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import CalendarMonthComponent from './CalendarMonthComponent';
import LegendComponent from './LegendComponent';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;
const { Option } = Select;


//import { readString } from 'react-papaparse'

const FrequencyPage2 = (props) => {
  const TAG = 'FrequencyPage2';

  const appContext = useContext(AppContext);
  const {
    apiClient,
    appRole,
    appClientId,
    appCurrentClient
  } = appContext;  

  const defaultFilter = {
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    dayOfWeek: '',
    crimeCategory: '',
    crimeType: '',
    group1: '',
    group2: '',
    site: '',
    relationship: ''
  }

  const [data, setData] = React.useState([]);
  const [mainFilter, setMainFilter] = React.useState(defaultFilter);
  const [loading, setLoading] = React.useState(true);
  
  const [crimeTypes, setCrimeTypes] = React.useState([]);
  const [crimeCategories, setCrimeCategories] = React.useState([]);

  const [mainFilterLegend, setMainFilterLegend] = React.useState('');

  const [showDOW, setShowDOW] = useState(false);
  const [showTOD, setShowTOD] = useState(false);

  const [chartData, setChartData] = useState([]);
  const [uniqueYear, setUniqueYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState(0);
  const [uniqueCategory, setUniqueCategory] = useState([]);

  const pageHeader = () => {
    return <div>

    </div>
  }

  const onSetFilterLegend = (legend) => {

    ca8debug(`${TAG} onSetFilterLegend`, legend);
    setMainFilterLegend(legend);

    onShowMainFilterLegendEx(legend);

  }

  const onShowMainFilterLegendEx = (legend) => {
    const args = {
      message: 'Filter options',
      description: legend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);       
  }  

  const onUseFilters = (filters) => {
    ca8debug(`${TAG} onUseFilters`,filters);

    const newData = data.slice();

    let startDate = '';
    let endDate = '';
    let startTime = '';
    let endTime = '';
    let dayOfWeek = '';  
    let crimeCategory = '';  
    let crimeType = '';  
    let group1 = '';  
    let group2 = '';  
    let site = '';  
    
    let dateCheck = true;

    if (filters.useDatePeriod) {
      startDate = getProperDate(filters.datePeriod[0], filters.dateMode);
      endDate = getProperDate(filters.datePeriod[1], filters.dateMode);
    }

    if (filters.useTimePeriod) {
      startTime = filters.timePeriod[0] ?? '';
      endTime = filters.timePeriod[1] ?? '';
    }

    if (filters.useWeekdays) {
      dayOfWeek = filters.weekdays.join(',');
    } 

    if (filters.useCrimeCategories) {
      crimeCategory = filters.crimeCategories.join(',');
      if (filters.useCrimeTypes) {
        crimeType = filters.crimeTypes.join(',');
      } 
    } 

    if (filters.useGroup1) {
      group1 = filters.group1.join(',');
      if (filters.useGroup2) {
        group2 = filters.group2.join(',');
        if (filters.useSite) {
          site = filters.site.join(',');
        } 
      } 
    } 

    
    const newFilter = {
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      dayOfWeek: dayOfWeek,
      crimeCategory: crimeCategory,
      crimeType: crimeType,
      group1: group1,
      group2: group2,
      site: site,
      relationship: filters.relationship
    }

    ca8debug(`${TAG} onUseFilters result`,newFilter);
    setMainFilter(newFilter);
    loadData(newFilter);
  }

  const getDayName = (yr, mn, dy) => {
    const date = new Date(yr, mn - 1, 1);  
    const month = date.toLocaleString('default', { month: 'short' });
    return `${dy} ${month}, ${yr}`;
  }

  const loadData = (filter) => {
    ca8debug(`${TAG} loadData started`,filter);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    setLoading(true);
    apiClient.post(`api/clients/${clientId}/chart/calendar`, filter)
      .then(response => {
        ca8debug(`${TAG} loadData response`, response);
        
        if (response.data && response.data.length > 0) {
            const uniqueYr = [...new Set(response.data.map(x => x.cyear))];
            const uC = [...new Set(response.data.map(x => x.category))].sort((a, b) => a.localeCompare(b));;

            const dt = [];
            let idx = 0;

            uniqueYr.forEach(yr => {

                const uniqueMonthofyear = [...new Set(response.data.filter(x => x.cyear == yr).map(x => x.cmonth))];
                uniqueMonthofyear.forEach(mn => {
                    const uniqueDayofmonth = [...new Set(response.data.filter(x => x.cyear == yr && x.cmonth == mn).map(x => x.cday))];
                    uniqueDayofmonth.forEach(dm => {
                        idx++;
                        let res = {};      
                        res['year'] = yr;
                        res['month'] = mn;
                        res['day'] = dm;
                        res['label'] = getDayName(yr, mn, dm);
                        let total = 0;
                        uC.forEach(ct => {
                            const val = response.data.filter(x => 
                                ((x.cyear === yr && x.cmonth == mn && x.cday == dm && x.category == ct)))
                                .map(y => y.value).reduce((a, b) => +a + +b, 0);
                            res[ct] = val;   
                            total += val;                             
                        });
                        res['total'] = total;
                        dt.push(res);
                    });
                    
                });
            }); 

            ca8debug(`${TAG} loadData dt`, dt, uniqueYr,uC);
            if (uniqueYr && uniqueYr.length > 0) {
              loadChartData(filter.startDate, filter.endDate, uniqueYr[0], dt, uC);
              setSelectedYear(uniqueYr[0]);
            } else {
              setSelectedYear(0);
            }
            setUniqueYear(uniqueYr);
            setUniqueCategory(uC);
            setData(dt);
        } else {
            setData([]);
            setChartData([]);
            setSelectedYear(0);
            setUniqueYear([]);
            setUniqueCategory([]);
        }
        ca8debug(`${TAG} setLoading`);
        setLoading(false);
    }).catch((error) => {

        setLoading(false);
        message.error(error);
    
    });
  }

  React.useEffect(() => {
    loadData(defaultFilter);
  }, []);

  const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
  }

  const getComponents = () => {

    const uniqueMonthofyear = [...new Set(data.map(x => `${x.year}#${x.month}`))];

    ca8debug(`${TAG} getComponents`,uniqueMonthofyear);

    return uniqueMonthofyear.map(x => {
        const arr = x.split('#');
        const dt = data.filter(x => x.year == +arr[0] && x.month == +arr[1]);
        return <CalendarMonthComponent data={dt} year={+arr[0]} month={+arr[1]} categories={uniqueCategory} />
    });
  }

  const loadChartData = (startDt, endDt, value, lst, uc) => {

    //const dt = lst.filter(x => x.year == value);

    //console.log(`${TAG} loadChartData started`, value, lst, uc, mainFilter);

    const dt = [];
 
    // add absent days
    // with filter's start date and end date check
    const startDateString = startDt && startDt.length > 0 ? startDt : '1970-01-01';
    const endDateString = endDt && endDt.length > 0 ? endDt : '3000-01-01';

    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);
    //console.log(`${TAG} loadChartData FFF`, mainFilter, startDate, endDate);

    for (let mn = 1; mn <= 12; mn ++) {
      const days = daysInMonth(mn, value);
      for (let dm = 1; dm <= days; dm ++) {
        // respect filter date range
        const itemDate = new Date(value, mn - 1, dm);
        ////console.log(`${TAG} loadChartData itemDate`,value, mn, dm, itemDate, startDate, endDate);

        if (itemDate >= startDate && itemDate <= endDate) {
        
          let res = {};      
          res['year'] = value;
          res['month'] = mn;
          res['day'] = dm;
          res['label'] = getDayName(value, mn, dm);
          const existingDay = lst.find(x => (x.year == value && x.month == mn && x.day == dm));
          uc.forEach(ct => {
            if (existingDay) {
              res[ct] = existingDay[ct];
            } else {
              res[ct] = 0;                                
            }
          });

          dt.push(res);
        }
      }
    }

    ca8debug(`${TAG} loadChartData dt 1`, lst);
    ca8debug(`${TAG} loadChartData dt 2`, dt);

    setChartData(dt);
    setSelectedYear(value);
  }

  const getChart = () => {

    ca8debug(`${TAG} getChart`,loading, chartData);

    return <BarChart
            layout="vertical"
            data={chartData}
            margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
            }}
        >
            <CartesianGrid strokeDasharray="3 3" />
            <YAxis dataKey="label" type="category" tick={{ fontSize: 10, width: 250 }} width={200} padding={{ left: 0, right: 0 }} interval={0} fontSize={10} />
            <XAxis type="number"/>
            <Tooltip cursor={{stroke: 'red', strokeOpacity: 0.5, fill: 'red', fillOpacity: 0.3}} />
            <Legend />
            {uniqueCategory.map(x => <Bar maxBarSize={25} dataKey={x} stackId="a" fill={getFillForCategory(x)}/>)}
        </BarChart> 
  }


  return (
    <Layout style={{ minHeight: '100vh' }}>
      <FilterOptions2 
        onUseFilters={onUseFilters} 
        onSetFilterLegend={onSetFilterLegend} 
        onSetShowTOD={setShowTOD}
        onSetShowDOW={setShowDOW}
        loading={loading}/>
        
    <Layout className="site-layout">
{/*}            
        
      <AppHeader />
      <div className="main-header">
        <Collapse defaultActiveKey={[]}>
          <Panel header="Filter options" key="1">
            {mainFilterLegend}
          </Panel>
        </Collapse>       
      </div>
*/}      
      <PageTitle
        icon={<BarChartOutlined />}
        title="Frequency - Chart"
      />      

      <DraggableComponent legend={mainFilterLegend} colors={uniqueCategory}/>

      <LegendComponent legend={uniqueCategory} />

      <Content style={{ margin: '0 5px' }}>

      <Spin tip="Loading ..." spinning={loading}>
        {uniqueYear && uniqueYear.length > 0
        ? <Space direction='horizontal'>
        <span>Select year</span>
        <Select value={selectedYear} onChange={(value) => loadChartData(value, data, uniqueCategory)} style={{ width: 120 }} >
          {uniqueYear.map(x => <Option value={x}>{x}</Option>)}
        </Select>    
        </Space>
        : ''}

        {chartData && chartData.length > 0
        ? <ResponsiveContainer width="100%" height={chartData && chartData.length > 15 ? 25 * chartData.length : 400}>
          {getChart()}
        </ResponsiveContainer>
        : <div style={{width: '100%', height: 400, padding: 100}}><Empty /></div>}
      </Spin>
             
      </Content>
          <AppFooter/>
        </Layout>
      </Layout>
  );
}


export default FrequencyPage2;
