import React, { useContext, useEffect } from "react";
//import { useHistory } from 'react-router-dom'

import {
  NOT_LOGGED_IN,
  LOG_IN_FORM,
  SIGN_UP_FORM,
  LOGGED_IN,
} from "../constants/AuthStatus";
import AuthNotLoggedIn from "./AuthNotLoggedIn";
//import AuthSignup from "./AuthSignup";
//import AuthLogin from "./AuthLogin";
import AuthLogout from "./AuthLogout";
import { AppContext } from "../contexts/AppContext";
import AuthLogin2 from "./AuthLogin2";
import ChartsPage from "./ChartsPage";
import SettingsPage from "./SettingsPage";
import FrequencyPage2 from "./FrequencyPage2";
import CalendarPage from "./CalendarPage";
import DashboardFixed from "./DashboardFixed";
import FrequencyAll from "./FrequencyAll";
import DashboardFixed3 from "./DashboardFixed3";

const AuthContainer = () => {
  const appContext = useContext(AppContext);
  const { authStatus, appCurrentClient, appRole, currentPage, cookie } = appContext;
  const showNotLoggedIn = authStatus === NOT_LOGGED_IN ? "" : "hidden";
  const showLoginForm = authStatus === LOG_IN_FORM ? "" : "hidden";
  const showSignupForm = authStatus === SIGN_UP_FORM ? "" : "hidden";
  const showLoggedIn = authStatus === LOGGED_IN ? "" : "hidden";

  //const history = useHistory();
  const TAG = 'AuthContainer';

  //const clientId = appCurrentClient.id;
  //const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

  //console.log('AuthContainer check', authStatus, appCurrentClient, currentPage);

  if (authStatus === LOGGED_IN) {
    //console.log('AuthContainer proceed with current page', currentPage, (!appCurrentClient?.id) && (appRole == 2));
    return (
      <div>
        <div>
          {(!appCurrentClient?.id) && (appRole == 2)
            ? <SettingsPage cookie={cookie} />
            : currentPage == 'dashboard' 
              ? <ChartsPage cookie={cookie} />
              : currentPage == 'frequency'
                ? <FrequencyPage2 cookie={cookie} />
                : currentPage == 'calendar'
                  ? <CalendarPage cookie={cookie} />
                  : currentPage == 'settings'
                    ? <SettingsPage cookie={cookie} />
                    : currentPage == 'frequency_all' 
                      ? <FrequencyAll cookie={cookie} />
                      : currentPage == 'dashboard_fixed3'
                        ? <DashboardFixed3 cookie={cookie} /> 
                        : <DashboardFixed cookie={cookie} />
          }
        </div>
      </div>
    );
  } else {
    //console.log('AuthContainer return to login');
    return <AuthLogin2 />;
  }
};

export default AuthContainer;
