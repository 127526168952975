import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Layout, Menu, Spin, Table, Button, Space, Drawer, Divider,Upload } from 'antd';
import { AppContext } from "../../contexts/AppContext";
//import {useDropzone} from 'react-dropzone'

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  FileExcelOutlined,
  CloudUploadOutlined } from '@ant-design/icons';

const { Dragger } = Upload;  

const SettingsPopulation = (props) => {
  const TAG = 'SettingsPopulation';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(false);
  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    loadData();
  }, [props.client]);

  const loadData = () => {
    //console.log(`${TAG} loadData started`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/region`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        setData(response.data);
        setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData response`,error);
        setData([]);
        setLoading(false);
      });

  }
  
  const columns = [
    { title: 'Name', dataIndex: 'region_name', key: 'region_name' },
    { title: 'Category', dataIndex: 'region_description', key: 'region_description' },
  ]

  return (
    
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
      <Spin tip="Loading..." spinning={loading} delay={0}>
        <Table size='small' columns={columns} dataSource={data} title={() => 'Population list'} />
      </Spin>
      </div>
  </div>    
  );
}


export default SettingsPopulation;
