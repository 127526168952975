import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState, useRef } from 'react'
import ReactDOM from  'react-dom'
import { ResponsiveContainer, BarChart, Bar, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Legend, ComposedChart, ReferenceLine, PieChart, Pie, Sector, Cell, Label } from 'recharts';
import { Layout, Menu, PageHeader, Table, Button, Space, Select, Divider,Collapse } from 'antd';
import SitesChartFixed from './SitesChartFixed';
import TimeChart from './TimeChart';
import DayChart from './DayChart';
import HourChart from './HourChart';
import CrimeChartFixed from './CrimeChartFixed';
import HeatmapChart from './HeatmapChart';
import FilterOptions from './FilterOptions';
import FilterOptions2 from './FilterOptions2';
import { AppContext } from "../contexts/AppContext";
import AppHeader from './AppHeader';
import DraggableComponent from './DraggableComponent'
import PageTitle from './PageTitle'


import { ca8debug, getProperDate } from './Utils';
import AppFooter from './AppFooter';


import { DesktopOutlined,
  BarChartOutlined,
  FileOutlined,
  TeamOutlined,
  UserOutlined } from '@ant-design/icons';
import ChartDataDrawer from './drawers/ChartDataDrawer';

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;
const { Panel } = Collapse;

//import { readString } from 'react-papaparse'

const DashboardFixed = (props) => {
  const TAG = 'DashboardFixed';

  const appContext = useContext(AppContext);
  const {
    apiClient,
    appRole,
    appClientId,
    appCurrentClient
  } = appContext;  

  const defaultFilter = {
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    dayOfWeek: '',
    crimeCategory: '',
    crimeType: '',
    group1: '',
    group2: '',
    site: '',
    relationship: ''
  }

  const [data, setData] = React.useState([]);
  const [mainFilter, setMainFilter] = React.useState(defaultFilter);
  const [loading, setLoading] = React.useState(true);
  
  const [crimeTypes, setCrimeTypes] = React.useState([]);
  const [crimeCategories, setCrimeCategories] = React.useState([]);

  const [mainFilterLegend, setMainFilterLegend] = React.useState('');

  const [showDOW, setShowDOW] = useState(true);
  const [showTOD, setShowTOD] = useState(true);
  
  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const [refreshDateTime, setRefreshDateTime] = useState(new Date().getMilliseconds());


  const [isSitesChartMinimized, setIsSitesChartMinimized] = useState(false);
  const [isTimeChartMinimized, setIsTimeChartMinimized] = useState(true);
  const [isDayChartMinimized, setIsDayChartMinimized] = useState(true);
  const [isHourChartMinimized, setIsHourChartMinimized] = useState(true);
  const [isCrimeChartMinimized, setIsCrimeChartMinimized] = useState(true);
  const [isHeatmapChartMinimized, setIsHeatmapChartMinimized] = useState(true);

  const [chartData, setChartData] = React.useState([]);
  const [chartColumns, setChartColumns] = React.useState([]);
  const [chartTitle, setChartTitle] = React.useState('');
  const [chartMode, setChartMode] = React.useState('');
  const [chartExtra, setChartExtra] = React.useState({});
  const [drawerVisible, setDrawerVisible] = React.useState(false);


  const pageHeader = () => {
    return <div>

    </div>
  }

  const onSetFilterLegend = (legend) => {
    ca8debug(`${TAG} onSetFilterLegend`, legend);
    setMainFilterLegend(legend);

    onShowMainFilterLegendEx(legend);

  }

  const onSiderCollapsed = (value) => {
    setSiderCollapsed(value);
  }

  const onShowMainFilterLegend = () => {
    const args = {
      message: 'Filter options',
      description: mainFilterLegend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);       
  }

  const onShowMainFilterLegendEx = (legend) => {
    const args = {
      message: 'Filter options',
      description: legend,
      duration: 0,
      key: 'filter_options',
      maxCount: 1
    };
    //notification.open(args);       
  }  

  const onUseFilters = (filters) => {
    ca8debug(`${TAG} onUseFilters`,filters);

    const newData = data.slice();

    let startDate = '';
    let endDate = '';
    let startTime = '';
    let endTime = '';
    let dayOfWeek = '';  
    let crimeCategory = '';  
    let crimeType = '';  
    let group1 = '';  
    let group2 = '';  
    let site = '';  
    
    let dateCheck = true;

    if (filters.useDatePeriod) {
      startDate = getProperDate(filters.datePeriod[0], filters.dateMode);
      endDate = getProperDate(filters.datePeriod[1], filters.dateMode);
    }

    if (filters.useTimePeriod) {
      startTime = filters.timePeriod[0] ?? '';
      endTime = filters.timePeriod[1] ?? '';
    }

    if (filters.useWeekdays) {
      dayOfWeek = filters.weekdays.join(',');
    } 

    if (filters.useCrimeCategories) {
      crimeCategory = filters.crimeCategories.join(',');
      if (filters.useCrimeTypes) {
        crimeType = filters.crimeTypes.join(',');
      } 
    } 

    if (filters.useGroup1) {
      group1 = filters.group1.join(',');
      if (filters.useGroup2) {
        group2 = filters.group2.join(',');
        if (filters.useSite) {
          site = filters.site.join(',');
        } 
      } 
    } 

    
    const newFilter = {
      startDate: startDate,
      endDate: endDate,
      startTime: startTime,
      endTime: endTime,
      dayOfWeek: dayOfWeek,
      crimeCategory: crimeCategory,
      crimeType: crimeType,
      group1: group1,
      group2: group2,
      site: site,
      relationship: filters.relationship
    }

    ca8debug(`${TAG} onUseFilters result`,newFilter);
    setMainFilter(newFilter);
    setRefreshDateTime(new Date().getMilliseconds());
    loadData(newFilter);
  }

  const loadData = (filter) => {
    ca8debug(`${TAG} loadData started`,filter);

    const clientId = appRole == 2 ? (appCurrentClient ? appCurrentClient.id : appClientId) : appClientId;

    setLoading(true);
    apiClient.post(`api/clients/${clientId}/chart/incidents`, filter)
      .then(response => {
        ca8debug(`${TAG} loadData response`, response);
        
            const dt = response.data.map((x, idx) => {
              return {
                id : idx + 1,
                incident_id : x.incident_id,
                site_name : x.site_name,
                site_city : x.site_city,
                state_name : x.state_name,
                crime_type : x.crime_type_name,
                crime_category : x.crime_category_name,
                incident_date : x.incident_date,
                incident_time : x.incident_time,
                incident_location : x.incident_location,
                incident_victim : x.incident_victim,
                incident_comments : x.incident_comments,
                incident_offence_report_no : x.incident_offence_report_no,
                cyear : x.cyear,
                cmonth : x.cmonth,
                weekday : x.weekday,
                timeperiod : x.chour,
                quarter : x.quarter,
                period1 : x.period1,
                period2 : x.period2,
                period4 : x.period4,
                period8 : x.period8,
              }
            });            
            ca8debug(`${TAG} dt`, dt);

            setData(dt);
            setLoading(false);
          });

  }

  React.useEffect(() => {
    loadData(defaultFilter);
  }, []);

  const onSetChartData = (dt) => {
    setChartData(dt);
  }

  const onShowChartData = (mde, ttl, dt, cols, extra = {}) => {
    setChartMode(mde);
    setChartTitle(ttl);
    setChartData(dt);
    setChartColumns(cols);
    setChartExtra(extra);
    setDrawerVisible(true);
  }  

  const onDrawerClose = () => {
    setDrawerVisible(false);
  }

  return (
    <Layout hasSider style={{ minHeight: '100vh' }}>
      <FilterOptions2 
        onUseFilters={onUseFilters} 
        onSetFilterLegend={onSetFilterLegend} 
        onSetShowTOD={setShowTOD}
        onSetShowDOW={setShowDOW}
        onCollapsed={onSiderCollapsed}
        loading={loading}
        
        />
        
{/*        
        <Menu.Item key="2" icon={<DesktopOutlined />}>
          Option 2
        </Menu.Item>
        <SubMenu key="sub1" icon={<UserOutlined />} title="User">
          <Menu.Item key="3">Tom</Menu.Item>
          <Menu.Item key="4">Bill</Menu.Item>
          <Menu.Item key="5">Alex</Menu.Item>
        </SubMenu>
        <SubMenu key="sub2" icon={<TeamOutlined />} title="Team">
          <Menu.Item key="6">Team 1</Menu.Item>
          <Menu.Item key="8">Team 2</Menu.Item>
        </SubMenu>
        <Menu.Item key="9" icon={<FileOutlined />}>
          Files
        </Menu.Item>

      <div style={{ width: 1700, display: "inline-block" }}>
        <FilterOptions onUseFilters={onUseFilters} />
      </div>

*/}        
      
    <Layout className="site-layout" >
{/*}            
        
      <AppHeader />
      <div className="main-header">
        <Collapse defaultActiveKey={[]}>
          <Panel header="Filter options" key="1">
            {mainFilterLegend}
          </Panel>
        </Collapse>       
      </div>
*/}      

    <PageTitle
        icon={<BarChartOutlined />}
        title="Dashboard"
    />      

      <DraggableComponent legend={mainFilterLegend} />

      <Content style={{ margin: '0 auto', }}>
        <div className='chart2-container'>
            <div className='chart2-left'>
              {isSitesChartMinimized
                ? ''
                : <SitesChartFixed onMinimizedChange={() => setIsSitesChartMinimized(!isSitesChartMinimized)} minimized={isSitesChartMinimized} showChartData={onShowChartData} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
              }
              {isTimeChartMinimized
                ? ''
                : <TimeChart onMinimizedChange={() => setIsTimeChartMinimized(!isTimeChartMinimized)} minimized={isTimeChartMinimized} showChartData={onShowChartData} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
              }
              {isDayChartMinimized
                ? ''
                : <DayChart minimized={isDayChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
              }
              {isHourChartMinimized
                ? ''
                : <HourChart minimized={isHourChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
              }
              {isCrimeChartMinimized
                ? ''
                : <CrimeChartFixed minimized={isCrimeChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
              }
              {isHeatmapChartMinimized
                ? ''
                : <HeatmapChart minimized={isHeatmapChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
              }
            </div>
            <div className='chart2-right'>
            {isSitesChartMinimized
                ? <SitesChartFixed onMinimizedChange={() => setIsSitesChartMinimized(!isSitesChartMinimized)} minimized={isSitesChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
                : ''
              }
              {isTimeChartMinimized
                ? <TimeChart onMinimizedChange={() => setIsTimeChartMinimized(!isTimeChartMinimized)} minimized={isTimeChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
                : ''
              }
              {isDayChartMinimized
                ? <DayChart minimized={isDayChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
                : ''
              }
              {isHourChartMinimized
                ? <HourChart minimized={isHourChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
                : ''
              }
              {isCrimeChartMinimized
                ? <CrimeChartFixed minimized={isCrimeChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
                : ''
              }
              {isHeatmapChartMinimized
                ? <HeatmapChart minimized={isHeatmapChartMinimized} refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
                : ''
              }
            </div>
        </div>

        <ChartDataDrawer 
          visible={drawerVisible} 
          onClose={onDrawerClose} 
          data={data} 
          chartData={chartData} 
          mode={chartMode} 
          title={chartTitle} 
          columns={chartColumns}
          extra={chartExtra}/>
{/*
            <SitesChartFixed refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend} onShowLegend={onShowMainFilterLegend}/>
            <TimeChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
            <DayChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
            <HourChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
            <CrimeChartFixed refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
            <HeatmapChart refreshed={refreshDateTime} data={data} filter={mainFilter} loading={loading} mainLegend={mainFilterLegend}/>
*/}            
      </Content>
      </Layout>
      </Layout>
  );
}


export default DashboardFixed;
