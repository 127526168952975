import React, { useEffect, useState } from 'react'
import ReactDOM from  'react-dom'
import { Table, Button, Space, Select } from 'antd';
import { ReferenceLine } from 'recharts';
import {
  SyncOutlined,
  RollbackOutlined,
} from '@ant-design/icons';

export const sortAsc = (arr, name) => {
    if (name == 'value') {
        // sort as number
        arr.sort(function(a, b){
            return (+a[name]) - (+b[name]);
        });
    } else {
        arr.sort(function(a, b){
            if(a[name] < b[name]) { return -1; }
            if(a[name] > b[name]) { return 1; }
            return 0;
        });
    }
    //ca8debug('sortAsc result', name, arr);
}

export const getProperDate = (date, mode) => {
  let result = '';
  if (date && date != '') {
    const arr = date.split('-');
    if (mode == 'year') {
      result = `${arr[0]}-01-01`;
    } else if (mode == 'month') {
      result = `${arr[0]}-${arr[1]}-01`;
    } else {
      result = date;
    }    
  }
  return result;
} 

export const sortDesc = (arr, name) => {
    if (name == 'value') {
        // sort as number
        arr.sort(function(a, b){
            return (+b[name]) - (+a[name]);
        });
    } else {
        arr.sort(function(a, b){
            if(a[name] < b[name]) { return 1; }
            if(a[name] > b[name]) { return -1; }
            return 0;
        });
    }
    //ca8debug('sortDesc result', name, arr);
}

export const getFillForCategory = (category) => {
  switch (category.toLowerCase()) {
    case 'disorder':
        return '#2F5597';
    case 'property':
        return '#548235';
    case 'vicap':
        return '#C00000';
    case 'society':
        return '#B613BF';
    case 'person':
        return '#FF006E';
    default: // n/a
        return '#FFD800';
  }
}

// calculate thresholds
// Mean - Arithmetic average.
// Minimum - The lowest number of crimes at a site among those selected.
// Maximum - The highest number of crimes at a site among those selected.      
export const getThresholdReferences = (data, isVertical = false, isPopulation = false, isMinimized = false) => {
    //ca8debug(`${TAG} calculateThresholds started`, data);
    const res = [];
    const fontSize = 14;
    if (data && data.length > 0) {
      let min = 1000000, max = 0;
      let sum = 0, count = 0;

      data.forEach(x => {
        const val = +x['value'];
        //ca8debug(`${TAG} .. calculateThresholds`, val);
        if (min > val) min = val;
        if (max < val) max = val;
        sum += val;
        count += 1;
      });

      const showDecimals = isPopulation || max < 2;

      //const avg = max / 2;
      const avg = count > 0 ? sum / count : max / 2;
      // set deviation to 10%
      const dev = calculateDeviation(data, avg, count);

      ca8debug(`Utils getThresholdReferences result`, avg + dev, avg - dev, dev, avg, count);

      if (isVertical) {
        res.push(<ReferenceLine key={'chart_high_threshold'} x={avg + dev} stroke="red" label={{ position: 'top', dx: 15,  value: showDecimals ? (avg + dev).toFixed(1) : Math.round(avg + dev), fill: 'red', fontSize: fontSize, offset: 2 }} />);
        res.push(<ReferenceLine key={'chart_middle_threshold'} x={avg} stroke="green" label={{ position: 'top',  value: showDecimals ? (avg).toFixed(1) : Math.round(avg), fill: 'green', fontSize: fontSize, offset: 2 }} />);
        res.push(<ReferenceLine key={'chart_low_threshold'} x={avg - dev} stroke="blue" label={{ position: 'top', dx: -15,  value: showDecimals ? (avg - dev).toFixed(1) : Math.round(avg - dev), fill: 'blue', fontSize: fontSize, offset: 2 }} />);
      } else {
        res.push(<ReferenceLine key={'chart_high_threshold'} y={avg + dev} stroke="red" label={{ position: 'right',  value: showDecimals ? (avg + dev).toFixed(1) : Math.round(avg + dev), fill: 'red', fontSize: fontSize }} />);
        res.push(<ReferenceLine key={'chart_middle_threshold'} y={avg} stroke="green" label={{ position: 'right',  value: showDecimals ? (avg).toFixed(1) : Math.round(avg), fill: 'green', fontSize: fontSize }} />);
        res.push(<ReferenceLine key={'chart_low_threshold'} y={avg - dev} stroke="blue" label={{ position: 'right',  value: showDecimals ? (avg - dev).toFixed(1) : Math.round(avg - dev), fill: 'blue', fontSize: fontSize }} />);
      }
      return res;
      
    } 

    return '';

} 

// calculate thresholds
// Mean - Arithmetic average.
// Minimum - The lowest number of crimes at a site among those selected.
// Maximum - The highest number of crimes at a site among those selected.      
export const getVerticalThresholdReferences = (data) => {
  //ca8debug(`${TAG} calculateThresholds started`, data);
  const res = [];
  if (data && data.length > 0) {
    let min = 1000000, max = 0;
    let sum = 0, count = 0;

    data.forEach(x => {
      const val = +x['value'];
      //ca8debug(`${TAG} .. calculateThresholds`, val);
      if (min > val) min = val;
      if (max < val) max = val;
      sum += val;
      count += 1;
    });

    //const avg = max / 2;
    const avg = count > 0 ? sum / count : max / 2;
    // set deviation to 10%
    const dev = calculateDeviation(data, avg, count);

    ca8debug(`Utils getThresholdReferences result`, avg + dev, avg - dev, dev, avg, count);

    res.push(<ReferenceLine key={'chart_high_threshold'} x={avg + dev} stroke="red" label={{ position: 'insideTopRight',  value: Math.round(avg + dev), fill: 'red', fontSize: 10 }} />);
    res.push(<ReferenceLine key={'chart_middle_threshold'} x={avg} stroke="green" label={{ position: 'insideTopRight',  value: Math.round(avg), fill: 'green', fontSize: 10 }} />);
    res.push(<ReferenceLine key={'chart_low_threshold'} x={avg - dev} stroke="blue" label={{ position: 'insideTopRight',  value: Math.round(avg - dev), fill: 'blue', fontSize: 10 }} />);

    return res;
    
  } 

  return '';

} 

const calculateDeviation = (data, mean, dataCount) => {

        if (dataCount === 0) {
            return 0;
        }

        let result = 0.0;

        data.forEach(x => {
          const val = +x['value'] - mean;
          result += val * val;
        });

        return Math.sqrt(result / dataCount);
}

export const daysInMonth = (month, year) => {
  return new Date(year, month, 0).getDate();
}

export const FiltersLegend = (pFilters, pResetFilters, pDrillUp) => {
    ca8debug(' .. FiltersLegend ', pFilters);
    if (!Array.isArray(pFilters)) {

      const len = pFilters && Object.keys(pFilters).map((x, idx) => {
        return pFilters[x] != null ? '1' : '';
      }).join('');

      ca8debug(' .. FiltersLegend len', len);

      if (len.length > 0) {
      return <div style={{ /* backgroundColor: 'white',*/ marginTop: 0, marginBottom: 0, display: 'block', position: 'relative', padding: 20, borderBottom:'1px dashed lightgray' }}>
      <div style={{marginTop: 0, marginBottom: 40, marginLeft: 10, marginRight: 10}}>
        <div data-html2canvas-ignore="true" style={{ position: 'absolute', bottom: 10, left: 10 }}>
        <Button 
          data-html2canvas-ignore="true"
          icon={<SyncOutlined />}
          style={{ width: 140 }}
          size='small' 
          type='ghost' 
          width={100}
          onClick={() => pResetFilters()}>Reset filters</Button>
        {pDrillUp ?
          <Button 
            data-html2canvas-ignore="true"
            icon={<RollbackOutlined />}
            style={{ marginLeft: 20, width: 140 }}
            size='small' 
            type='ghost' 
            onClick={() => pDrillUp()}>Roll up</Button>
        : ''}
        </div>


          {
            pFilters && Object.keys(pFilters).map((x, idx) => {
              return pFilters[x] != null 
                ? <span key={`tr${idx}`}>
                    <span style={{ marginRight: 10, fontSize: 18 }}><b>{getFilterTitle(x)}:</b></span>
                    <span style={{ marginRight: 20 }}>{pFilters[x] != null ? pFilters[x].join(', ') : ''}</span>
                  </span> 
                : <span key={`tr${idx}`}></span>;
              })
          }
      </div>
      </div>
      } 
    } 
      return <div style={{margin: 50}}></div>
  }

  export const setTableVisibility = (pShowTable, pTableId) => {
    const root = document.getElementById(pTableId);
    const tbody = ReactDOM.findDOMNode(root).getElementsByClassName('ant-table-tbody');
    tbody[0].style.display = pShowTable ? 'table-row-group' : 'none';
    const pagination = ReactDOM.findDOMNode(root).getElementsByClassName('ant-pagination');
    
    for (let i = 0; i < pagination.length; i++) {
        pagination[i].style.display = pShowTable ? 'flex' : 'none';
    }
  }   

  export const emptyDiv = () => {
    return <span style={{height: 26, display: "inline-block"}}></span>
  }

  export const getMenuColor = (selected) => {
    return selected ? '#1890ff' : 'black'
  }

  export const ca8debug = (...args) => {
    // console.log(args);
  }

  export const useOnScreen = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false)
  
    const observer = new IntersectionObserver(
      ([entry]) => setIntersecting(entry.isIntersecting)
    )
  
    useEffect(() => {
      observer.observe(ref.current)
      // Remove the observer as soon as the component is unmounted
      return () => { observer.disconnect() }
    }, [])
  
    return isIntersecting
  }  

  export const formatTime = (value) => {
    return value ? new Date(value).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : '';
  }  

  export const formatIncidentTime = (value) => {
    if (value) {
      const arr = value.split(':');
      if (arr && arr.length > 1) {
        return `${arr[0]}:${arr[1]}`
      }
    }

    return '';
  }  

  export const formatExpertYear = (value, expertYear) => {
    //console.log("formatExpertYear", value, expertYear);
    if (expertYear) {
      if (value == expertYear.year) {
        return expertYear.value;
      }
    }

    return value;
  } 

  export const sortCrimeCategories = (lst) => {
    return lst.sort((a, b) => getSortOrderForCategory(a) - getSortOrderForCategory(b));
  }

  const getSortOrderForCategory = (category) => {
    switch (category.toLowerCase()) {
      case 'disorder':
          return 3;
      case 'property':
          return 2;
      case 'vicap':
          return 1;
      case 'society':
          return 4;
      case 'person':
          return 5;
      default: // n/a
          return 6;
    }    
  }


const filterTitles = {
  group1: 'Group 1',
  group2: 'Group 2',
  category: 'Crime Group',
  crime: 'Crime Type',
  weekday: 'Day',
  timeperiod: 'Timeperiod',
  site: 'Site',
  year: 'Year',
  quarter: 'Quarter',
  month: 'Month',
  period1: '1h period',
  period2: '2h period',
  period4: '4h period',
  period8: '8h period',
}
export const getFilterTitle = (value) => {
  return filterTitles[value];
}
