import 'antd/dist/antd.css';

import React, { useContext, useCallback, useState } from 'react'
import ReactDOM from  'react-dom'
import { message, Spin, Menu, Radio, Table, Button, Space, Drawer, Divider,Upload, Checkbox } from 'antd';
import { AppContext } from "../../contexts/AppContext";
//import {useDropzone} from 'react-dropzone'

import { DesktopOutlined,
  UserAddOutlined,
  ReloadOutlined,
  FileExcelOutlined,
  CloudDownloadOutlined,
  CloudUploadOutlined, 
  RedditCircleFilled} from '@ant-design/icons';
import { ca8debug } from '../Utils';

const { Dragger } = Upload;  

const SettingsImport = (props) => {
  const TAG = 'SettingsImport';

  const appContext = useContext(AppContext);
  const {apiClient} = appContext;  

  const [loading, setLoading] = React.useState(false);
  const [messagesLoading, setMessagesLoading] = React.useState(false);

  const [uploading, setUploading] = React.useState(false);
  const [fileList, setFileList] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [importTitles, setImportTitles] = React.useState([]);
  const [importStarted, setImportStarted] = React.useState();
  const [importFinished, setImportFinished] = React.useState();

  const [isImportAvailable, setIsImportAvailable] = React.useState(false);
  const [isShowErrorsOnly, setIsShowErrorsOnly] = React.useState(true);

  const [incidentCount, setIncidentCount] = React.useState(0);
  const [ucrSystem, setUcrSystem] = React.useState(0);

/*
  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    //console.log(`${TAG} onDrop`, acceptedFiles);
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
*/

  const onImport = () => {
    //console.log(`${TAG} onImport started`, props);
    setLoading(true);
    setData([]);
    var formData = new FormData();
    formData.append("file", fileList[0]);
    formData.append("ucr_system", ucrSystem);
    apiClient.post(`api/clients/${props.client.id}/import`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    })
      .then(async response => {
        //console.log(`${TAG} onImport response`, response);
        //setUsers(response.data);
        setLoading(false);
        message.success('Import done');
        loadAllData();

      })
      .catch(function (error) {
        console.error(`${TAG} onImport response`,error);
        message.error(error);
        setLoading(false);
      })
      .finally(() => {
        //console.log(`${TAG} onImport finally`);
      });

  }

  const loadAllData = async () => {

    //setLoading(true);
    await loadJobs();
    await loadData();
    setLoading(false);

  }

  const loadData = async () => {
    //console.log(`${TAG} loadData started`, props);
    //setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/incidents/count`)
      .then(response => {
        //console.log(`${TAG} loadData response`, response);
        if (response.data) {
          //console.log(`${TAG} loadData response incidents count`, response.data.count);
          setIncidentCount(response.data.count ? response.data.count : 0);
          setIsImportAvailable(true);
        } else {
          setIsImportAvailable(false);
          message.error('Unable to verify incidents for client');
        }
        //setUsers(response.data);
        //setLoading(false);
      })
      .catch(function (error) {
        console.error(`${TAG} loadData error`,error);
        message.error(error);
        //setLoading(false);
      });
  }  

  const loadJobs = async () => {
    ////console.log(`${TAG} loadJobs started ##`, props);
    setLoading(true);
    apiClient.get(`api/clients/${props.client.id}/jobs`)
      .then(response => {
        //console.log(`${TAG} loadJobs response >> `, `api/clients/${props.client.id}/jobs`, response);
        if (response.data) {
          //console.log(`${TAG} loadJobs`, response.data);
          setData(response.data);
        } else {
          message.error('Unable to load jobs data');
          setData([]);
        }
        //setLoading(false);
      })
      .catch(error => {
        //console.log(`${TAG} loadJobs error`, error);
        message.error(error);
        setData([]);
        //setLoading(false);
      });
  }   

  React.useEffect(() => {
    setUcrSystem(props.client.ucr_system);
    setLoading(true);
    loadAllData();
    //loadData();
    //loadJobs();
  }, [props.client]);

  const uploadProps = {
    onRemove: file => {
        //console.log(`${TAG} onRemove`,file);
        setFileList([]);
      },
      beforeUpload: file => {
        //console.log(`${TAG} beforeUpload`,file);
        setFileList([file]);
        return false;
      },
      fileList,
      accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"      
    };


const titleColumns = [
  { title: 'Title', key: 'name', render: (text, record) => {

    const fileName = record.file_name && record.file_name.length > 0 ? ` (${record.file_name})` : '';

    const wrnCount = (record && record.messages) ? record.messages.filter(x => x.severity === 1) : [];
    const errCount = (record && record.messages) ? record.messages.filter(x => x.severity === 2) : [];

    //console.log(`${TAG} titleColumns`, record, wrnCount, errCount);    

    return `Import job #${record.id}${fileName} started at ${record.created_at ? new Date(record.created_at).toLocaleString() : '?'}`
  }},
]

const jobDescription = (record) => {

    const wrnCount = (record && record.messages) ? record.messages.filter(x => x.severity === 1) : [];
    const errCount = (record && record.messages) ? record.messages.filter(x => x.severity === 2) : [];

    //console.log(`${TAG} jobDescription`, record, wrnCount, errCount);    

    return `Errors: ${errCount.length}, Warnings: ${wrnCount.length}`
}

const expandedColumns = [
  { title: 'Date', key: 'created_at', render: (text, record) => (record.created_at ? new Date(record.created_at).toLocaleString() : '') },
  { title: 'Message', dataIndex: 'message', key: 'message' },
]

const loadJobMessages = (jobId) => {
    //console.log(`${TAG} loadJobMessages started ##`, props);
    setMessagesLoading(true);
    apiClient.get(`api/clients/${props.client.id}/job/${jobId}/messages`)
      .then(response => {
        //console.log(`${TAG} loadJobMessages response >> `, `api/clients/${props.client.id}/job/${jobId}/messages`, response.data);

        const newData = [...data];
        newData.forEach(x => {
          if (x.id === jobId) {
            x.messages = response.data;
          }
        })
        //console.log(`${TAG} loadJobMessages newData`, newData);
        setData(newData);

      })
      .catch(error => {
        //console.log(`${TAG} loadJobMessages error`, error);
        message.error(error);
      })
      .finally(() => {
        //console.log(`${TAG} loadJobMessages finally`);
        setMessagesLoading(false);
      });
};

const showImportGroups = (perent, parent0Id) => {
    //console.log(`${TAG} showData`, perent);

    // get import titles
    const dt = perent.messages?.filter(x => x.message != 'Import started' && x.message != 'Import finished');

    if (dt) {
      const titles = [...new Set(dt.map(x => {
        const arr = x.message.split(' - ');
        return arr[0];
      }))].map((x, idx) => { return { id: idx, name: x } });

      //const dt = data.filter(x => x.message.startsWith(title.name));
      
      const columns = [{ title: 'Title', key: 'name', dataIndex: 'name'}];
      return <>
        <h3>{jobDescription(perent)}</h3>
        <Table 
          size="small"
          rowKey='id'
          key={`table_${perent.id}`}
          columns={columns} 
          dataSource={titles}
          expandable={{
            defaultExpandAllRows: perent.id === parent0Id,
            //onExpand:  (record) => showImportDetails(record, dt),
            expandedRowRender: (record) => showImportDetails(record, dt),
            rowExpandable: (record) => isShowImportDetails(record, dt),
          }}           
        />
      </>
    } else {
    return <Button type="primary" shape="round" size='small' icon={<CloudDownloadOutlined />} onClick={() => loadJobMessages(perent.id)} loading={messagesLoading}>Load details</Button>
    }
  }  

  const showImportDetails = (perent, messages) => {
    ////console.log(`${TAG} showImportDetails`, title);

    // get import titles
    const dt = isShowErrorsOnly 
      ? messages.filter(x => x.message.startsWith(perent.name) && x.severity === 2)
      : messages.filter(x => x.message.startsWith(perent.name));

    ca8debug(`${TAG} showImportDetails`,perent,dt);
    
    return <>
      {messages === null
        ? <Button type="primary" onClick={() => loadJobMessages(perent.id)} loading={messagesLoading}>Load details 2222</Button>
        : ''
      }
      {Array.isArray(messages)
        ? <Table 
            size="small"
            key={`table_${perent.id}`}
            columns={expandedColumns} 
            dataSource={dt}
            rowClassName={record => record.severity === 1 ? 'row-severity-warning' : record.severity === 2 ? 'row-severity-error' : ''}
          />
        : ''
      }
    </>
  }   

  const isShowImportDetails = (perent, messages) => {
    const dt = isShowErrorsOnly 
      ? messages.filter(x => x.message.startsWith(perent.name) && x.severity === 2)
      : messages.filter(x => x.message.startsWith(perent.name));

    return dt && dt.length > 0;
  }   
  
  const onShowErrorsOnlyChanged = (e) => {
    setIsShowErrorsOnly(e.target.checked);
  }

  return (
    
    <div>
      <div style={{ marginBottom: 16, marginTop: 20 }}>
        {isImportAvailable
          ? <Space direction='vertical'>
              <Space direction='horizontal'>
              <Upload {...uploadProps}>
                <Button icon={<FileExcelOutlined />} loading={loading}>Select File</Button>
              </Upload>
              
              {incidentCount > 0
                ? <div>UCR System: {props.client.ucr_system == 1 ? 'SRS' : 'NIBRS'}<span style={{ marginLeft: 20 }}>(to change UCR System you have to delete all incidents)</span></div>
                : <div>
                    <span style={{ marginLeft: 20, marginRight: 20 }}>Select UCR System:</span>
                    <Radio.Group onChange={(e) => setUcrSystem(e.target.value)} value={ucrSystem}>
                      <Space direction="horizontal">
                        <Radio value={1}>SRS</Radio>
                        <Radio value={2}>NIBRS</Radio>
                      </Space>
                    </Radio.Group>    
                  </div>
              }    
            </Space>
            <Button
              type="primary"
              icon={<CloudUploadOutlined />}
              onClick={onImport}
              disabled={fileList.length === 0}
              loading={loading}
              style={{ marginTop: 16 }}
            >
              {uploading ? 'Uploading' : 'Start Upload'}
            </Button>
          </Space>
          : <div>Import is not available</div>
        }

{/*          

          <Button type="primary" onClick={onImport} loading={loading} icon={<CloudUploadOutlined />}>
            Import
          </Button>

          <div {...getRootProps()} className="dropzone-area">
            <input {...getInputProps()} />
            {
                isDragActive ?
                <p>Drop the files here ...</p> :
                <p>Drag 'n' drop some files here, or click to select files</p>
            }
            </div>   
*/}                   
      </div>
      {data && data.length > 0
            ? <div>
                <span style={{ display: "block", marginBottom: 10 }}>
                    <Checkbox checked={isShowErrorsOnly} onChange={onShowErrorsOnlyChanged}>Show errors only</Checkbox>
                </span>
              
                <Spin tip="Loading ..." spinning={loading}>
                  <Table 
                    size='small' 
                    rowKey='id'
                    rowClassName="ant-table-title"
                    showHeader={false}
                    columns={titleColumns} 
                    dataSource={data} 
                    expandable={{
                      defaultExpandedRowKeys: [data[0].id],
                      expandedRowRender: (record) => showImportGroups(record, data[0].id),
                    }}           
                  />
                </Spin>
              </div>
            : ''
        }      
  </div>    
  );
}


export default SettingsImport;
